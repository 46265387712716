import React, { useCallback, useEffect, useState } from 'react'

declare interface IOption<T> {
    value: T,
    text: string
}
export default function SimpleDropdown<ValueType extends string|number>(props: {
    options: IOption<ValueType>[]
    defaultValue?: ValueType,
    valueSelectHanlder: (value: ValueType | undefined) => void
}) {
    const { options, defaultValue, valueSelectHanlder } = props;
//console.debug(defaultValue)
    const [_value, setValue] = useState<ValueType | undefined>(defaultValue);
  

    const handleOnChange = useCallback(
        (e) => {
            setValue(e.target.value)
        },
        [],
    )
    const handleValueSelectCallback = useCallback(valueSelectHanlder,
        [],
    )
    useEffect(() => {
        
        handleValueSelectCallback(_value)
    }, [_value,handleValueSelectCallback])

    return (

        <select onChange={handleOnChange} value={_value}>
            {
                _value===undefined&&<option value={undefined}>Choose Here</option>
            }
            {
                options.map((option: IOption<ValueType>) => {

                    return <option value={option.value} >{option.text}</option>
                })
            }
        </select>
    )
}
