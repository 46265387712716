import { getSessionStorageValue } from "../../_commons/Utils/BrowserStorage";
import { Regex } from "../../_commons/Utils/Regex";

const AFFILIATE_REF_STRING = "ref";
function addAffiliateRefToURL(radacadWPURL: string): string {
    let ref = getSessionStorageValue(AFFILIATE_REF_STRING);


    //console.debug(radacadWPURL + " " + Regex.test(radacadWPURL, Regex.RegExpEnum.EXP_END_WITH_QUERYSRING));


    let result=  `${radacadWPURL}${ref ? `${Regex.test(radacadWPURL, Regex.RegExpEnum.EXP_END_WITH_QUERYSRING) ? "&" : "?"}${AFFILIATE_REF_STRING}=${ref}` : ""}`

    //console.debug(result);
    return result;
}

export const AffiliateService = {
    AFFILIATE_REF_STRING,
    addAffiliateRefToURL
}