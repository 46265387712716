export function getLocalStorageValue(key: string): string | undefined {
    try {
        return window.localStorage.getItem(key) ?? undefined;

    } catch {
        return undefined;
    }
}

export function setLocalStorageValue(key: string, value: string): void {
    try {
        window.localStorage.setItem(key, value);

    } catch {

    }
}
export function getSessionStorageValue(key: string): string | undefined {
    try {
        return window.sessionStorage.getItem(key) ?? undefined;

    } catch {
        return undefined;
    }
}

export function setSessionStorageValue(key: string, value: string): void {
    try {
        window.sessionStorage.setItem(key, value);

    } catch {

    }
}

