import React, { useEffect, useState } from 'react';
import { UserService, IUserMetrics } from '../../../_services/UserService';


import { Time } from '../../../_commons/Utils/Time';

import MetricsCharts from './MetricsCharts';
interface IMetricsState {
    isLoading: boolean,
    errorMessage: string,
    value: IMetrics | null
}
interface IMetrics {
    totalWatchTimeInSec: number,
    certificateCount: number,
    inProgressCourseCount: number,
    questionCount: number,
    consecutiveDays: number,
    learnTimesByMonth: ILearnTimeByMonth[],
    last7DaysLearnTime: ILearnTimeData[],
    last8WeeksLearnTime: ILearnTimeByWeek[],
    maxDayLearnTime: ILearnTimeByDay,
    maxNonStopWatchTimeLength: number
}
interface ILearnTimeByMonth {
    monthDate: string,
    monthLearnTime: number,
}
interface ILearnTimeByDay {
    dayDate: string,
    dayLearnTime: number
}
interface ILearnTimeByWeek {
    startDate: string,
    endDate: string,
    learnTime: number
}
interface ILearnTimeData {
    date: string,
    learnTime: number
}




export default function UserMetrics(props:{accessToken:string}) {

    const [_metricsState, setMetricsState] = useState<IMetricsState>(
        {
            isLoading: true,
            errorMessage: "",
            value: null
        }
    );

    useEffect(() => {
        try {
            
            UserService.GetUserMetrics(props.accessToken)
                .then(resp => resp.json())
                .then((metricValue: IUserMetrics) => {
                    //console.log(metricValue)
                    setMetricsState({
                        isLoading: false,
                        value: metricValue,
                        errorMessage: ""

                    })
                })

        } catch (e) {
            setMetricsState({
                isLoading: false,
                value: null,
                errorMessage: "Some errors happened."
            })
        }

    }, [])



    return _metricsState.isLoading ?
        (
            <div>
                Loading...
            </div>
        ) : _metricsState.value ?
            (
                <div>
                    {RenderUserMetrics(_metricsState.value)}

                </div>
            )
            :
            (
                <div>
                    {_metricsState.errorMessage}
                </div>
            )
}


const RenderUserMetrics = (metricsValue: IMetrics): JSX.Element => {

    return (
        <>

            <div style={{ marginBottom: "1em" }}>
                <div style={{ display: 'inline-block', fontSize: '1.5em', padding: '.5em', border: '1px solid' }} >
                    Total learning time: {Time.SecsToReadableStr(metricsValue.totalWatchTimeInSec)}

                </div>
            </div>
            {/* <div>
                Number of certificates: {metricsValue.certificateCount}
            </div>
            <div>
                How many courses in progress:  {metricsValue.inProgressCourseCount}
            </div>
            <div>
                how many questions asked: {metricsValue.questionCount}
            </div>
            <div>
                The number of consecutive days learned: {metricsValue.consecutiveDays}
            </div>

            { metricsValue.learnTimesByMonth &&
                <div>
                    <table>
                        <tr>
                            <th>
                                Month
                        </th>
                            {
                                metricsValue.learnTimesByMonth.map(
                                    (learnTime: ILearnTimeByMonth) => {
                                        return (
                                            <td>
                                                {learnTime.monthDate}
                                            </td>
                                        )
                                    }
                                )
                            }
                        </tr>
                        <tr>
                            <th>
                                LearnTime
                        </th>
                            {
                                metricsValue.learnTimesByMonth.map(
                                    (learnTime: ILearnTimeByMonth) => {
                                        return (
                                            <td>
                                                {learnTime.monthLearnTime}
                                            </td>
                                        )
                                    }
                                )
                            }
                        </tr>
                    </table>


                </div>
            }
            {
                metricsValue.maxDayLearnTime &&
                <div>
                    the longest total sum learned in a 24 hours period: {metricsValue.maxDayLearnTime.dayDate}, {metricsValue.maxDayLearnTime.dayLearnTime}
                </div>


            }
            max watched non stop length: {metricsValue.maxNonStopWatchTimeLength} */}

            <MetricsCharts
                learnTimesByMonth={metricsValue.learnTimesByMonth}
                last7DaysLearnTime={metricsValue.last7DaysLearnTime}
                last8WeeksLearnTime={metricsValue.last8WeeksLearnTime}
            ></MetricsCharts>




        </>
    )
}
