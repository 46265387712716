import { HubConnection } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react'
import SimpleDialog from '../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
import { withHubConnection } from '../../_services/radacad-academy-signalRHub/react-hocs/withHubConnection';
import useLiveNotificationReceiver from '../../_services/radacad-academy-signalRHub/react-hooks/useLiveNotificationReceiver';
import AlertDialog from '../_commons/Dialogs/AlertDialog';

export default withHubConnection(withLiveNotificationReceiver());
function withLiveNotificationReceiver(): (props: { connection: HubConnection }) => JSX.Element {
    return (props: { connection: HubConnection }) => {
        const { connection } = props;
        const { notification } = useLiveNotificationReceiver(connection);

        return <LiveNotificationDialog
            notification={notification}
        />

    }
}

function LiveNotificationDialog(props: { notification: string | undefined }) {
    const { notification } = props;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    useEffect(() => {
        if (notification !== undefined) {
            setDialogOpen(true)
        }

        return () => setDialogOpen(false)
    }, [notification])

    const handleDialogClose=()=>{
        setDialogOpen(false)
    }

    return <AlertDialog
        open={dialogOpen && notification !== undefined}
        layoutState={
            {
                DialogTitle: "Live Notification",
                DialogContext: notification ? <LiveNotification content={notification} /> : <></>,
                ConfirmButtonText: "Confirm",
                ConfirmHandler: handleDialogClose
            }
        }>
    </AlertDialog>


}

function LiveNotification(props: { content: string }) {
    const { content } = props;
    return (
        <div dangerouslySetInnerHTML={{ __html: content }} />
    )
}
