import React from 'react';
//@ts-ignore
import CKEditor from 'ckeditor4-react';

function HTMLEditor_CKEditor4(props: {
    defaultHtmlStr?: string,
    htmlStrSetter?: (htmlStr: string) => void
}) {
    const { defaultHtmlStr, htmlStrSetter: setHTMLStr } = props;

    return <CKEditor


        data={defaultHtmlStr}

        onChange={(event: any) => {
            // console.log(event.editor.getData())
            let data = event.editor.getData()
            if (setHTMLStr && data)
                setHTMLStr(data)

        }}
    />
}


export default HTMLEditor_CKEditor4;


