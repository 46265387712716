import React, {  useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
export default function EmbedDialog(props) {
    const [layoutState, setLayoutState] = React.useState({
     
        CancleButtonText: "",
        CloseHandler: () => { }
    })


    useEffect(() => {
        setLayoutState(props.layoutState);

    }, [props.layoutState]);




    return (
        <div>
            <Dialog
                open={props.open}
                onClose={layoutState.CloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        
                {props.children}

                <DialogActions>
                    <Button variant='outlined' onClick={layoutState.CloseHandler}  >
                        {layoutState.CancleButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
