function test(url: string, regExp: string | RegExp) {
    // regex pattern for detecting querystring
    var pattern = new RegExp(regExp);
    return pattern.test(url);
}
enum RegExpEnum {

    EXP_END_WITH_QUERYSRING= `\\?[^\\/]+=[^\\/]*(&[^\\/]+=[^\\/]*)*$`
}


export const Regex = {
    RegExpEnum,
    test,
    
}