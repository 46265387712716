import React, { useState } from "react";
import { ILMSLessonModel, ILMSModuleModel } from "../../../_services/CMSServices/Models";
import HTMLEditor from "../../_commons/Editor/HTMLEditor";
import MaterialUISwitch from "../../_commons/MaterialUISwitch";
import SimpleDropdown from "../../_commons/SimpleDropdown";
import { ConfirmFormDiv } from "../../_commons/ConfirmFormDiv";
import { useLMSLessonFormStyle } from "./LMSLessonForm.style";
import { PrivateResourceLink } from "../../_commons/PrivateResourceLink";
import { LMSMaterialUploadButton } from "../../_commons/Button/AcademyUploadButton";

export function LMSLessonForm(
    props: {
        courseId: number,
        courseModules: ILMSModuleModel[] | undefined | null,

        lesson: ILMSLessonModel | undefined,
        submitHandler: (lesson: ILMSLessonModel) => void
        cancelHandler: () => void,

    }
) {
    const { courseId, courseModules, lesson, submitHandler, cancelHandler } = props;
    const classes = useLMSLessonFormStyle();




    const [_lessonTitle, setLessonTitle] = useState<string>(lesson?.lessonTitle ?? "")
    const [_moduleId, setModuleId] = useState<number | undefined>(lesson?.moduleId ?? undefined);
    const [_isPreview, setIsPreview] = useState<boolean>(lesson?.isPreview ?? false);
    const [_lessonLengthInSecond, setLessonLengthInSecond] = useState<number>(lesson?.lessonLengthInSecond ?? 0);
    const [_lessonVideoUrl, setLessonVideoUrl] = useState<string>(lesson?.lessonVideoUrl ?? "https://");
    const [_lessonMaterialHTML, setLessonMaterialHTML] = useState<string>(lesson?.lessonMaterialHtml ?? "")
    const [_lessonMaterialLink, setLessonMaterialLink] = useState<string>(lesson?.lessonMaterialLink ?? "")

    const handleOnChange = (e: any, setState: (state: any) => void) => {
        setState(e.target.value);
    }

    const handleSubmit = () => {

        submitHandler({
            lessonId: lesson?.lessonId ?? 0,
            courseId: courseId,
            moduleId: _moduleId,
            orderInCourse: lesson?.orderInCourse ?? 0,
            orderInModule: lesson?.orderInModule ?? 0,
            lessonTitle: _lessonTitle,
            isPreview: _isPreview,
            lessonLengthInSecond: _lessonLengthInSecond,
            lessonVideoUrl: _lessonVideoUrl,
            lessonMaterialHtml: _lessonMaterialHTML,
            lessonMaterialLink: _lessonMaterialLink === "" ? null : _lessonMaterialLink
        })

    }
    const handleCancel = () => {
        cancelHandler();
    }



    return <ConfirmFormDiv
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
    >
        <div>

            <div className={classes.fullwidthInputDiv}>
                <label> Lesson Title</label>
                <input type="text" value={_lessonTitle} onChange={(e: any) => { handleOnChange(e, setLessonTitle) }}></input>
            </div>
            <div>
                <label> Module   </label>
                {courseModules && <SimpleDropdown
                    options={courseModules.map((value: ILMSModuleModel) => { return { value: value.moduleId, text: value.moduleTitle } })}
                    defaultValue={_moduleId}
                    valueSelectHanlder={setModuleId}
                />
                }
            </div>
            <div>
                <label>Preview Status: </label>
                <MaterialUISwitch
                    defaultValue={_isPreview}
                    onChangleHandler={setIsPreview}
                    color={"secondary"}
                />
                <span>{_isPreview ? "Preview Open" : "Preview Closed"}</span>
            </div>
            <div>
                <label> Lesson Length(mins) </label>
                <input type="number" value={_lessonLengthInSecond} onChange={(e: any) => { handleOnChange(e, setLessonLengthInSecond) }}></input>
            </div>
            <div className={classes.fullwidthInputDiv}>
                <label> Lesson Video URL  </label>

                <input type="text" value={_lessonVideoUrl} onChange={(e: any) => { handleOnChange(e, setLessonVideoUrl) }}></input>
                <iframe
                    src={_lessonVideoUrl}

                    style={{
                        width: '300px',
                        height: '300px'
                    }}

                    frameBorder="0"
                    title={"Video Link Preview"}
                >
                </iframe>
            </div>
            <div>
                <label> Lesson Material HTML  </label>
                <HTMLEditor
                    defaultHtmlStr={_lessonMaterialHTML}
                    htmlStrSetter={setLessonMaterialHTML}
                />
            </div>

            <div className={classes.fullwidthInputDiv}>
                <label> Lesson Material Download Link </label>
                <input type="text" value={_lessonMaterialLink} onChange={(e: any) => { handleOnChange(e, setLessonMaterialLink) }}></input>
                <div className={classes.uploadContainer}>
                    {
                        <LMSMaterialUploadButton
                            label={"Upload Material File"}
                            succ={(resp) => setLessonMaterialLink(resp)}
                        />

                    }
                    {
                        _lessonMaterialLink
                        &&
                        <PrivateResourceLink href={_lessonMaterialLink}><button>Download</button></PrivateResourceLink>
                    }

                </div>
            </div>
        </div>
    </ConfirmFormDiv>
}

