import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = (severity) => makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alertbox: severity === "success" ? {
    backgroundColor: '#16cc5c !important'
  } : {
    backgroundColor: '#F44336 !important'
  }
}));

export default function CustomizedSnackbars(props) {
  const classes = useStyles(props.severity)();
  const [open, setOpen] = React.useState(true);


  useEffect(() => {
    setOpen(true);
  }, [])


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={7500} onClose={handleClose}>
        <Alert className={classes.alertbox} onClose={handleClose} severity={props.severity ?? "error"}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}