import React, { useEffect, useState, useContext } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

import { AdminService } from '../../../../_services/AdminService';
import CustomIframe from '../../../_commons/CustomIframe';
import { AcademyContext } from '../../../../_contexts/UserContext';


interface IEmailNotificationTemplate {
    sourceCode: string
}



export default function EmailTemplateEditorForNewMessageNotification() {
    const loginContext = useContext(AcademyContext)
    const [_HTMLContent, setHTMLContent] = useState<string>('');
    const [_previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);
    const [_submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false);



    useEffect(() => {
        FetchSourceCode();
    }, [])

    const FetchSourceCode = () => {
        AdminService.GetEmailNotificationTemplate(loginContext.loginState.accessToken!)
            .then(
                async resp => {
                    if (resp.status === 200) {
                        let data: IEmailNotificationTemplate = await resp.json();
                        setHTMLContent(data.sourceCode);
                    } else {
                        alert('Source Code Fetching Failed');
                    }

                }
            ).catch(
                (e) => alert('Source Code Fetching Failed')
            );
    }

    const PostChanges = async (content: string): Promise<boolean> => {
        try {


            let resp = await AdminService.UpdateEmailNotificationTemplate(content, loginContext.loginState.accessToken!);
            if (resp.status === 200) {
                alert('success')
                return true;
            }
            else {
                alert('failed')
                return false;
            }
        } catch {
            return false;
        }

    }

    const HandleEditorChange = (content: string) => {
        setHTMLContent(content);
    }
    const HandleChangeSubmit = async (content: string) => {
        if (window.confirm('Confirm to update?')) {
            if (await PostChanges(content)) {
                setSubmitDialogOpen(false);
            }
        }
    }

    const classes = useStyle();
    const textareaStyle = {};
    return (
        <div className={classes.root}>
            <h6>To let our application know where are positions to present user name, course title, lesson title, and message content,<br />
            please leave <strong>{`{StudentName}, {CourseTitle}, {LessonTitle}, {MessageContent}`}</strong> at the their positions in this email template respectively.
            </h6>
            <textarea

                style={textareaStyle}
                value={_HTMLContent}
                onChange={(e) => HandleEditorChange(e.target.value)}
            />
            <button className={`hover-highlight-low`} onClick={() => setPreviewDialogOpen(true)}>Preview</button>
            <button className={` hover-highlight-low`} style={{ marginRight: 0 }} onClick={() => setSubmitDialogOpen(true)}>Save Changes</button>
            <div dangerouslySetInnerHTML={{ __html: _HTMLContent }}>

            </div>

            <Dialog
                open={_previewDialogOpen}
                onClose={() => setPreviewDialogOpen(false)}
                fullWidth={true}
                maxWidth={false}
                scroll={"body"}
            >
                <DialogContent >
                    <CustomIframe html={_HTMLContent}></CustomIframe>
                </DialogContent>
            </Dialog>


            <Dialog
                open={_submitDialogOpen}
                onClose={() => setSubmitDialogOpen(false)}
                fullWidth={true}
                maxWidth={false}
                scroll={"body"}
            >
                <DialogContent >
                    <CustomIframe html={_HTMLContent}></CustomIframe>
                </DialogContent>
                <DialogActions>
                    <button className={`${classes.SaveButton} hover-highlight`} onClick={() => { HandleChangeSubmit(_HTMLContent) }}>Save</button>
                    <button className={`${classes.CancleButton} hover-highlight`} onClick={() => { setSubmitDialogOpen(false) }}>Cancle</button>
                </DialogActions>

            </Dialog>

        </div >
    );

}
const ButtonStyle = {
    backgroundColor: '#4CAF50',
    border: 'none',
    color: 'white',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline - block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer'
}
const useStyle = makeStyles({

    root: {
        '& textarea': {
            width: '100%', height: '400px',
            '& ::selection': {
                backgroundColor: 'blue',
                color: 'white'
            }

        },

        '& button': ButtonStyle

    },
    SaveButton: {
        backgroundColor: '#4CAF50',
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline - block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer'
    },
    CancleButton: {
        backgroundColor: '#D25750',
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline - block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer'
    }




})