import { LMSMaterialLinkType } from "./Types";

export function LMSMaterialLinkHandler(apiResp: Promise<Response>): Promise<LMSMaterialLinkType> {
    return apiResp.then(async (resp) => {
        if (resp.status === 200) {
            let result: string | null | undefined = await resp.text();
            return result ? { link: result } : "NOT_FOUND";
        }
        else if (resp.status === 401) {
            return "NOT_AUTHORISED";
        } else {
            return "NOT_FOUND";
        }
    }).catch(() => {
        return "NOT_FOUND";
    });

}


