import { IProduct } from '../../_viewmodels/ProductModel';
import { HttpHelper } from '../../_commons/Helpers/HttpHelper';

export enum ProductionEnum {
    Individual = "Individual",
    Team = "Team"
}


function getAcademyMembershipProductImageLinkURL(product: ProductionEnum): string | undefined {
    if (product === ProductionEnum.Individual) {
        return "https://i1.wp.com/learn.radacad.com/wp-content/uploads/2019/09/online-3412473_640.jpg?fit=640%2C426&ssl=1"
    } else if (product === ProductionEnum.Team) {
        return "https://i1.wp.com/learn.radacad.com/wp-content/uploads/2020/04/online-3412473_640-1.jpg?fit=640%2C426&ssl=1"
    } else
        return undefined;
}
function getAcademyMembershipProductWebPageLinkURL(product: ProductionEnum): string | undefined {
    if (product === ProductionEnum.Individual) {
        return "https://learn.radacad.com/product/academy-membership/"
    } else if (product === ProductionEnum.Team) {
        return "https://learn.radacad.com/product/academy-membership-for-teams/"
    } else
        return undefined;
}

async function getProductByCourseId(courseId: number | string): Promise<IProduct | null> {
    let apiURL = `/api/product?courseId=${courseId}`
    let product = await HttpHelper.Get(apiURL).then(async (resp): Promise<IProduct | null> => {
        if (resp.status === 200) {
            let data: IProduct = await resp.json();
            return data;
        } else {
            return null;
        }
    }).catch(() => {
        return null;
    })

    return product;
}

export const AcademyProductService = {
    getAcademyMembershipProductWebPageLinkURL,
    getAcademyMembershipProductImageLinkURL,
    getProductByCourseId
}