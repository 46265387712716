import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

import './UserSwitch.css';
import { AcademyContext } from '../../../../_contexts/UserContext'
import { AdminService } from '../../../../_services/AdminService'


export default class UserSwitch extends Component {
    static contextType = AcademyContext;
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            searchInput: "",
            searchOption: "userName",
            resultList: [],
            redirectToProfile: false,
            redirectToSignIn: false
        }
        this.searchInputChangeHanlder = this.searchInputChangeHanlder.bind(this);
        this.searchOptionChangeHandler = this.searchOptionChangeHandler.bind(this);
        // this.switchUserButtonClickHandler= this.switchUserButtonClickHandler.bind(this);
    }

    componentDidMount() {
        AdminService.getUserList(
            (data) => {

                this.setState({ userList: data, resultList: data })
            },
            this.context.loginState.accessToken
        );
    }
    searchInputChangeHanlder(e) {

        this.setState({ searchInput: e.target.value });

        this.filterSearchResult(this.state.searchOption);

    }
    searchOptionChangeHandler(e) {
        this.setState({ searchOption: e.target.value });

        this.filterSearchResult(e.target.value);
    }
    filterSearchResult(searchOption) {
        if (searchOption === "userName") {
            this.setState(state => ({
                resultList: state.userList.filter(user => user.userName.toLowerCase().includes(state.searchInput.toLowerCase()))
            }))
        }
        else if (searchOption === "userEmail") {
            this.setState(state => ({
                resultList: state.userList.filter(user => user.userEmail.toLowerCase().includes(state.searchInput.toLowerCase()))
            }))
        }

    }
    switchUserButtonClickHandler(userId) {
        AdminService.switchUser(
            userId,
            (data) => {

                if (data.accessToken != null) {

                    this.context.refreshLoginState();
                    this.setState({ redirectToProfile: true });
                }
                else {
                    this.setState({ redirectToSignIn: true });
                }

            },
            this.context.loginState.accessToken
        );
    }




    render() {
        if (this.state.redirectToProfile === true)
            return <Redirect to='/'></Redirect>
        else if (this.state.redirectToSignIn === true)
            return <Redirect to='/SignIn'></Redirect>
        else
            return (
                <div>
                    <div>
                        Keywords to Search:  &nbsp;
               <input onChange={this.searchInputChangeHanlder} ></input>

                    </div>
                    <div> Search by: &nbsp;
                <input type="radio" defaultChecked id="byUserName" name="searchOption" value="userName" onChange={this.searchOptionChangeHandler}></input>
                        <label htmlFor="byUserName">UserName</label>
                        <input type="radio" id="byUserEmail" name="searchOption" value="userEmail" onChange={this.searchOptionChangeHandler}></input>
                        <label htmlFor="byUserEmail">UserEmail</label>
                    </div>
                    <div>
                        Result Count:  <span>{this.state.resultList.length}</span>
                    </div>

                    <table >
                        <tbody>
                            {this.state.resultList.map((user, index) =>
                            (
                                <tr key={index}>
                                    <td>
                                        <button onClick={this.switchUserButtonClickHandler.bind(this, user.userId)}> SwitchTo </button>
                                        {user.userName}
                                    </td>
                                    <td>{user.userEmail}</td>
                                    <td>{user.registerDate}</td>
                                    <td>{user.userCapbility}</td>
                                </tr>
                            )
                            )
                            }
                        </tbody>
                    </table >
                </div>
            )
    }
}
