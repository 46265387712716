import { HttpHelper } from '../_commons/Helpers/HttpHelper';

const GetAllBadges = (): Promise<Response> => {
    return HttpHelper.Get("api/Badge/All");
}
const GetUserBadges = (token: string): Promise<Response> => {

    return HttpHelper.Get(`api/Badge/UserBadges`,token)
}

export const BadgeService = {
    GetAllBadges,
    GetUserBadges
}