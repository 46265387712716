import { makeStyles } from '@material-ui/core';
export const useMembershipPlanCourseCMSFormStyle = makeStyles({
    root: {
        margin: "20px auto",
        padding: "20px ",
        backgroundColor: "white",
        "& div ": {
            width: "100%",
            "& input": {
                width: "100%"
            }
        }
    },


    footerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px"
    }

})