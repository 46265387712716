import { HttpHelper } from "../../_commons/Helpers/HttpHelper";

interface IPreCourseRespModel {
    preCourseId: number
}
//public
const getPreCoursesById = async (courseId: number, dataHandler: (data: IPreCourseRespModel[]) => void,
    errHandler = (err: any) => { }, finalHandler = () => { }) => {
    await fetch(`api/CoursePrerequisite/PreCourse/${courseId}`)
        .then((response) => response.json())
        .then((data: IPreCourseRespModel[]) => {
            dataHandler(data);
        })
        .catch((e) => {
            errHandler(e);
        }).finally(() => { finalHandler() });

}


interface INextCourseRespModel {
    nextCourseId: number
}
//public
const getNextCoursesById = async (courseId: number, handler: (data: INextCourseRespModel[]) => void,
    errHandler = (err: any) => { }, finalHandler = () => { }) => {
    await fetch(`/api/CoursePrerequisite/NextCourse/${courseId}`)
        .then((response) => response.json())
        .then((data: INextCourseRespModel[]) => {
            handler(data)
        })
        .catch((e) => {
            errHandler(e);
        }).finally(() => { finalHandler() });

};




interface IPreCourseReqModel {
    CourseId: number,
    PreCourseIdList: number[]
}
//private
const UpdatePreCourses = async (token:string,courseId: number, precourseIdList: number[]): Promise<Response> => {
    let api = `api/CoursePrerequisite/PreCourse`;
    let bodydata: IPreCourseReqModel = {
        CourseId: courseId,
        PreCourseIdList: precourseIdList
    };
    return HttpHelper.PostJSON(api,bodydata,token);
}

interface INextCourseReqModel {
    CourseId: number,
    NextCourseIdList: number[]
}
//private
const UpdateNextCourses = async (token:string,courseId: number, nextcourseIdList: number[]): Promise<Response> => {
    let api = `api/CoursePrerequisite/NextCourse`;
    let bodydata: INextCourseReqModel = {
        CourseId: courseId,
        NextCourseIdList: nextcourseIdList
    };
    return HttpHelper.PostJSON(api,bodydata,token);
}


export const CoursePrerequisiteService = {
    //public
    getPreCoursesById,
    getNextCoursesById,
    //private
    UpdatePreCourses,
    UpdateNextCourses
} 