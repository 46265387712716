import React, { useEffect, useState } from 'react'
import { ILMSLessonModel } from '../../../_services/CMSServices/Models';
import { LMSLessonApi } from '../../../_services/CMSServices/LMSLessonApi';
import { BaseOrderingEditor } from '../BaseOrderingEditor';
import { IOrderElement } from '../../_commons/DragDropList';



interface IProps {
  courseId: number,
  accessToken: string,
  submitHandler: () => void,
  cancelHandler: () => void
}
export default function OrderEditor(props: IProps) {
  const { submitHandler, cancelHandler } = props;

  const [_pageState, setPageState] = useState<"Loading" | "Waiting" | "Showing" | "Error">("Loading");


  const [_list, setList] = useState<ILMSLessonModel[] | null>(null);
  useEffect(() => {
    setPageState("Loading")


    const FetchModule = async (courseId: number) => {

      LMSLessonApi.Get(courseId, undefined, props.accessToken)
        .then(
          async (data) => {
            if (data) {
              data.sort((a: ILMSLessonModel, b: ILMSLessonModel) => a.orderInCourse - b.orderInCourse);
              setList(data);
              setPageState("Showing")

            } else {
              setList(null);
              setPageState("Error")

            }

          }
        ).catch(
          (err) => {
            setList(null);
            setPageState("Error")

          }
        )
    }
    FetchModule(props.courseId);

  }, [props.courseId, props.accessToken]);






  const handleSubmit = (resultList: IOrderElement[]) => {

    LMSLessonApi.UpdateOrder(
      "COURSE_LESSON",
      {
        parentId: props.courseId,
        lessonOrders: resultList.map((ele: IOrderElement, index: number) => {
          return {
            lessonId: ele.id,
            lessonOrder: ele.order
          }
        })
      },
      props.accessToken!
    ).then(
      (succ) => {
        if (succ) {
          alert('succ');
          submitHandler();
        }

        else alert('failed')
      }
    ).catch(() => {
      alert('failed')
    }).finally(() => {
      setPageState("Showing")

    })



  }
  const handleCancel = () => {
    cancelHandler();
  }
  return <BaseOrderingEditor
    pageState={_pageState}
    initialDataList={_list ? _list.map((ele: ILMSLessonModel) => { return { id: ele.lessonId, order: ele.orderInCourse, label: ele.lessonTitle } }) : null}
    submitHandler={handleSubmit}
    cancelHandler={handleCancel}
  />
}

