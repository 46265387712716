import { IGroupedLessons } from './models/IGroupedLessons';
import { HttpHelper } from "../../_commons/Helpers/HttpHelper";
import { LMSMaterialLinkHandler } from "../Common/Handlers";
import { LMSMaterialLinkType } from "../Common/Types";
import { ILessonPublicDto } from "./models/ILessonPublicDto";
import { defaultPagenatedList, IPagenatedList } from "./models/IPagenatedList";

//public   require token 
const RequestLessonPublicDetail = (token: string | null, id: string, respHandler: (resp: any) => void, errHandler: (err: any) => void) => {


    let apiUrl = `api/Lesson/${id}/PublicDetail`;
    return HttpHelper.Get(apiUrl, token)
        .then(
            (resp) => {
                respHandler(resp);
            })
        .catch(
            (err) => {
                errHandler(err)
            }
        );


}

//public   require token 
const RequestPreviewLessonResource = async (token: string | null, id: string, dataHandler: (data: any) => void) => {

    let apiurl = `api/Lesson/${id}/PreviewResource`;
    await HttpHelper.Get(apiurl, token)
        .then(
            (response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('RequestPreviewLessonResource error');
            //console.log(e);
        });
}

//private 
const RequestMemberLessonResource = async (id: string, dataHandler: (data: any) => void, token: string) => {
    let apiUrl = `api/Lesson/${id}/MemberResource`;

    await HttpHelper.Get(apiUrl, token)
        .then(
            (response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('RequestLessonResource error');
            //console.log(e);
        });

}

//private
const GetMaterialLink = (lessonId: number | string, token: string | null): Promise<LMSMaterialLinkType> => {

    let api = `api/Lesson/MaterialLink/${lessonId}`;
    return LMSMaterialLinkHandler(HttpHelper.Get(api, token));

}


const searchLesson = (keyword: string, pageIndex?: number): Promise<IPagenatedList<ILessonPublicDto>> => {

    let api = `api/Lesson?PageIndex=${pageIndex ?? 1}${keyword && keyword !== "" ? `&Title=${keyword}` : ""}`;
    // alert(api)
    return HttpHelper.PostJSON(api, null).then(
        async resp => {
            let data: IPagenatedList<ILessonPublicDto> = await resp.json();
            return data;
        }
    ).catch(() => {
        return defaultPagenatedList
    }
    )

}


const searchGroupedLessons = (keyword: string, pageIndex?: number): Promise<IPagenatedList<IGroupedLessons>> => {

    let api = `api/Lesson/Search?PageIndex=${pageIndex ?? 1}${keyword && keyword !== "" ? `&Title=${keyword}` : ""}`;
    // alert(api)
    return HttpHelper.PostJSON(api, null).then(
        async resp => {
            let data: IPagenatedList<IGroupedLessons> = await resp.json();
            return data;
        }
    ).catch(() => {
        return defaultPagenatedList
    }
    )

}


export const LessonService = {
    //public  require token 
    RequestPreviewLessonResource: RequestPreviewLessonResource,
    RequestLessonPublicDetail: RequestLessonPublicDetail,
    //private 
    RequestMemberLessonResource: RequestMemberLessonResource,

    GetMaterialLink,
    searchLesson,
    searchGroupedLessons

}