import React from 'react';
import {  withStyles } from '@material-ui/core/styles';
import { LinearProgress, Box } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
  root:
  {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#f0e9e9',
  },
  bar: {
    borderRadius: 5,
    height: 10,
    backgroundColor: '#5bc99f',
  },
}))(LinearProgress);



export default function CourseProgressBar(props) {


  return (

    <Box display="flex" alignItems="center" >
      <Box width="100%" position="relative" >
        <div mr=".2em">
          <small mr="0">
            {`${Math.round(
              props.value,
            )}%`}
          </small>
        </div>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>



    </Box>

  );
}
