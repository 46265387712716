import { HubConnection } from "@microsoft/signalr";
import React from "react";
import LiveNotificationHubProvider, { useLiveNotificationHubConnection } from "../react-providers/LiveNotificationHubProvider";

export function withHubConnection(ComponentToWrap:(props: { connection: HubConnection })=>JSX.Element){
    function WrappedComponent(){
        const {connection} =useLiveNotificationHubConnection();
        return <ComponentToWrap connection={connection}/>
    }

    return ()=><LiveNotificationHubProvider>
        <WrappedComponent/>
    </LiveNotificationHubProvider>
}