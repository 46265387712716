import React, { useCallback, useContext, useEffect, useState } from 'react'
import useResponseData from '../../../_commons/Hooks/useResponseData';
import { useDynamicContentDialog } from '../../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
import { IMembershipPlanCourseViewModel } from '../../../_viewmodels/MembershipPlanCourseViewModel';
import { AcademyContext } from '../../../_contexts/UserContext';
import { MembershipPlanCourseCMSApi } from '../../../_services/CMSServices/MembershipPlanCourseCMSApi';
import { IMembershipPlanCourseModel } from '../../../_services/CMSServices/Models';
import { ICourseTitleDto, MembershipPlanCourseCMSForm } from './MembershipPlanCourseCMSForm';
import { MembershipPlanCourseCMSTable } from './MembershipPlanCourseCMSTable';
import { useMembershipPlanCourseCMSStyle } from './MembershipPlanCourseCMS.style';
import { CourseService } from '../../../_services/radacad-academy-lms/CourseService';
import { MembershipPlanCMSApi } from '../../../_services/CMSServices/MembershipPlanCMSApi';




type DataModelType = IMembershipPlanCourseModel;
export default function MembershipPlanCourseCMS() {


    const { loginState: { accessToken } } = useContext(AcademyContext);
    const token = accessToken ?? ""

    const classes = useMembershipPlanCourseCMSStyle();


    const { data: membershipPlanCourses, handleRefresh } = useResponseData<IMembershipPlanCourseViewModel[]>(
        useCallback(
            () => MembershipPlanCourseCMSApi.GetAll(token)
            ,
            [token]
        ));
    const [_courses, setCourses] = useState<ICourseTitleDto[] | null | undefined>(undefined);
    const { data: membershipPlans } = useResponseData(
        useCallback(
            () => MembershipPlanCMSApi.GetAll(token)
            ,
            [token],
        )
    )
    useEffect(() => {
        CourseService.getAllCourses(setCourses);
    }, [])


    const useDialog = useDynamicContentDialog({ maxWidth: 'lg', fullWidth: true, disableBackdropClick: true });


    const handleAddCourse = () => {
        useDialog.open(
            <MembershipPlanCourseCMSForm
                mpCourse={undefined}
                courses={_courses}
                membershipPlans={membershipPlans}
                submitHandler={(course: DataModelType) => { handleFormSubmit("ADD", course) }}
                cancelHandler={handleFormCancel}
            />
        )
    }
    const handleEditCourse = (mpCourse: IMembershipPlanCourseModel) => {
        useDialog.open(
            <MembershipPlanCourseCMSForm
                mpCourse={mpCourse}
                courses={_courses}
                membershipPlans={membershipPlans}
                submitHandler={(course: DataModelType) => { handleFormSubmit("EDIT", course) }}
                cancelHandler={handleFormCancel}
            />
        )
    }

    const handleFormSubmit = (action: "ADD" | "EDIT", dataModel: DataModelType) => {

        if (window.confirm("confirm to submit?"))
            MembershipPlanCourseCMSApi.Update(action, dataModel, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    useDialog.close();
                    handleRefresh();
                }

                else
                    alert("failed");

            })

    }
    const handleFormCancel = () => {
        useDialog.close();
    }
    const handleDeleteCourse = (membershipPlanCourseId: number) => {
        if (window.confirm("confirm to submit?"))
            MembershipPlanCourseCMSApi.Delete({ membershipPlanCourseId }, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    handleRefresh();
                }

                else
                    alert("failed");

            })
    }
    return (
        <div className={`${classes.root}`}>
            <div className={classes.header}>
                <button onClick={() => handleAddCourse()}>Add New Course</button>
            </div>

            <div className={classes.body}>
                <MembershipPlanCourseCMSTable
                    memberhipPlanCourse={membershipPlanCourses}
                    membershipPlans={membershipPlans}
                    editButtonHandler={handleEditCourse}
                    deleteButtonHandler={handleDeleteCourse}
                />
            </div>
            <div className={classes.footer}>

            </div>
            {useDialog.render()}
        </div>
    )
}

