

import { HttpHelper } from '../_commons/Helpers/HttpHelper'


//private 
const CheckIsAdmin = (token:string): Promise<Response> => {
   let api = `api/Identity/CheckIsAdmin`;
    return  HttpHelper.Get(api,token);
}



export const IdentityService = {
    //private
    CheckIsAdmin
}