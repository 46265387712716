import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";


// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = (isDraggingOver: any) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});


export interface IOrderElement {
  id: number | string,
  order: number,
  label: string
}
interface IDragDropListProps {
  list: IOrderElement[],
  setList: (list: IOrderElement[]) => void
}

export function DragDropList(props: IDragDropListProps) {
  const { list,setList } = props;

  const onDragEnd = (result: any): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderList = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    setList(reorderList.map((ele: IOrderElement, index: number): IOrderElement => { return { ...ele, order: index } }));
  }
  return <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable">
      {(provided, snapshot) => (
        <RootRef rootRef={provided.innerRef}>
          <List style={getListStyle(snapshot.isDraggingOver)}>
            {
              list.map((ele: IOrderElement, index) => (

                <Draggable key={ele.id} draggableId={ele.id + ""} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      // ContainerProps={{ ref: provided.innerRef }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ListItemIcon>
                        {index + 1}
                      </ListItemIcon>
                      <ListItemText
                        primary={ele.label}
                        secondary={""}
                      />
                      {/* <ListItemSecondaryAction>
                            <IconButton>
                              <EditIcon />
                            </IconButton>
                          </ListItemSecondaryAction> */}
                    </ListItem>
                  )}
                </Draggable>
              ))
            }

            {provided.placeholder}
          </List>
        </RootRef>
      )}
    </Droppable>
  </DragDropContext>
}

