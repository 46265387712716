import React from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { TransferProgressEvent } from '@azure/core-http';
import InputFile from '../InputFile';
import { IBlobUploadOption } from '../../../_services/FileStorageService';

export function BlobUploadButton(props: {
    getUploadOptions: (fileName: string) => Promise<IBlobUploadOption | null>,
    handleUploadStart?: (fileName: string) => void,
    handleUploadOnProgress?: (percentage: number) => void,
    handleUploadSuccess?: (fileName: string, fileUri: string) => void,
    handleUploadError?: (fileName: string, error: any) => void,
    children?: React.ReactNode
}) {

    const { getUploadOptions, handleUploadError, handleUploadOnProgress, handleUploadStart, handleUploadSuccess, children } = props;


    async function UploadFileToBlob(file: File) {
        let isNotProduction = process.env.NODE_ENV === "production";
        function uploadStart() {
            handleUploadStart ? handleUploadStart(file.name)
                : isNotProduction && console.debug(`Upload ${file.name} starts`);
        }
        function onProgress(progress: TransferProgressEvent) {
            handleUploadOnProgress ?
                handleUploadOnProgress(parseInt(((progress.loadedBytes / file.size) * 100).toString(), 10))
                : isNotProduction && console.debug(progress.loadedBytes)
        }
        function uploadSuccess(url: string) {
            handleUploadSuccess ?
                handleUploadSuccess(file.name, url) :
                isNotProduction && console.debug(`Upload ${file.name} successfully`, url);
        }
        function uploadError(e: any) {

            handleUploadError ?
                handleUploadError(file.name, e) :
                isNotProduction && console.debug(`Upload ${file.name} failed`, e);
        }


        let uploadOptions = await getUploadOptions(file.name);
        if (uploadOptions) {

            uploadStart();
            isNotProduction && console.debug(`Upload ${file.name} starts`)
            try {
                const blobServiceClient = new BlobServiceClient(`${uploadOptions.clientUrl}?${uploadOptions.sasToken}`);
                const containerClient = blobServiceClient.getContainerClient(uploadOptions.containerName);
                const blockBlobClient = containerClient.getBlockBlobClient(uploadOptions.blobName);

                await blockBlobClient.uploadData(file, {
                    onProgress: onProgress,
                    blobHTTPHeaders: {
                        blobContentType: file.type
                    }
                });
                let fileUri = blockBlobClient.url.split('?')[0];
                uploadSuccess(fileUri);
                isNotProduction && console.debug(`Upload ${file.name} successfully`, fileUri);
            } catch (error) {
                uploadError(error);
            }

        }

    }

    return (
        <InputFile singleItemHanlder={UploadFileToBlob} >
            {children ?? <button>Upload files</button>}
        </InputFile >
    )
}

// function uploadFile(blockBlobClient: BlockBlobClient, file: File) {
//     return new Observable<number>(observer => {
//         blockBlobClient.load(file, {
//                 onProgress: onProgress(observer),
//                 blobHTTPHeaders: {
//                     blobContentType: file.type
//                 }
//             })
//             .then(
//                 this.onUploadComplete(observer, file),
//                 this.onUploadError(observer)
//             );
//     }).pipe(distinctUntilChanged());
// }
// function onProgress(observer: Subscriber<number>) {
//     return (progress: TransferProgressEvent) =>
//         observer.next(progress.loadedBytes);
// }