import React, { useRef, useState, useEffect } from 'react'

import { Grid, Slider, Chip, Box,  Button } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { fakeLearningPathDiagramData } from './LearnPageDiagramData'
import DragScrollPaper from './DragScrollPaper';


export default function LearningPathDiagramPage() {

    //Changable Image
    const [_loaded, setLoaded] = useState(false);
    const [_selectedIndex, setSelectedIndex] = useState<number>(0);
    useEffect(() => {
        setLoaded(false);
        setCurrHeightPerc(100);
    }, [_selectedIndex])


    //Zoomable Image

    const _imgRef = useRef(null);
    const _sizeSetting = { minPerc: 10, maxPerc: 200, defaultPerc: 100, deltaPerc: 10 };
    const [_currHeightPerc, setCurrHeightPerc] = useState<number>(100);
    const HandleZoomOut = () => {
        setCurrHeightPerc(perc => perc - _sizeSetting.deltaPerc);

    }
    const HandleZoomIn = () => {
        setCurrHeightPerc(perc => perc + _sizeSetting.deltaPerc);
    }
    const HandleSizeChange = (event: any, newValue: any) => {
        setCurrHeightPerc(newValue);

    };
    const HandleSizeReset = () => {
        setCurrHeightPerc(_sizeSetting.defaultPerc);
    }
    const wheelHandler = (e: any) => {

        if (e.deltaY > 0 && _currHeightPerc > _sizeSetting.minPerc) {

            setCurrHeightPerc(perc => perc - _sizeSetting.deltaPerc);

        } else if (e.deltaY < 0 && _currHeightPerc < _sizeSetting.maxPerc) {

            setCurrHeightPerc(perc => perc + _sizeSetting.deltaPerc);

        }

    }






    return (
        <Grid container spacing={3}
        >
            <Grid item xs={12}>

                {
                    fakeLearningPathDiagramData.map((diag: any, index: number): JSX.Element => (
                        <Chip
                            style={{ margin: '2px' }}
                            className={`MuiChip-Dark MuiChip-Rect ${_selectedIndex === index ? 'MuiChip-Dark-Selected' : ''}`}
                            key={index} label={diag.persona} clickable onClick={() => { setSelectedIndex(index) }} 
                            />
                    ))
                }
            </Grid>
            <Grid item xs={12} >
                <Box display={'flex'} alignItems={'center'}>
                    <Box style={{ width: '50px' }}>

                        {_currHeightPerc}%
                    </Box>
                    <Box style={{ width: '25px' }}>
                        {_currHeightPerc > _sizeSetting.minPerc && <RemoveIcon className={'MuiIcon-HighlightHover'} onClick={() => HandleZoomOut()}>-</RemoveIcon>}
                    </Box>


                    <Slider
                        // ValueLabelComponent={ValueLabelComponent}
                        style={{
                            maxWidth: '200px'
                        }}
                        aria-label="zoom img"
                        min={_sizeSetting.minPerc}
                        max={_sizeSetting.maxPerc}
                        defaultValue={_sizeSetting.defaultPerc}
                        value={_currHeightPerc}

                        onChange={HandleSizeChange}
                    />
                    <Box style={{ width: '25px' }}>
                        {_currHeightPerc < _sizeSetting.maxPerc && <AddIcon className={'MuiIcon-HighlightHover'} onClick={() => HandleZoomIn()}>+</AddIcon>}
                    </Box>
                    <Button
                        className={'myThemeContainedButton'}
                        onClick={HandleSizeReset} >Reset</Button>

                </Box>

            </Grid>
            <Grid item xs={12}

            >
                <DragScrollPaper
                    className={'MuiPaper-Dark '}
                    style={{
                        height: '80vh', padding: '20px'
                    }}
                >
                    <img src={fakeLearningPathDiagramData[_selectedIndex].imageURL} alt={fakeLearningPathDiagramData[_selectedIndex].persona}

                        ref={_imgRef}
                        style={{
                            height: `calc(70vh * ${_currHeightPerc / 100})`,
                            display: _loaded ? 'inline-block' : 'none'
                        }}
                        onWheel={wheelHandler}
                        onLoad={() => { setLoaded(true) }}

                    ></img>
                </DragScrollPaper>

            </Grid>
        </Grid >
    )
}


