import React, {  useState } from "react";
import { ICertificateTemplateModel, ILMSAuthorModel, ILMSCourseModel } from "../../../_services/CMSServices/Models";
import { useLMSCourseFormStyle } from "./LMSCourseForm.style";
import HTMLEditor from "../../_commons/Editor/HTMLEditor";
import SimpleDropdown from "../../_commons/SimpleDropdown";
import MaterialUISwitch from "../../_commons/MaterialUISwitch";
import { PrivateResourceLink } from "../../_commons/PrivateResourceLink";
import { LMSMaterialUploadButton, LMSPhotoUploadButton } from "../../_commons/Button/AcademyUploadButton";

export function LMSCourseForm(
    props: {
        course: ILMSCourseModel | undefined,
        authors: ILMSAuthorModel[] | undefined | null,
        certificateTemplates: ICertificateTemplateModel[] | undefined | null,
        submitHandler: (course: ILMSCourseModel) => void,
        cancelHandler: () => void,

    }
) {

    const { course, authors, certificateTemplates, submitHandler, cancelHandler } = props;
    const classes = useLMSCourseFormStyle();

    const [_courseTitle, setCourseTitle] = useState<string>(course?.courseTitle ?? "");
    const [_authorId, setAuthorId] = useState<number | undefined>(course?.authorId ?? undefined);
    const [_CourseDescription, setCourseDescription] = useState<string>(course?.courseDescription ?? "");
    const [_courseLink, setCourseLink] = useState<string>(course?.courseLink ?? "");
    const [_coursePhtotLink, setCoursePhotoLink] = useState<string>(course?.coursePhotoLink ?? "");
    const [_publishStatus, setPublishStatus] = useState<boolean>(course?.publishStatus ?? false);
    const [_certificateTemplatedId, setCertificateTemplatedId] = useState<number | undefined>(course?.certificateTemplateId ?? undefined);
    const [_courseMaterialLink, setCourseMaterialLink] = useState<string>(course?.courseMaterialLink ?? "");


    const handleOnChange = (e: any, setState: (state: any) => void) => {
        setState(e.target.value);
    }

    const handleSubmit = () => {
        submitHandler({
            courseId: course?.courseId ?? 0,
            courseTitle: _courseTitle,
            courseLink: _courseLink,
            coursePhotoLink: _coursePhtotLink,
            courseDescription: _CourseDescription,
            publishStatus: _publishStatus,
            authorId: _authorId ?? 0,
            createdDate: course?.createdDate ?? new Date(Date.now()),
            certificateTemplateId: _certificateTemplatedId ?? null,
            courseMaterialLink: _courseMaterialLink === "" ? null : _courseMaterialLink,
        })

    }
    const handleCancel = () => {
        cancelHandler();
    }


    return <div className={classes.root}>

        <div className={classes.inputDiv}>
            <label> Course Title    </label>
            <input
                type="text"
                value={_courseTitle}
                onChange={(e: any) => { handleOnChange(e, setCourseTitle) }}
            />

        </div>

        <div>
            <label> Author  </label>
            {authors && <SimpleDropdown
                options={
                    authors.map(
                        (author: ILMSAuthorModel) => {
                            return { value: author.authorId, text: author.authorName }
                        }
                    )
                }
                defaultValue={_authorId ?? undefined}
                valueSelectHanlder={setAuthorId}
            />
            }
        </div>

        <div>
            <label> Course Description </label>
            <HTMLEditor
                defaultHtmlStr={_CourseDescription}
                htmlStrSetter={setCourseDescription}
            />
        </div>

        <div className={classes.inputDiv}>
            <label>Course Link to Mall</label>
            <input type="text" value={_courseLink} onChange={(e: any) => { handleOnChange(e, setCourseLink) }} />

        </div>

        <div className={classes.inputDiv}>
            <label> Course Photo Link URL </label>
            <input type="text" value={_coursePhtotLink} onChange={(e: any) => { handleOnChange(e, setCoursePhotoLink) }}></input>
            <div className={classes.uploadContainer}>
                <img src={_coursePhtotLink} alt={"Invalid URL"} />
                {
                    <LMSPhotoUploadButton
                        label={"Upload Course Photo"}
                        succ={(resp) => setCoursePhotoLink(resp)}
                    />
                }
            </div>
        </div>

        <div>
            <MaterialUISwitch
                defaultValue={_publishStatus}
                onChangleHandler={(result: boolean) => { setPublishStatus(result) }}
                color={"primary"}
            />
            <span>{_publishStatus === false ? "Not Published" : "Live"}</span>
        </div>

        <div>
            <label> Certificate Template  </label>
            {certificateTemplates && <SimpleDropdown
                options={
                    certificateTemplates.map(
                        (cert: ICertificateTemplateModel) => {
                            return { value: cert.certificateTemplateId, text: cert.certificateTitle }
                        }
                    )
                }
                defaultValue={_certificateTemplatedId ?? undefined}
                valueSelectHanlder={setCertificateTemplatedId}
            />
            }
        </div>

        <div className={classes.inputDiv}>
            <label> Course Material Download Link </label>
            <input type="text" value={_courseMaterialLink} onChange={(e: any) => { handleOnChange(e, setCourseMaterialLink) }}></input>
            <div className={classes.uploadContainer}>
                {
                    <LMSMaterialUploadButton
                        label={"Upload Material File"}
                        succ={(resp) => setCourseMaterialLink(resp)}
                    />
                }
                {
                    _courseMaterialLink
                    &&
                    <PrivateResourceLink href={_courseMaterialLink}><button>Download</button></PrivateResourceLink>
                }

            </div>
        </div>

        <div className={classes.footerContainer}>
            <div>
                <button onClick={() => { handleSubmit() }}>Submit</button>
                <button onClick={() => { handleCancel() }}>Cancel</button>
            </div>
        </div>
    </div >
}

