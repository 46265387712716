import React, { Component } from 'react'

export default class CustomIframe extends Component {

  constructor(props) {
    super(props)

    this.iframe_ref = null

    this.writeHTML = this.writeHTML.bind(this)
  }

  writeHTML(frame) {

    if (!frame) {
      return
    }

    this.iframe_ref = frame

    let doc = frame.contentDocument

    doc.open()
    doc.write(this.props.html)
    doc.close()

    frame.style.width = '100%'
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`
  }

  render() {
    return (
      <iframe src='about:blank'  scrolling='no' frameBorder='0' ref={this.writeHTML} title="custom iframe"></iframe>
    )
  }
}