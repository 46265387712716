import { makeStyles } from '@material-ui/core';
export const useLMSLessonTableStyles = (col: number) => makeStyles({

    LMSTable: {
        "& td": {
            maxWidth: `${80 / col}vw`,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    }


})