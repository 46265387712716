import React, { useRef } from 'react';

const InputFile: React.FC<{
    multiple?: boolean,
    mutilItemHanlder?: (files: FileList) => void
    singleItemHanlder?: (file: File) => void
}> = (props) => {

    const { multiple, mutilItemHanlder: handleMultiItems, singleItemHanlder: handleSingleItem, children } = props;
    const inputFileRef = useRef<HTMLInputElement>(null);

    const handleFiles = (files: FileList) => {
        multiple ?
            handleMultiItems && handleMultiItems(files) :
            files.item(0) && handleSingleItem && handleSingleItem(files.item(0)!);
    }

    const showFileDialog = () =>
        inputFileRef.current && inputFileRef.current.click();

    return (
        <div className="input-file">
            <input
                style={{ display: 'none' }}
                ref={inputFileRef}
                type="file"
                multiple={multiple}
                onChange={e => e.target.files && e.target.files.length > 0 && handleFiles(e.target.files)}
            />
            <div onClick={() => showFileDialog()}>
                {children}
            </div>
        </div>
    );
};

export default InputFile;