import React, { useState } from 'react'
import EmailSubjectEditorForNewMessageNotification from './EmailSubjectEditors/EmailSubjectEditorForNewMessageNotification';
import EmailSubjectEditorForUnreadMessageNotification from './EmailSubjectEditors/EmailSubjectEditorForUnreadMessageNotification';
import EmailTemplateEditorForNewMessageNotification from './EmailTemplateEditors/EmailTemplateEditorForNewMessageNotification'
import EmailTemplateEditorForUnreadMessageNotification from './EmailTemplateEditors/EmailTemplateEditorForUnreadMessageNotification';

export default function EmailTemplateManagement() {
    const [selectedOption, setSelectedOption] = useState<"1" | "2">("1");
    return (
        <div>
            <button onClick={() => setSelectedOption("1")}>template for new message email notification</button>&nbsp;
            <button onClick={() => setSelectedOption("2")}>template for unread message email cnotification</button>
            {selectedOption === "1" ? <div>
                <h3>Task for new message email notification</h3>
                <EmailSubjectEditorForNewMessageNotification/>
                <EmailTemplateEditorForNewMessageNotification />
            </div>
                : <div>
                    <h3>Task for unread message email cnotification</h3>
                    <EmailSubjectEditorForUnreadMessageNotification/>
                    <EmailTemplateEditorForUnreadMessageNotification />
                </div>}

        </div>
    )
}
