import React, { useEffect, useState } from 'react';
import { LearningPathService } from '../../../../_services/radacad-academy-lms/LearningPathService';
import { useRadacadAuth } from '../../../../_services/radacad-auth-react/Core/useRadacadAuth';

interface IProps {
    currentCourse: ICourse
    learningPathList: ILearningPath[]

}

interface ICourse {
    courseId: number,
    courseTitle: string,

}
interface ILearningPath {
    learningPathId: number,
    persona: string
}

export default function LearningPathEditor(props: IProps) {
    //console.log(props.learningPathList)
    const { authState: { accessToken } } = useRadacadAuth();
    const [_checkListIsLoading, setCheckListIsLoading] = useState<boolean>(true);
    const [_checkList, setCheckList] = useState<boolean[]>(new Array<boolean>(props.learningPathList.length).fill(false));

    useEffect(() => {
        setCheckListIsLoading(true);
        InitCheckList(props.currentCourse.courseId, props.learningPathList, setCheckList);
    }, [props.currentCourse]);

    const InitCheckList = async (courseId: number, learningPathList: ILearningPath[], checkListSetter: React.Dispatch<React.SetStateAction<boolean[]>>) => {
        // console.log('init')
        await LearningPathService.GetLearningPathByCourseId(courseId,
            (data: ILearningPath[]) => {
                //console.log(data)
                let checkListResult = new Array<boolean>(learningPathList.length).fill(false);
                // console.log(checkListResult)

                data.forEach((learninPath): void => {
                    let findIndex = learningPathList.findIndex((learningPath: ILearningPath) => learningPath.learningPathId === learninPath.learningPathId)
                    if (findIndex !== -1)
                        checkListResult[findIndex] = true;
                });
                // console.log(checkListResult)
                checkListSetter(checkListResult);

                setCheckListIsLoading(false);
            })
    }
    const HandleCheckboxChange = (event: React.MouseEvent<HTMLInputElement>, index: number) => {
        //console.log(event.currentTarget.checked)
        let checked = event.currentTarget.checked;
        setCheckList((oldcheckList: boolean[]): boolean[] => {
            let newCheckList = oldcheckList.slice();
            // console.log(checked)
            newCheckList[index] = checked;

            return newCheckList;
        })
    }
    const HandleSubmitUpdate = async (currentCourse: ICourse, learningPathList: ILearningPath[], checkListResult: boolean[]) => {
        // let confirmText = `\n"Learning Paths" for "${currentCourse.courseTitle}" would change to`;

        let selectedLearningPaths = learningPathList.filter((learningPath: ILearningPath, index: number): boolean => checkListResult[index]);


        // confirmText += selectedLearningPaths.map((learningPath: ILearningPath, index: number): string => `\n${index + 1}. ${learningPath.persona}`);

        // if (window.confirm(confirmText)) {
        let selectedLearningPathId: number[] = selectedLearningPaths.map((learningPath: ILearningPath): number => learningPath.learningPathId);
        //     alert(selectedLearningPathId);

        let resp = await LearningPathService.UpdateLearningPaths(
            currentCourse.courseId,
            selectedLearningPathId,
            accessToken!
        );

        if (resp.status === 200) {
            alert("success");
        }
        else {
            alert("failed");
        }

        //}
    }

    return (
        <div>
            {
                _checkListIsLoading === false && (
                    < div >
                        {props.learningPathList.map((learningPath: ILearningPath, index: number) => {
                            return (
                                <div key={index}  >
                                    <label className={'hover-pointer'}>
                                        <input type="checkbox" defaultChecked={_checkList[index]}
                                            onClick={(event) => { HandleCheckboxChange(event, index) }}
                                        />
                                        {learningPath.persona}
                                    </label>
                                </div>
                            )
                        })}
                        <button onClick={() => { HandleSubmitUpdate(props.currentCourse, props.learningPathList, _checkList) }} >Submit</button>
                    </div>
                )


            }
        </div>
    )
}
