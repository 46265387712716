import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import Moment from 'moment';
import { Grid } from '@material-ui/core';

import { AcademyContext } from '../../_contexts/UserContext';
import { ChatMessageService, IChatConversationHeader } from '../../_services/radacad-academy-message/ChatMessageService';
import { JwtHelper } from '../../_services/radacad-auth-react/Helpers/JwtHelper';
import MessageBox, { ILessonMessage, ITempLessonMessage, ITempMessageStateType } from './MessageBox';
import MessageTabList from './ConversationSelection';
import './style.css';


export default function MessagePage() {
    const { loginState: { isLoggedIn, accessToken }, setUnreadCount } = useContext(AcademyContext);


    const [_selectedConversationUserId, setSelectedConversationUserId] = useState<number>();
    const [_conversationHeaderList, setConversationHeaderList] = useState<IChatConversationHeader[] | null | undefined>();
    const HandleConversationSelect = (userId: number): void => {
        //update selected conversation
        setSelectedConversationUserId(userId);
    }

    const [_isLoadState, setIsLoadState] = useState<boolean>(false);
    const [_messageList, setMessageList] = useState<(ILessonMessage | ITempLessonMessage)[]>([]);//messages List for child component to display
    const lastMessageSentReceiverUserIdRef = useRef<number>();
    const currrentConversationUserIdRef = useRef<number>();
    const HandlerSubmitNewMessage = useCallback(
        (newMessageString: string, messageReplyTo: ILessonMessage): void => {
            let tempSendingLessonMessage: ITempLessonMessage = {
                messageText: newMessageString,
                tempMessageState: ITempMessageStateType.sending,
                messageReplyTo: messageReplyTo
            }
            let tempFailedLessonMessage: ITempLessonMessage = {
                messageText: newMessageString,
                tempMessageState: ITempMessageStateType.failed,
                messageReplyTo: messageReplyTo
            }

            setMessageList((messages): (ILessonMessage | ITempLessonMessage)[] => [...messages, tempSendingLessonMessage]);
            lastMessageSentReceiverUserIdRef.current = parseInt(messageReplyTo.sender.uid);


            //send message to remote and wait for response 
            ChatMessageService.PostReplyMessage(
                accessToken!,
                {
                    LessonId: messageReplyTo.lesson!.lessonId ?? -1,
                    Datetime: Moment(Date.now()).format(""),
                    ReplyId: parseInt(messageReplyTo.id),
                    MessageContent: newMessageString,

                    CourseId: messageReplyTo.course!.courseId ?? -1,
                    ReceiverId: parseInt(messageReplyTo.sender.uid),
                    Title: `${messageReplyTo.text.substr(0, 20)} ${messageReplyTo.text.length > 20 ? "..." : ""}`
                }
            ).then(async resp => {
                if (lastMessageSentReceiverUserIdRef.current === currrentConversationUserIdRef.current) {
                    try {
                        let data = await resp.json();
                        setMessageList(
                            (messages): (ILessonMessage | ITempLessonMessage)[] =>
                                [...messages.filter(m => m !== tempSendingLessonMessage),
                                {
                                    text: data.messageContent,
                                    id: data.messageId + "",
                                    datetime: data.datetime,
                                    replyId: data.replyId,
                                    lesson: data.lesson,
                                    course: data.course,
                                    title: data.title,
                                    sender: {
                                        "name": data.senderName,
                                        "uid": data.senderId + ""
                                    }
                                }
                                ]
                        )
                    } catch {
                        setMessageList(
                            (messages): (ILessonMessage | ITempLessonMessage)[] =>
                                [...messages.filter(m => m !== tempSendingLessonMessage),
                                    tempFailedLessonMessage
                                ]
                        )
                    }
                }
            }
            )
        },
        [accessToken],
    )


    useEffect(() => {
        const initConversationHeaderList = () => {
            if (accessToken) {
                ChatMessageService.getChatConversationHeaders(accessToken).then(
                    data => {
                        setConversationHeaderList(data)
                    }

                )
            }
        }
        initConversationHeaderList();
    }, [])

    useEffect(() => {
        currrentConversationUserIdRef.current = _selectedConversationUserId;
        if (_selectedConversationUserId && _conversationHeaderList) {
            const selectedSenderId = _selectedConversationUserId + "";
            // console.log(selectedSenderId)
            // console.log(_conversationHeaderList)
            //select matched conversation once selected user changes
            let selectedConversation = _conversationHeaderList.find(conv => conv.targetUser.userId + "" === selectedSenderId);
            // console.log(selectedConversation)
            if (selectedConversation && accessToken) {
                //Update MessageList
                //setMessageList(currentTabMessages);

                setIsLoadState(true);
                ChatMessageService.GetHistoryMessageList(_selectedConversationUserId.toString(), accessToken).then(
                    (data) => {
                        //   console.log(data)
                        if (data != null) {
                            setMessageList(data.map(m => {
                                return {
                                    text: m.messageContent,
                                    id: m.messageId + "",
                                    datetime: m.datetime,
                                    replyId: m.replyId,
                                    lesson: m.lesson,
                                    course: m.course,
                                    title: m.title,
                                    sender: {
                                        "name": m.senderName,
                                        "uid": m.senderId + ""

                                    }
                                }
                            }));

                            let currentUserIdNumber: number = parseInt(JwtHelper.GetUserId(accessToken));
                            let unreadMessageIds = data.filter(
                                (message) => {
                                    return message.readDatetime === null && message.receiverId === currentUserIdNumber
                                }
                            ).map(
                                (message): number => {
                                    return message.messageId;
                                }
                            )

                            if (unreadMessageIds.length > 0) {
                                ChatMessageService.PostNewReadMessage(
                                    accessToken,
                                    { MessageIds: unreadMessageIds },
                                    (resp) => {
                                        if (resp.status === 200) {
                                            setUnreadCount((count: number) => { return Math.max(0, count - unreadMessageIds.length) });
                                        }
                                    },
                                    (err) => {

                                    }
                                )



                                //Update UnreadCount
                                setConversationHeaderList((conversationHeaders: IChatConversationHeader[] | undefined | null): IChatConversationHeader[] | undefined | null => {
                                    if (conversationHeaders) {
                                        let index = conversationHeaders.findIndex(c => c.targetUser.userId == selectedSenderId);

                                        if (index >= 0) {
                                            conversationHeaders[index].unreadMessageCount -= unreadMessageIds.length;
                                        }
                                    }
                                    return conversationHeaders;

                                })



                            }

                        } else {
                            setMessageList([]);
                        }
                    }

                ).finally(
                    () => setIsLoadState(false)
                )
            }




        }
    }, [_selectedConversationUserId, _conversationHeaderList, accessToken])


    return isLoggedIn === false ?
        <Redirect to={'/SignIn'}></Redirect>
        :
        (
            <Grid container spacing={2} style={{ height: "100%" }} justify={'flex-start'} >
                <Grid item xs={'auto'} style={{ height: "100%" }} >
                    <MessageTabList
                        tabs={
                            _conversationHeaderList ? _conversationHeaderList.map((conv: IChatConversationHeader) => {
                                const tab = {
                                    user: {
                                        userId: parseInt(conv.targetUser.userId),
                                        userName: conv.targetUser.userName ?? "",
                                    },
                                    latestMessageSent: conv.latestMessageSent,
                                    latestMessageReceived: conv.latestMessageReceived,
                                    unreadCount: conv.unreadMessageCount
                                }
                                return tab;
                            }) : []
                        }
                        handleTabSelect={HandleConversationSelect}
                    />
                </Grid>

                <Grid item  xs={true}  style={{ height: "100%", width:'1%'}} >
                    <MessageBox
                        isLoading={_isLoadState}
                        messages={_messageList}
                        user={{ "uid": JwtHelper.GetUserId(accessToken) }}
                        receiverId={_selectedConversationUserId}
                        onSubmit={HandlerSubmitNewMessage}
                        EmptyContentTitle={
                            _conversationHeaderList === undefined ? "Loading" :
                                _conversationHeaderList && _conversationHeaderList.length > 0 ?
                                    (_selectedConversationUserId ? 'No message from this user' : 'Select one of users from left side to check message history')
                                    : 'The message history is empty at the moment'
                        }
                    />
                </Grid>
            </Grid>
        )
}
