import React, { useContext, useState } from "react";
import { AcademyContext } from "../../../_contexts/UserContext";
import { FileStorageService, IBlobUploadOption } from "../../../_services/FileStorageService";
import { BlobUploadButton } from "./BlobUploadButton";

export const AcademyUploadButton = (props: {
    getUploadOptions: (fileName: string) => Promise<IBlobUploadOption | null>,
    label: string,
    succ: (resp: any) => void,
    err?: (resp: any) => void
}) => {
    const { getUploadOptions, label, succ, err } = props;
    const [progressPercentage, setProgressPercentage] = useState<number | undefined>();
    const [completeStatus, setCompleteStatus] = useState<string | undefined>();


    return <BlobUploadButton
        getUploadOptions={getUploadOptions}
        handleUploadStart={undefined}
        handleUploadOnProgress={setProgressPercentage}
        handleUploadSuccess={(filename: string, url: string) => { setCompleteStatus("success."); succ(url) }}
        handleUploadError={(fileName: string, error: any) => { setCompleteStatus("failed."); err && err(error) }}
    >
        <button>{label}</button><br></br>
        {progressPercentage&&progressPercentage+"%"}<br></br>
        {completeStatus}
    </BlobUploadButton >
}

function withGetUploadOptions(
    WrappedComponent: (props: {
        getUploadOptions: (fileName: string) => Promise<IBlobUploadOption | null>,
        label: string,
        succ: (resp: any) => void,
        err?: (resp: any) => void
    }) => JSX.Element

    , accessType: "Public" | "Private") {


    return (props: {
        label: string,
        succ: (resp: any) => void,
        err?: (resp: any) => void
    }) => {
        const { loginState: { accessToken: token } } = useContext(AcademyContext);
        const getUploadOptions = accessType === "Public" && token ?
            ((fileName: string) => FileStorageService.getBlobUploadOption(fileName, token, "Public")) :
            accessType === "Private" && token ?
                ((fileName: string) => FileStorageService.getBlobUploadOption(fileName, token)) :
                undefined;

        return getUploadOptions ? <WrappedComponent getUploadOptions={getUploadOptions}  {...props as any} /> : <></>



    }
}

export const LMSMaterialUploadButton = withGetUploadOptions(AcademyUploadButton, "Private");
export const LMSPhotoUploadButton = withGetUploadOptions(AcademyUploadButton, "Public");
