import React, { useState } from 'react';
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Badge, TextField, Box } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { HTMLTextOneLineDiv } from './HTMLTextOneLineDiv';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: '#555',

    },
    latestMessage: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }),

);
interface ITabMessage {
  messageDateTime: Date,
  messageContent: string
}
export default function AlignItemsList(
  props: {
    tabs: {
      user: {
        userId: number;
        userName: string;
      },
      latestMessageSent?: ITabMessage | null,
      latestMessageReceived?: ITabMessage | null,
      unreadCount: number;
    }[],
    handleTabSelect: (key: number) => void
  }
) {
  const { tabs, handleTabSelect } = props;
  const classes = useStyles();


  const [selectedIndex, setSelectedIndex] = useState(-1);
  const HandleListItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number, userId: number) => {
    setSelectedIndex(index);
    handleTabSelect(userId)
  };

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const handleSearchKeywordChange = (keyword: string) => {
    setSearchKeyword(keyword)
  }

  return <Box display="flex" height="100%" flexDirection="column">
    <TextField
      variant="outlined"
      label="Search for Users"
      className={"myThemeBorder"}
      margin={"dense"}
      size={"small"}
      onChange={(e) => { handleSearchKeywordChange(e.target.value) }} value={searchKeyword}
    />
    <Box flexGrow={1} overflow={"auto"}>
      <List className={classes.root}>
        {
          tabs
            .sort((a, b) => {
              let result = a.latestMessageReceived && b.latestMessageReceived ?
                (a.latestMessageReceived.messageDateTime > b.latestMessageReceived.messageDateTime ? -1 : 1)
                : a.latestMessageReceived ? -1
                  : b.latestMessageReceived ? 1
                    : 0;
              return result
            }
            )
            .filter(tab => tab.user.userName.toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase()))
            .map((tabItem, index) => (
              <React.Fragment key={tabItem.user.userId}>
                {
                  index > 0 &&
                  <Divider variant="inset" component="li" />
                }

                <ListItem
                  alignItems="flex-start"
                  selected={selectedIndex === index}
                  onClick={(event) => HandleListItemClick(event, index, tabItem.user.userId)}
                >
                  <ListItemAvatar>
                    <Badge
                      badgeContent={tabItem.unreadCount}
                      color="secondary"
                    >
                      <Avatar alt={tabItem.user.userName + ""} src="/static/images/avatar/1.jpg" />
                    </Badge>
                  </ListItemAvatar>

                  <ListItemText
                    primary={tabItem.user.userName}
                    secondary={
                      <div className={classes.latestMessage}>
                        <HTMLTextOneLineDiv
                          messageText={
                            tabItem.latestMessageReceived && tabItem.latestMessageSent ?
                              (
                                tabItem.latestMessageReceived.messageDateTime > tabItem.latestMessageSent.messageDateTime ?
                                  tabItem.latestMessageReceived.messageContent : tabItem.latestMessageSent.messageContent
                              )
                              : tabItem.latestMessageReceived ? tabItem.latestMessageReceived.messageContent
                                : tabItem.latestMessageSent ? tabItem.latestMessageSent.messageContent
                                  : ""

                          }
                        />

                      </div>
                    }
                  />
                </ListItem>
              </React.Fragment>
            )
            )
        }


      </List>
    </Box>
  </Box>
}


