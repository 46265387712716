import { HubConnection } from '@microsoft/signalr'
import { useEffect, useState } from 'react'

export default function useLineNotificationReceiver(connection: HubConnection) {
    const [notification, setNotification] = useState<string | undefined>(undefined)
    useEffect(() => {
        connection.on("PresentLiveNotification", function (resp: { content: string }) {
            setNotification(resp.content);
        })
        return () => {
            connection.off("PresentLiveNotification")
        }
    }, [connection])

    return { notification }
}
