import { HttpHelper } from '../../_commons/Helpers/HttpHelper'
import { LMSMaterialLinkHandler } from '../Common/Handlers';
import { LMSMaterialLinkType } from '../Common/Types';

//public
const GetNewCourseCards = async (handler: any) => {
    let api = `api/Course/NewCourseCards`;
    await HttpHelper.Get(api)
        .then((response) => response.json())
        .then((data) => {
            handler(data);
        })
        .catch((e) => {
            //console.log('Get NewCourseCards error');
            //console.log(e);
        });

}

//public
const getAllCourses = async (handler: any) => {
    let api = `api/Course/Card`;
    await HttpHelper.Get(api)
        .then((response) => response.json())
        .then((data) => {
            handler(data);
        })
        .catch((e) => {
            handler(null);
        });
}

//public
const getCourseByIdForCard = async (id: any, dataHandler: any, exceptionHandler: any) => {
    let api = `api/Course/${id}/Card`;
    await HttpHelper.Get(api)
        .then((response) => response.json()
        )
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            exceptionHandler(e);
        });
}


//public
const getAllCoursesForSearch = async (dataHandler: (data: any) => void, errorHandler = (err: any) => { }, finalHandler = () => { }) => {
    let api = `api/Course/Search`;
    await HttpHelper.Get(api)
        .then((response) => response.json())
        .then((data: any) => {
            dataHandler(data);
        })
        .catch((e) => {
            errorHandler(e);
        }).finally(
            () => { finalHandler() }
        );
}

//public 
const getCourseByIdForDetail = (id: any, respHandler: any, dataHandler: any, errHandler: any) => {
    let api = `api/Course/${id}/Detail`;
    return HttpHelper.Get(api)
        .then(
            async (resp) => {
                if (respHandler(resp)) {

                    dataHandler(await resp.json());

                }
            })
        .catch((e) => {
            errHandler(e);
        });
}
//public  required token
const getCourseModulesWithOrderById = async (token: string, id: any, handler: any) => {

    let api = `api/Course/${id}/ModulesWithOrderv2`;
    await HttpHelper.Get(api, token)
        .then((response) => response.json())
        .then((data) => {
            handler(data);
        })
        .catch((e) => {
            //console.log('getCourseModulesWithOrderById error');
            //console.log(e);
        });
}
//public
const GetCourseModules = (courseId: number): Promise<Response> => {

    let api = `api/Course/${courseId}/Module`;
    return HttpHelper.Get(api);
}


//private
const OwnCourse = (courseId: number, token: string | null): Promise<Response> => {

    let api = `api/Course/OwnCourse/${courseId}`;
    return HttpHelper.Get(api, token);
}


//private
const GetMaterialLink = (courseId: number | string, token: string | null): Promise<LMSMaterialLinkType> => {

    let api = `api/Course/MaterialLink/${courseId}`;
    return LMSMaterialLinkHandler(HttpHelper.Get(api, token));

}

export const CourseService = {
    //public
    getAllCourses,
    getCourseByIdForCard,
    getAllCoursesForSearch,
    getCourseByIdForDetail,
    getCourseModulesWithOrderById,
    GetCourseModules,
    GetNewCourseCards,
    OwnCourse,
    GetMaterialLink



};
