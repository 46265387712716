import { HttpHelper } from '../../_commons/Helpers/HttpHelper';
import { IMembershipPlanModel } from './Models';

export type IGetMembershipPlanResponseData = IMembershipPlanModel

const GetAll = (token: string): Promise<IGetMembershipPlanResponseData[] | null> => {
    return HttpHelper.Get(`/api/CMS/MembershipPlan`, token).then(async (resp) => {
        if (resp.status === 200) {
            let data: IGetMembershipPlanResponseData[] = await resp.json();
            return data;
        } else {
            return null;
        }

    })
}


export const MembershipPlanCMSApi = {
    GetAll
}