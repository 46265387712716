import React from 'react'
import Moment from 'moment';
import {Grid} from '@material-ui/core';
import CourseCardUI from '../CourseCardComponent/CourseCardUIComponent/CourseCardUI';
import CourseSrollContainer from "../CourseScrollContainerComponent/CourseScrollContainer.jsx";


export default function CourseCardList(props) {
    //console.log(props);

    return props.courses?(
        <CourseSrollContainer>

            <Grid container spacing={5} wrap="nowrap" >

                {
                    props.courses.map(
                        (course,index) =>
                            (

                                <Grid item key={index}>
                                    {
                                        props.withPercent ?
                                            <CourseCardUI
                                                courseId={course.courseCard.courseId}
                                                courseTitle={course.courseCard.courseTitle}
                                                courseLink={course.courseCard.courseLink}
                                                coursePhotoLink={course.courseCard.coursePhotoLink}

                                                includeProgress={true}
                                                lastDate={Moment(course.lastDate).format("MMM DD YYYY HH:mm:ss")}
                                                percentage={course.percentage}
                                                certificateLink={course.certificateLink}
                                            /> :
                                            <CourseCardUI
                                                courseId={course.courseId}
                                                courseTitle={course.courseTitle}
                                                courseLink={course.courseLink}
                                                coursePhotoLink={course.coursePhotoLink}
                                            />
                                    }

                                </Grid>
                            )
                    )}



            </Grid>

        </CourseSrollContainer>
    ): <></>
}
