import { Button } from "@material-ui/core";
import React, {  } from "react";
import { LMSMaterialLinkType } from "../../../_services/Common/Types";
import { PrivateResourceLink } from "../../_commons/PrivateResourceLink";

export function LMSMaterialDownloadButton(props: { lmsMaterialLink: LMSMaterialLinkType, activeColor?: string, children: any, className?: string }) {
    const { lmsMaterialLink, children, className } = props;


    const unauthorizedHintText = "Only available for members or who have purchased this course";

    return lmsMaterialLink === "NOT_FOUND" ? <></>
        : lmsMaterialLink === "NOT_AUTHORISED" ?
            <Button className={className} style={{backgroundColor:"#b3b2b2"}}size="small" variant="contained"  title={unauthorizedHintText}>
                <small>{children}</small>
            </Button>
            :
            <PrivateResourceLink href={lmsMaterialLink.link}>
                <Button className={className} size="small" variant="contained" >
                    <small>{children}</small>
                </Button>
            </PrivateResourceLink>
}