
import React from 'react';
export interface ILoginState {
    isLoggedIn: boolean,
    accessToken: string | null
}
export const defaultLoginState = {
    isLoggedIn: false,
    accessToken: null
}
interface IAcademyContext {
    loginState: ILoginState,
    refreshLoginState: () => void,

    unreadCount: number,
    setUnreadCount: (count: any) => void
}

const defaultAcademyAppContext: IAcademyContext = {



    loginState: defaultLoginState,
    refreshLoginState: () => { },


    unreadCount: 0,
    setUnreadCount: (count: number) => { }
}

export const AcademyContext = React.createContext(defaultAcademyAppContext);