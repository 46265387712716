

const MinsToHoursMins = (lengthInMins: number) => {
    return {
        hours: Math.floor(lengthInMins / 60),
        mins: lengthInMins % 60
    }
}
const SecsToHoursMins = (secs: number) => {
    return {
        hours: Math.floor(secs / 3600),
        mins: Math.round(secs / 60) % 60
    }
}
const SecsToMins = (secs: number) => {
    return Math.round(secs / 60);
}

const SecsToDayHourMinSec = (total: number) => {


    let sec = total % 60;
    total = Math.round(total / 60) //total min
    let min = total % 60;
    total = Math.floor(total / 60) //total hour 
    let hour = total % 24;
    total = Math.floor(total / 24) //total day
    let day = total;

    return {
        days: day,
        hours: hour,
        mins: min,
        secs: sec
    }
}

const SecsToReadableStr = (total: number) => {
    let res = SecsToDayHourMinSec(total);


    let str = "";
    if (res.days > 0) {
        str += res.days + (res.days > 1 ? " days " : " day ")
    }

    if (res.hours > 0) {
        str += res.hours + (res.hours > 1 ? " hours " : " hour ")
    }


    str += res.mins + (res.mins > 1 ? " mins " : " min ")



    return str;
}

const MinsToDayHourMinSec = (total: number) => {



    let min = total % 60;
    total = Math.floor(total / 60) //total hour 
    let hour = total % 24;
    total = Math.floor(total / 24) //total day
    let day = total;

    return {
        days: day,
        hours: hour,
        mins: min
    }
}
const MinsToReadableStr = (total: number) => {
    let res = MinsToDayHourMinSec(total);


    let str = "";
    if (res.days > 0) {
        str += res.days + (res.days > 1 ? " days " : " day ")
    }

    if (res.hours > 0) {
        str += res.hours + (res.hours > 1 ? " hours " : " hour ")
    }


    str += res.mins + (res.mins > 1 ? " mins " : " min ")



    return str;
}

export const Time = {
    MinsToHoursMins: MinsToHoursMins,
    SecsToMins,
    SecsToHoursMins,
    SecsToDayHourMinSec,
    SecsToReadableStr,
    MinsToReadableStr
}