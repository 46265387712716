import React, { useEffect, useState } from 'react'
import { CoursePrerequisiteService } from '../../../../_services/radacad-academy-lms/CoursePrerequisiteService';
import { useRadacadAuth } from '../../../../_services/radacad-auth-react/Core/useRadacadAuth';




interface IProps {
    currentCourse: ICourse
    courseList: ICourse[]

}
interface ICourse {
    courseId: number,
    courseTitle: string,

}


export default function PrerequisiteCourseEditor(props: IProps) {

    const {authState:{accessToken}} = useRadacadAuth();
    const [_checkListIsLoading, setCheckListIsLoading] = useState<boolean>(true);
    const [_checkList, setCheckList] = useState<boolean[]>(new Array<boolean>(props.courseList.length).fill(false));

    useEffect(() => {
        setCheckListIsLoading(true);
        InitCheckList(props.currentCourse.courseId, props.courseList, setCheckList);
    }, [props.currentCourse])

    const InitCheckList = async (courseId: number, courseList: ICourse[], checkListSetter: React.Dispatch<React.SetStateAction<boolean[]>>) => {
        await CoursePrerequisiteService.getPreCoursesById(courseId,
            (data) => {

                let checkListResult = new Array<boolean>(courseList.length).fill(false);
                // console.log(checkListResult)

                data.forEach((preCourseObj): void => {
                    let findIndex = courseList.findIndex((course: ICourse) => course.courseId === preCourseObj.preCourseId)
                    if (findIndex !== -1)
                        checkListResult[findIndex] = true;
                });
                // console.log(checkListResult)
                checkListSetter(checkListResult);

                setCheckListIsLoading(false);
            })
    }

    const HandleCheckboxChange = (event: React.MouseEvent<HTMLInputElement>, index: number) => {
        //console.log(event.currentTarget.checked)
        let checked = event.currentTarget.checked;
        setCheckList((oldcheckList: boolean[]): boolean[] => {
            let newCheckList = oldcheckList.slice();
            //console.log(checked)
            newCheckList[index] = checked;

            return newCheckList;
        })
    }
    const HandleSubmitUpdate = async (currentCourse: ICourse, courseList: ICourse[], checkListResult: boolean[]) => {
        //let confirmText = `\n"Prerequesite Course" for "${currentCourse.courseTitle}" would change to`;

        let selectedCourses = courseList.filter((course: ICourse, index: number): boolean => checkListResult[index]);


        // confirmText += selectedCourses.map((course: ICourse, index: number): string => `\n${index + 1}. ${course.courseTitle}`);
        // if (window.confirm(confirmText)) 
        // {

        let selectedCourseId = selectedCourses.map((course: ICourse): number => course.courseId);
        //    alert(selectedCourseId);
        let resp = await CoursePrerequisiteService.UpdatePreCourses(
            accessToken!,
            currentCourse.courseId, 
            selectedCourseId);
        if (resp.status === 200) {
            alert("success");
        }
        else {
            alert("failed");
        }

        // }
    }

    return (
        <div>
            {
                _checkListIsLoading === false && (
                    < div >
                        {props.courseList.map((course: ICourse, index: number) => {
                            return (
                                <div key={index} >
                                    <label className={'hover-pointer'}>
                                        {course.courseId !== props.currentCourse.courseId &&
                                            <input type="checkbox" defaultChecked={_checkList[index]}
                                                onClick={(event) => { HandleCheckboxChange(event, index) }}
                                            />}
                                        {course.courseTitle}
                                    </label>
                                </div>
                            )
                        })}
                        <button onClick={() => { HandleSubmitUpdate(props.currentCourse, props.courseList, _checkList) }} >Submit</button>
                    </div>
                )


            }
        </div>
    )
}
