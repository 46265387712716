import React from 'react';
import { SSOApi } from '../../apis/SSOApi';
import { useRadacadAuth } from '../../Core/useRadacadAuth';
import { IGoogleAuthCredential } from '../../Core/IUserCredential';
import { SSOEnum } from '../../Core/SSOType';
import { GoogleSSOButton } from './GoogleSSOButton';



export function GoogleSSOAddButton(props: {
    onStart?: () => void,
    onSuccess?: () => void,
    onFailure?: (errMsg: string) => void
}) {
    const { onStart, onSuccess, onFailure } = props;
    const { googleLogin, authState: { accessToken: jwtToken } } = useRadacadAuth();


    const handleSuccess = async (cred: IGoogleAuthCredential) => {
        if (jwtToken) {
            onStart && onStart();
            let booleanResponse = await SSOApi.addSSO(SSOEnum.Google, cred, jwtToken);
            if (booleanResponse) {
                const { success, errorMessage } = booleanResponse;
                if (success) {
                    onSuccess && onSuccess()
                } else {
                    onFailure && onFailure(errorMessage)
                }
            } else {
                onFailure && onFailure("Add Google login failed");
            }

        }

    }

    return googleLogin ? <GoogleSSOButton
        onFailure={(error: { errorMessage: string }) => { onFailure && onFailure(error.errorMessage) }}
        onSuccess={handleSuccess}
    /> : <></>
}

