import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { LoadingPage } from '../../../_commons/Utils/React/Components/Pages/LoadingPage';
import { IProduct } from '../../../_viewmodels/ProductModel';
import { AcademyProductService, ProductionEnum } from '../../../_services/radacad-academy-product/AcademyProductService';
import { AffiliateService } from '../../../_services/radacad-academy-affiliate/AffiliateService';


export default function PurchaseCourseCards(props: { courseId: number }) {
    const { courseId } = props;
    const classes = useStyles();
    const [_product, setProduct] = useState<IProduct | undefined | null>(undefined);

    const individualAcademyProductWebPageLinkURL = AcademyProductService.getAcademyMembershipProductWebPageLinkURL(ProductionEnum.Individual);
    const individualAcademyProductImageURL = AcademyProductService.getAcademyMembershipProductImageLinkURL(ProductionEnum.Individual);
    const teamAcademyProductWebPageLinkURL = AcademyProductService.getAcademyMembershipProductWebPageLinkURL(ProductionEnum.Team);
    const teamAcademyProductImageURL = AcademyProductService.getAcademyMembershipProductImageLinkURL(ProductionEnum.Team);


    useEffect(() => {
        AcademyProductService.getProductByCourseId(courseId).then((product) => {
            setProduct(product)
        }).catch(() => { setProduct(null) });

    }, [courseId])


    return <div className={classes.imageContainer}>

        {
            _product !== null &&
            <div className={classes.imageDiv}>
                {
                    _product === undefined ?
                        <LoadingPage /> :


                        <a href={AffiliateService.addAffiliateRefToURL(_product.productWebPageURL)} target="_blank" rel="noopener noreferrer">
                            <h3>{_product.productTitle}</h3>
                            <img src={_product.productImageURL} alt="Individual Academy Membership Product" />
                        </a>

                }
            </div>

        }

        <div className={classes.imageDiv}>

            {
                individualAcademyProductWebPageLinkURL &&
                <a href={AffiliateService.addAffiliateRefToURL(individualAcademyProductWebPageLinkURL)} target="_blank" rel="noopener noreferrer">
                    <h3> Academy Membership</h3>
                    <img src={individualAcademyProductImageURL} alt="Individual Academy Membership Product" />
                </a>
            }
        </div>
        <div className={classes.imageDiv} >

            {teamAcademyProductWebPageLinkURL &&
                <a href={AffiliateService.addAffiliateRefToURL(teamAcademyProductWebPageLinkURL)} target="_blank" rel="noopener noreferrer">
                    <h3>Academy Membership – for Teams</h3>
                    <img src={teamAcademyProductImageURL} alt="Team Academy Membership Product" />
                </a>
            }

        </div>
    </div>


}
const useStyles = makeStyles({

    imageContainer: {
        display: 'flex',
        justifyContent: 'space-around',

    },
    imageDiv: {
        width: "30%",
        padding: "2%",
        display: "inline-block",
        "& a": {
            display: "block",
            padding: "5%",
            borderRadius: "5px",

            height: "100%",
            backdropFilter: "blur(10px)",
            boxShadow: "4px 4px 8px 4px rgba(255,255,255,0.2)",
            transition: "0.3s",

            "&:hover": {
                boxShadow: "8px 8px 16px 8px rgba(255,255,255,0.4)"
            }
        },
        "& img": {
            width: "100%"
        }
    },

})


