import React, { useState } from "react";
import { IMembershipPlanCourseModel, IMembershipPlanModel } from "../../../_services/CMSServices/Models";
import SimpleDropdown from "../../_commons/SimpleDropdown";
import { useMembershipPlanCourseCMSFormStyle } from "./MembershipPlanCourseCMSForm.style";

type DataModelType = IMembershipPlanCourseModel;
export interface ICourseTitleDto { courseId: number, courseTitle: string }
export function MembershipPlanCourseCMSForm(
    props: {
        mpCourse: DataModelType | undefined,
        courses: ICourseTitleDto[] | undefined | null,
        membershipPlans: IMembershipPlanModel[] | undefined | null,

        submitHandler: (course: DataModelType) => void,
        cancelHandler: () => void

    }
) {

    const { mpCourse, courses,membershipPlans, submitHandler, cancelHandler } = props;
    const classes = useMembershipPlanCourseCMSFormStyle();

    const [_membershipPlanId, setMembershipPlanId] = useState<number | undefined>(mpCourse?.membershipPlanId ?? undefined);
    const [_courseId, setCourseId] = useState<number | undefined>(mpCourse?.courseId ?? undefined);

    const handleOnChange = (e: any, setState: (state: any) => void) => {
        setState(e.target.value);
    }

    const handleSubmit = () => {

        submitHandler({
            membershipPlanCourseId: mpCourse?.membershipPlanCourseId ?? 0,
            membershipPlanId: _membershipPlanId??0,
            courseId: _courseId ?? 0
        })

    }
    const handleCancel = () => {
        cancelHandler();
    }
    return <div className={classes.root}>
        <div >
            <label> Membership Plan Id   </label>
            <input
                type="number"
                value={_membershipPlanId}
                disabled
            />

        </div>
        <div>
            <label> Membership Plan  </label>
            {membershipPlans && <SimpleDropdown
                options={
                    membershipPlans.map(
                        (mp: IMembershipPlanModel) => {
                            return { value: mp.membershipPlanId, text: mp.membershipPlanName}
                        }
                    )
                }
                defaultValue={_membershipPlanId ?? undefined}
                valueSelectHanlder={setMembershipPlanId}
            />
            }
        </div>



        <div>
            <label> CourseTitle  </label>
            {courses && <SimpleDropdown
                options={
                    courses.map(
                        (course: ICourseTitleDto) => {
                            return { value: course.courseId, text: course.courseTitle }
                        }
                    )
                }
                defaultValue={_courseId ?? undefined}
                valueSelectHanlder={setCourseId}
            />
            }
        </div>

        <div className={classes.footerContainer}>

            <div>
                <button onClick={() => { handleSubmit() }} disabled={_membershipPlanId===undefined||_courseId===undefined}>Submit</button>
                <button onClick={() => { handleCancel() }}>Cancel</button>
            </div>


        </div>
    </div >
}

