import React from 'react'
import UserSwitch from './UserSwitchComponent/UserSwitch'

export default function UserManagement() {
    return (
        <div>

            <UserSwitch></UserSwitch>
        </div>
    )
}
