import React, { Component } from 'react';

import {CourseService} from '../../../_services/radacad-academy-lms/CourseService';
import CourseCardUI from './CourseCardUIComponent/CourseCardUI';


export default class CourseCard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            courseId: props.courseId,
            courseTitle: "",
            courseLink: "",
            coursePhotoLink: ""
        }
    }
    componentDidMount() {

        CourseService.getCourseByIdForCard(this.state.courseId, 
            (data)=>
            {
                //console.log(data);
                this.setState({
                    courseTitle: data.courseTitle,
                    courseLink: data.courseLink,
                    coursePhotoLink: data.coursePhotoLink,
                    loading: false
                });
            },
            (e)=>{
                //console.log(e);
               
            }
        );

    }


    renderContent() {
        return (
            <div>
                <CourseCardUI
                    courseId={this.state.courseId}
                    courseTitle={this.state.courseTitle}
                    courseLink={this.state.courseLink}
                    coursePhotoLink={this.state.coursePhotoLink}
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderContent();

        return (
            <div>
                {contents}
            </div>
        );
    }
}

