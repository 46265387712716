import { HttpHelper } from '../Helpers/HttpHelper';
import { IFacebookAuthCredential, ILinkedInAuthCredential, IMicrosoftAuthCredential, IGoogleAuthCredential, ITwitterAuthCredential } from '../Core/IUserCredential';
import { SSOEnum } from '../Core/SSOType';



declare type SSOType = SSOEnum.Facebook | SSOEnum.Microsoft | SSOEnum.LinkedIn | SSOEnum.Google | SSOEnum.Twitter;
type AuthCredential = IFacebookAuthCredential | ILinkedInAuthCredential | IMicrosoftAuthCredential | IGoogleAuthCredential | ITwitterAuthCredential;
interface IBooleanReponse {
    success: boolean,
    errorMessage: string
}
type IAddSSOReponse = IBooleanReponse
type IRemoveSSOReponse = IBooleanReponse
async function addSSO(ssoType: SSOEnum, ssoCred: AuthCredential, token: string) {

    let api = `api/${ssoType}SSO/Add`;
    const resp = await HttpHelper.PostJSON(api, ssoCred, token);
    try {
        let data: IAddSSOReponse = await resp.json();
        return data;
    } catch {
        return null;
    }
}


async function removeSSO(ssoType: SSOEnum, token: string) {
    let api = `api/${ssoType}SSO/Remove`;
    const resp = await HttpHelper.PostJSON(api,{}, token);
    try {
        let data: IRemoveSSOReponse = await resp.json();
        return data;
    } catch {
        return null;
    }
}

export const SSOApi = {
    addSSO,
    removeSSO
}