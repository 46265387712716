import { useCallback, useEffect, useState } from 'react'

type IStateType<T> = T | undefined | null;

export default function useResponseData<T>(dataResponseCallBack: ()=>Promise<T | null>): { data: IStateType<T>, handleRefresh: () => void } {
    const [data, setData] = useState<IStateType<T>>();


    const handleRefresh = useCallback(
        () => {
            dataResponseCallBack().then((data: T | null) => setData(data)).catch(()=>setData(null));
        },
        [dataResponseCallBack],
    )

    useEffect(() => {
        handleRefresh();

    }, [handleRefresh])

    return { data, handleRefresh };
}



