import React, { useEffect, useState } from 'react'
import { BadgeService } from '../../../_services/BadgeService';
import { IBadge, IUserBadge } from '../../../_viewmodels/BadgeModel';
import { Badge } from './Badge';

export default function UserBadges(props: { accessToken: string }) {

    const { accessToken } = props;
    const [badges, setBadges] = useState<IBadge[] | null | undefined>(undefined);
    const [userBadges, setUserBdages] = useState<IUserBadge[] | null | undefined>(undefined);
    useEffect(() => {
        BadgeService.GetAllBadges().then(async (resp) => {
            if (resp.status === 200) {
              //  console.debug(resp);
                try {
                    let data: IBadge[] = await resp.json();
                    setBadges(data);
                    // alert(JSON.stringify(data));
                } catch (e) {
                    // alert(e);

                    setBadges(null);
                }

            } else {
                setBadges(null);
            }

        })
    }, [])

    useEffect(() => {
        if (badges && accessToken) {
            BadgeService.GetUserBadges(accessToken).then(async (resp) => {
                if (resp.status === 200) {

                   // console.debug(resp);
                    try {

                        let data: IUserBadge[] = await resp.json();
                       // console.debug(data);
                        setUserBdages(data);
                    } catch (e) {
                        //console.debug(e);
                        setUserBdages(null);
                    }
                } else {
                    setUserBdages(null);
                }

            })
        } else {
            setUserBdages(null)
        }
    }, [badges, accessToken])


    const renderLoading = () => {

        return (<div>
            Loading...
        </div>)
    }
    const renderError = () => {
        return (
            <div>
                Error
            </div>
        )
    }

    const renderAllBadges = (badges: IBadge[]) => {

        return <div style={{display:"flex", flexWrap:"wrap"}}>
            {
                badges.map((badge: IBadge) => {
                    return (
                        <React.Fragment key={badge.badgeId}>
                            <Badge 
                                badgeMeta={badge}
                                userCompletionState={{ completed: userBadges ? (userBadges.some((ub: IUserBadge) => ub.badgeId === badge.badgeId)) : false }}

                                containerStyle={{
                                    display:"flex",
                                    flexDirection:"column",
                                    alignItems:"center",
                                    margin:"10px"
                                }}
                                imgStyle={{height:"150px"}}

                            />
                        </React.Fragment>
                    )
                })
            }
        </div>
    }



    return badges === undefined ?
        renderLoading()
        : badges === null ?
            renderError()
            :
            renderAllBadges(badges)
}


