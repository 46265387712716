import React, { useContext, useEffect, useRef, useState } from 'react'
import { HubConnection, HubConnectionState, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
interface ILiveNotificationHubContext {
    connection: HubConnection,
    connectionState: HubConnectionState
}
const defaultHubContext: ILiveNotificationHubContext = {
    connection: new HubConnectionBuilder()
        .withUrl("/livenotificationhub")
        .configureLogging(LogLevel.Information)
        .build(),
    connectionState: HubConnectionState.Disconnected,
}

const LiveNotificationHubContext = React.createContext<ILiveNotificationHubContext>(defaultHubContext);
export const useLiveNotificationHubConnection = () => useContext<ILiveNotificationHubContext>(LiveNotificationHubContext);


export default function LiveNotificationHubProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const hubConnectionRef = useRef<HubConnection>(
        new HubConnectionBuilder()
            .withUrl("/livenotificationhub")
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build());
    const [_connectionState, setConnectionState] = useState<HubConnectionState>(HubConnectionState.Disconnected);





    useEffect(() => {
        const startHubConnection = async () => {
            try {
                await hubConnectionRef.current.start();
                setConnectionState(hubConnectionRef.current.state);
                console.log("Successfully connected to signalR hub.");
            } catch (error) {
                console.log(`Error establishing connection to signalR hub: ${error}`);
            }

        }

        const stopHubConnection = async () => {
            try {
                await hubConnectionRef.current.stop();
                setConnectionState(HubConnectionState.Disconnected);
                console.log("Successfully disconnected to signalR hub.");
            } catch (error) {
                console.log(`Error stopping connection to signalR hub: ${error}`);
            }
        }
        const BeforeunloadHanlder = async (event: any) => {
            event.preventDefault();
            stopHubConnection();
        }

        hubConnectionRef.current.onclose(() => {
            setConnectionState(HubConnectionState.Disconnected);
        })
        startHubConnection();
        window.addEventListener("beforeunload", BeforeunloadHanlder);
        return () => {
            window.removeEventListener("beforeunload", BeforeunloadHanlder);

        }


    }, [])

    return (
        <LiveNotificationHubContext.Provider
            value={{
                connection: hubConnectionRef.current,
                connectionState: _connectionState
            }}
        >
            {children}
        </LiveNotificationHubContext.Provider>
    )
}



