import React, { useEffect, useState} from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";


import { CourseService } from '../../../../_services/radacad-academy-lms/CourseService';
import { AdminService } from '../../../../_services/AdminService';
import { useRadacadAuth } from '../../../../_services/radacad-auth-react/Core/useRadacadAuth';



interface IProps {
  currentCourse: ICourse
}

interface ICourse {
  courseId: number,
  courseTitle: string,

}
interface IModule {
  moduleId: number,
  moduleTitle: string,
  moduleOrder: number,
  description: string,
  lessonCount: number
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = (isDraggingOver: any) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

export default function CourseModuleEditor(props: IProps) {

  const { authState: { accessToken } } = useRadacadAuth();
  const [_isLoading, setIsLoading] = useState<boolean>(true);


  const [_moduleList, setModuleList] = useState<IModule[] | null>(null);

  useEffect(() => {
    setIsLoading(true);
    FetchModule(props.currentCourse.courseId);

  }, []);

  useEffect(() => {
    //console.log(_moduleList)
  }, [_moduleList]);

  const FetchModule = async (courseId: number) => {

    CourseService.GetCourseModules(courseId)
      .then(
        async (resp) => {
          let data = await resp.json();
          //console.log(data);
          data.sort((a: IModule, b: IModule) => a.moduleOrder - b.moduleOrder);
          setModuleList(data)
        }
      ).catch(
        (err) => {
          setModuleList(null);
        }
      ).finally(
        () => {
          setIsLoading(false);
        }
      )
  }

  const onDragEnd = (result: any): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderModuleList = reorder(
      _moduleList!,
      result.source.index,
      result.destination.index
    );

    setModuleList(reorderModuleList);
  }

  const HandleSubmit = () => {
    AdminService.UpdateCourseModule(
      props.currentCourse.courseId,
      _moduleList!.map((module, index) => { return { moduleId: module.moduleId, moduleOrder: index } }),
      accessToken!
    )
      .then(
        resp => {
          if (resp.status === 200) {
            alert('success')
          }
          else {
            alert('failed');
          }
        }
      )
  }

  return _isLoading ? <div>isLoading</div>
    : _moduleList === null ? <div>No Modules for this course</div>
      : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <RootRef rootRef={provided.innerRef}>
                  <List style={getListStyle(snapshot.isDraggingOver)}>
                    {
                      _moduleList.map((module: IModule, index) => (

                        <Draggable key={module.moduleId} draggableId={module.moduleId + ""} index={index}>
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              // ContainerProps={{ ref: provided.innerRef }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ListItemIcon>
                                {index + 1}
                              </ListItemIcon>
                              <ListItemText
                                primary={module.moduleTitle}
                                secondary={module.description}
                              />
                              {/* <ListItemSecondaryAction>
                            <IconButton>
                              <EditIcon />
                            </IconButton>
                          </ListItemSecondaryAction> */}
                            </ListItem>
                          )}


                        </Draggable>


                      ))
                    }

                    {provided.placeholder}
                  </List>
                </RootRef>
              )}
            </Droppable>
          </DragDropContext>
          <button onClick={() => HandleSubmit()}>Submit</button>
        </>
      )
}

