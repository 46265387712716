import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
export default function SimpleBackdrop(props: { open: boolean }) {
    const { open } = props;
    return <Backdrop
        style={{ color: '#fff', zIndex: 1001 }}
        open={open}
    >
        <CircularProgress color="inherit" />
    </Backdrop>;
}