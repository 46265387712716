import React, { useEffect, useRef } from 'react';


// export interface IMessage{
//   "text": string,
//   "id": string,
//   "sender": {
//           "name": string,
//           "uid": string,
//           "avatar"?: string,
//       }
// }
// export interface IMessageUser{
//   "uid":string
// }


// interface IChatBoxProps {
//   messages: IMessage[],
//   isLoading: boolean,
//   user: IMessageUser,
//   onSubmit: (message: string)=>void
//   renderMessage?: () => void,
//   typingListner?: () => void,
//   typingIndicator?: JSX.Element,

// }


export default function MessageList(props) {

  const { isLoading, messages, user, renderMessage, renderNoMessage, EmptyContentTitle, EmptyContentSubTitle, renderLoadingMessage } = props;

  const bottomDivRef = useRef(null);

  useEffect(() => {
    if (!isLoading && messages.length) {
      if (bottomDivRef && bottomDivRef.current) {
        // bottomDivRef.current.scrollIntoView({ behavior: 'smooth' });
        bottomDivRef.current.scrollIntoView();
      }
    }

  }, [isLoading, messages]);

  let chatContent = renderLoadingMessage ? renderLoadingMessage()
    : (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '150px'
      }}>
        <span style={{

          color: '#1546dc',
          fontSize: ' 25px',
          marginTop: '20px',

        }}>Loading Messages</span>
      </div >
    );

  if (!isLoading && !messages.length) {
    chatContent = renderNoMessage ? renderNoMessage()
      : (
        <div style={{
          position: 'relative',
          margin: ' auto',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
        }}>

          <div>
            <h2 style={{
              textAlign: 'center',
              padding: '0;',
              fontSize: '2rem',
              margin: '25px auto 15px',
              maxWidth: '400px'
            }}> {EmptyContentTitle ?? 'No new message?'} </h2>
            <h6
              style={{
                textAlign: 'center',
                color: ' #555',
                fontSize: '18px'
              }}>
              {EmptyContentSubTitle ?? 'Send your first message below.'}
            </h6>
          </div>
        </div >
      );
  }
  else if (!isLoading && messages.length) {
    chatContent = renderMessage ? renderMessage(messages, user)
      : messages.map((message, index) => {

        let isUser = user.uid === message.sender.uid;
        let renderName;
        if (isUser) {
          renderName = null;
        } else {
          renderName = <div className='sender-name'>{message.sender.name}</div>;
        }
        return (
          <div
            key={index}
            className='chat-bubble-row'
            style={{ flexDirection: isUser ? 'row-reverse' : 'row' }}>
            <img
              src={message.sender.avatar}
              alt='sender avatar'
              className='avatar'
              style={isUser ? { marginLeft: '15px' } : { marginRight: '15px' }}
            />
            <div className={`chat-bubble ${isUser ? 'is-user' : 'is-other'}`}>
              {renderName}
              <div
                className='message'
                style={{ color: isUser ? '#FFF' : '#2D313F' }}>
                {message.text}
              </div>
            </div>
          </div>
        );

      });

  }
  return (
    < >
      {chatContent}
      <div ref={bottomDivRef}></div>
    </>
  );

}
