import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react'

import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import { LessonService } from '../../_services/radacad-academy-lms/LessonService';
import { IGroupedLessons } from '../../_services/radacad-academy-lms/models/IGroupedLessons';
import { ILessonPublicDto } from '../../_services/radacad-academy-lms/models/ILessonPublicDto';
import { defaultPagenatedList, IPagenatedList } from '../../_services/radacad-academy-lms/models/IPagenatedList';
import CourseCardUI, { CourseForCard } from '../CoursePage/CourseCardComponent/CourseCardUIComponent/CourseCardUI';
import LessonCard from '../LessonPage/LessonCardComponent/LessonCard';
import { useCallback } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export function SearchPageLessonResult(props: {
    keyword: string,
    courses: CourseForCard[],
    resultCountCallback?: (resultCount: number) => void,
    notHidden?: boolean

}) {
    const { keyword, courses, resultCountCallback, notHidden } = props;
    const [firstLoading, setFirstLoading] = useState<boolean>(false);
    const [pagenatedGroupedLessons, setPagenatedGroupedLesson] = useState<IPagenatedList<IGroupedLessons>>(defaultPagenatedList);



    useEffect(() => {
        let count = 0;
        pagenatedGroupedLessons.items.forEach((groupedLessons) => {
            count += groupedLessons.lessonSearchResult.length
        })
        resultCountCallback && resultCountCallback(count);

    },
        [pagenatedGroupedLessons.items])



    const maxPageIndex = useRef<number>(0);
    const handleDebounceLoadNew = useMemo(
        () => debounce((keyword: string) => {
            setFirstLoading(true)
            LessonService.searchGroupedLessons(keyword).then(
                data => {
                    setPagenatedGroupedLesson(data);
                    maxPageIndex.current = data.pageIndex;

                }

            ).finally(
                () => setFirstLoading(false)
            )
        }, 1500),
        [],
    )
    useEffect(() => {
        maxPageIndex.current = 0;
        handleDebounceLoadNew(keyword)
    }, [keyword, handleDebounceLoadNew]);




    const handleLoadMore = useCallback(
        () => {
            if ((document.documentElement.scrollTop + document.documentElement.clientHeight) / document.documentElement.scrollHeight > 0.85) {
                if (maxPageIndex.current > 0 &&
                    Math.max(maxPageIndex.current, pagenatedGroupedLessons.pageIndex) < pagenatedGroupedLessons.totalPages
                ) {
                    maxPageIndex.current = Math.max(maxPageIndex.current, pagenatedGroupedLessons.pageIndex) + 1
                    LessonService.searchGroupedLessons(keyword, maxPageIndex.current).then(
                        data => {
                            setPagenatedGroupedLesson(page => { return { ...data, items: [...page.items, ...data.items] } });
                        }
                    )
                }

            }

        },
        [keyword, pagenatedGroupedLessons.pageIndex, pagenatedGroupedLessons.totalPages],
    )
    const throttleLoadMore = useMemo(
        () => throttle(() => {
            handleLoadMore();

        }, 2000)
        ,
        [handleLoadMore])
    useEffect(() => {
        if (notHidden) {

            window.addEventListener('scroll', throttleLoadMore);
            window.addEventListener('resize', throttleLoadMore);
            return () => {
                window.removeEventListener('scroll', throttleLoadMore)
                window.removeEventListener('resize', throttleLoadMore)
            }
        }
    }, [notHidden, throttleLoadMore])

    useEffect(() => {
        if (notHidden && maxPageIndex.current > 0) {

            let timeout = setTimeout(handleLoadMore, 2000)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [notHidden, handleLoadMore])





    const renderCourseCard = (course: CourseForCard) => {
        return <CourseCardUI courseId={course.courseId} coursePhotoLink={course.coursePhotoLink} courseTitle={course.courseTitle} />
    }
    const renderLessonList = (lessons: ILessonPublicDto[]) => {
        return <Grid container spacing={1} alignContent="flex-start" style={{ height: "300px", overflowY: "auto", overflowX: "hidden" }}>
            {

                lessons.map(
                    (lesson: ILessonPublicDto) =>
                        <Grid item>
                            <LessonCard key={lesson.lessonId}
                                lesson={lesson}
                            />
                        </Grid>
                )
            }
        </Grid>
    }
    const renderGroupedLesson = (groupedLessons: IGroupedLessons) => {
        return <div style={{ display: "flex" }}>
            {
                courses.find((course) => parseInt(course.courseId) === groupedLessons.courseId) &&
                renderCourseCard(courses.find((course) => parseInt(course.courseId) === groupedLessons.courseId)!)
            }
            {
                renderLessonList(groupedLessons.lessonSearchResult)
            }

        </div >
    }
    return (
        <Grid container spacing={4} justifyContent="flex-start">
            {firstLoading ?
                <Grid item sm={10} >

                    <LinearProgress />
                </Grid>
                :
                <>

                    {
                        pagenatedGroupedLessons.items.map(
                            (groupedLessons: IGroupedLessons) =>
                                <Grid item sm={12} lg={6} key={groupedLessons.courseId}>
                                    {renderGroupedLesson(groupedLessons)}
                                </Grid>
                        )
                    }
                    {
                        pagenatedGroupedLessons.pageIndex < pagenatedGroupedLessons.totalPages &&
                        <Grid item sm={12} >
                            <LinearProgress />
                        </Grid>
                    }</>}

        </Grid>
    )
}
