
import { CircularProgress } from '@material-ui/core'
import React from 'react'

export function LoadingPage(props: { text?: string }) {
    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <CircularProgress />
        {props.text}
    </div>
}