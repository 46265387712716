import React from "react";
import { ICertificateTemplateModel } from "../../../_services/CMSServices/Models";

type DataModelType = ICertificateTemplateModel;
export function CertificateTemplateCMSTable(props: {
    dataList: DataModelType[] | undefined | null,
    editButtonHandler: (course: DataModelType) => void,
    deleteButtonHandler: (courseId: number) => void
}
) {

    const { dataList, editButtonHandler, deleteButtonHandler } = props;


    return dataList === undefined ? <div>isLoading...</div> :
        dataList === null ? <div>Data fetching failed</div> :
            <table>
                <thead>

                    <tr>
                        <td>Title</td>
                        <td>Url</td>
                        <td colSpan={2}>Actions</td>

                    </tr>
                </thead>
                <tbody>
                    {dataList.map((value: DataModelType, index: number) => {
                        return <tr key={value.certificateTemplateId}>
                            <td>{value.certificateTitle}</td>
                            <td>{value.certificateURL}</td>

                            <td><button onClick={() => { editButtonHandler(value) }}>Edit</button></td>
                            <td><button onClick={() => { deleteButtonHandler(value.certificateTemplateId) }}>Remove </button></td>

                        </tr>

                    })}
                </tbody>
            </table>




}
