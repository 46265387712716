import React, { useEffect } from 'react'
import { ComponentType } from 'react';
import { AffiliateService } from './AffiliateService';
import { setSessionStorageValue } from '../../_commons/Utils/BrowserStorage';
import { useSearchParams } from '../../_commons/Utils/React/Hooks/useSearchParams';

export default function withAffiliateRef(WrappedComponent: ComponentType): ComponentType {

    function WithAffiliateRefComponent(props: any) {
        const { ref } = useSearchParams(AffiliateService.AFFILIATE_REF_STRING);
        useEffect(() => {
            //console.debug(`${AffiliateService.AFFILIATE_REF_STRING}: ${ref}`);
            ref && setSessionStorageValue(AffiliateService.AFFILIATE_REF_STRING, ref);
        }, [ref])

        return <WrappedComponent {...props as any} />
 
    }
    return WithAffiliateRefComponent;

}
