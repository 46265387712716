import { Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { FacebookSSOAddButton } from '../../_services/radacad-auth-react/Components/FacebookButtons/FacebookSSOAddButton'
import { FacebookSSORemoveButton } from '../../_services/radacad-auth-react/Components/FacebookButtons/FacebookSSORemoveButton'
import { GoogleSSOAddButton } from '../../_services/radacad-auth-react/Components/GoogleButtons/GoogleSSOAddButton'
import { GoogleSSORemoveButton } from '../../_services/radacad-auth-react/Components/GoogleButtons/GoogleSSORemoveButton'
import { LinkedInSSOAddButton } from '../../_services/radacad-auth-react/Components/LinkedInButtons/LinkedInSSOAddButton'
import { LinkedInSSORemoveButton } from '../../_services/radacad-auth-react/Components/LinkedInButtons/LinkedInSSORemoveButton'
import { useRadacadAuth } from '../../_services/radacad-auth-react/Core/useRadacadAuth'
import { IUserSSOState, UserService } from '../../_services/UserService'
import CustomizedSnackbars from '../_commons/CustomizedSnackbars'
import { LoadingAnimation } from '../_commons/LoadingAnimation'
import SimpleBackdrop from '../_commons/SimpleBackDrop'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const UserSSOStatus = () => {

    const { authState: { accessToken } } = useRadacadAuth();
    const [backDropOpen, setBackDropOpen] = useState<boolean>(false)
    const [userSSOState, setUserSSOState] = useState<IUserSSOState | undefined | null>(undefined);
    const [messages, setMessages] = useState<{ text: string, severity?: "success" | "error" }[]>([])
    const handleOnStart = useCallback(() => {
        setBackDropOpen(true)
    }
        , []);
    const handleOnSuccess = useCallback(() => {
        setBackDropOpen(false)
        setMessages(messages => [...messages, { text: "Successful!", severity: "success" }]);

    }
        , []);
    const handleOnFailure = useCallback((errMsg: string) => {
        setBackDropOpen(false)
        setMessages(messages => [...messages, { text: errMsg, severity: "error" }]);

    }
        , []);
    useEffect(() => {
        accessToken &&
            UserService.GetUserSSO(accessToken).then(
                (data) => {
                    setUserSSOState(data)
                }
            )
    }, [accessToken])

    return userSSOState === undefined ? <LoadingAnimation />
        : userSSOState === null ? <></>
            :
            <Grid container spacing={1}>

                {
                    userSSOState.facebookOn ?
                        <SSORemoveButtonWrapper>
                            <FacebookSSORemoveButton
                                onStart={handleOnStart}
                                onSuccess={
                                    () => {
                                        handleOnSuccess();
                                        setUserSSOState(s => { return s ? { ...s, facebookOn: false } : s });

                                    }
                                }
                                onFailure={handleOnFailure}
                            />
                        </SSORemoveButtonWrapper>
                        : <SSOAddButtonWrapper>
                            <FacebookSSOAddButton
                                onStart={handleOnStart}
                                onSuccess={
                                    () => {
                                        handleOnSuccess();
                                        setUserSSOState(s => { return s ? { ...s, facebookOn: true } : s });

                                    }
                                }
                                onFailure={handleOnFailure}
                            />
                        </SSOAddButtonWrapper>
                }{
                    userSSOState.googleOn ?
                        <SSORemoveButtonWrapper >

                            <GoogleSSORemoveButton
                                onStart={handleOnStart}
                                onSuccess={
                                    () => {
                                        handleOnSuccess();
                                        setUserSSOState(s => { return s ? { ...s, googleOn: false } : s });

                                    }
                                }
                                onFailure={handleOnFailure} />
                        </SSORemoveButtonWrapper>
                        :
                        <SSOAddButtonWrapper>
                            <GoogleSSOAddButton
                                onStart={handleOnStart}
                                onSuccess={() => {
                                    handleOnSuccess();
                                    setUserSSOState(s => { return s ? { ...s, googleOn: true } : s });

                                }}
                                onFailure={handleOnFailure}

                            />
                        </SSOAddButtonWrapper>
                }
                {
                    userSSOState.linkedInOn ?
                        <SSORemoveButtonWrapper>
                            <LinkedInSSORemoveButton
                                onStart={handleOnStart}
                                onSuccess={() => {
                                    handleOnSuccess();
                                    setUserSSOState(s => { return s ? { ...s, linkedInOn: false } : s });

                                }}
                                onFailure={handleOnFailure}
                            />
                        </SSORemoveButtonWrapper> :
                        <SSOAddButtonWrapper>
                            <LinkedInSSOAddButton
                                onStart={handleOnStart}
                                onSuccess={() => {
                                    handleOnSuccess();
                                    setUserSSOState(s => { return s ? { ...s, linkedInOn: true } : s });

                                }}
                                onFailure={handleOnFailure}
                            />
                        </SSOAddButtonWrapper>
                }


                <SimpleBackdrop open={backDropOpen} />
                {
                    messages !== null &&
                    messages.map((message, index) =>
                    (
                        <CustomizedSnackbars key={index} message={message.text} severity={message.severity} ></CustomizedSnackbars>
                    ))
                }
            </Grid>
}

const useStyle = makeStyles({
    root: {
        display: "inline-block",
        position: "relative",
        padding: "0  !important",
        marginRight:"6px"
    },
    grayWrapper: {
        filter: "grayscale(100%)",
        "&:hover": {
            filter: "grayscale(0)"
        }
    },
    green: {
        color: "#4DBD91 !important",
        position: "absolute",
        right: '0',
        bottom: "0",
        width: "40%",
        height: "40%",
        pointerEvents: "none"
    }
})
const SSOAddButtonWrapper = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const classes = useStyle();
    return <Grid item className={`${classes.root} ${classes.grayWrapper}`} title={"Add new Link"}>
        {children}
    </Grid>

}
const SSORemoveButtonWrapper = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const classes = useStyle();
    return <Grid item className={`${classes.root} `} title={"Unlink this account"}>
        <CheckCircleIcon className={classes.green} />
        {children}
    </Grid>
}