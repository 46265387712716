import { HttpHelper } from '../../_commons/Helpers/HttpHelper';
import { ILMSAuthorModel } from './Models';

type IGetAllLMSAuthorResponseData = ILMSAuthorModel[];
const GetAllAuthors = (token: string): Promise<IGetAllLMSAuthorResponseData | null> => {
    return HttpHelper.Get('/api/LMS/Author/All', token).then(async (resp) => {
        if (resp.status === 200) {
            let data: IGetAllLMSAuthorResponseData = await resp.json();
            return data;
        } else {
            return null;
        }

    })
}


export const LMSAuthorApi = {
    GetAllAuthors

}