import React, { useState, useEffect } from 'react'
import { AuthorService } from '../../../_services/radacad-academy-lms/AuthorService';
import AuthorCard from '../AuthorCardComponent/AuthorCard';
import { Grid } from '@material-ui/core';
import FullScreenLoadingProgress from '../../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';

export default function AuthorList() {

    const [_isLoading, setIsLoading] = useState(true);
    const [authorList, setAuthorList] = useState([]);

    useEffect(() => {
        AuthorService.RequestGetAuthorList((data) => {
            //console.log(data);
            setAuthorList(data);
            setIsLoading(false);
        })

    }, [])

    return _isLoading ?
        <FullScreenLoadingProgress></FullScreenLoadingProgress>
        : (
            <Grid container spacing={3}>
                {
                    authorList.map((author, index) =>
                        (<Grid item key={index}>
                            <AuthorCard
                                authorId={author.authorId}
                                authorName={author.authorName}
                                authorPhotoLink={author.photoLink}
                                courseCount={author.courseCount}

                            >

                            </AuthorCard>
                        </Grid>)

                    )
                }
            </Grid>
        )
}
