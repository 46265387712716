import { HttpHelper } from "../_commons/Helpers/HttpHelper";


//private 
const GetUserProfile = async (dataHandler: any, token: string) => {

    await HttpHelper.Get(`api/User/Profile`, token)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('getUserProfile error');
            //console.log(e);
        });


}

export interface IUserMetrics {
    totalWatchTimeInSec: number,
    certificateCount: number,
    inProgressCourseCount: number,
    questionCount: number,
    consecutiveDays: number,
    learnTimesByMonth: {
        monthDate: string,
        monthLearnTime: number,
    }[],
    last7DaysLearnTime: {
        date: string,
        learnTime: number
    }[],
    last8WeeksLearnTime: {
        startDate: string,
        endDate: string,
        learnTime: number
    }[],
    maxDayLearnTime: {
        dayDate: string,
        dayLearnTime: number
    },
    maxNonStopWatchTimeLength: number
}
//private 
const GetUserMetrics = (token: string): Promise<Response> => {
    return HttpHelper.Get(`api/User/Metrics`, token);
}

export interface IUserSSOState {
    facebookOn: boolean,
    linkedInOn: boolean,
    googleOn: boolean
}
//private 
const GetUserSSO = (token: string): Promise<IUserSSOState | null> => {
    return HttpHelper.Get(`api/User/SSOState`, token).then(
        async (resp) => {
            let data: IUserSSOState = await resp.json();

            return data;
        }
    ).catch(() => null);
}

const GetUserEmailNotificationSetting = (token:string):Promise<boolean|null>=>{
    return HttpHelper.Get('/api/User/UserNotificationSetting',token).then(
        async (resp)=>{
            let data:boolean = await resp.json();
            console.log(data);
            return data;
        }
    ).catch(()=>null);
}

const SetUserEmailNotificationSetting = (openStatus:boolean, token:string)=>{
    return HttpHelper.PostJSON('/api/User/UserNotificationSetting',{openStatus:openStatus},token);
}



export const UserService = {
    //private 
    GetUserProfile: GetUserProfile,
    GetUserMetrics: GetUserMetrics,
    GetUserSSO,
    GetUserEmailNotificationSetting,
    SetUserEmailNotificationSetting
}