import React from 'react';
//@ts-ignore
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { ISSOButtonProps } from '../../Core/ISSOButtonProps';
import { ILinkedInAuthCredential } from '../../Core/IUserCredential';
import { LinkedInIcon } from './icons/LinkedInIcon';




export function LinkedInSSOButton(props: ISSOButtonProps<ILinkedInAuthCredential> & {
    children?: React.ReactNode
}
) {
    // const redirectUri =
    //     process.env.NODE_ENV === "development" ?
    //         process.env.REACT_APP_LINKEDIN_REDIRECT_URI ?? process.env.PUBLIC_URL + '/linkedin'
    //         : process.env.PUBLIC_URL + '/linkedin'
    const redirectUri = process.env.REACT_APP_LINKEDIN_REDIRECT_URI!
    const { onSuccess, onFailure, children } = props;

    return <LinkedIn
        clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
        onFailure={onFailure}
        onSuccess={(data: { code: any }) => onSuccess({ code: data.code, redirectUri: redirectUri })}
        scope="r_liteprofile r_emailaddress"
        redirectUri={`${redirectUri}`}
        supportIE
        redirectPath='/linkedin'
    >
        {children ?? <LinkedInIcon />}
    </LinkedIn>

}
