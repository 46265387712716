import React from 'react';
import Switch from '@material-ui/core/Switch';
//ref:https://material-ui.com/components/switches/#switch
export default function Switches(props: {
    defaultValue?: boolean,
    onChangleHandler: (result: boolean) => void,
    disabled?: boolean | undefined,
    color?: "primary" | "secondary" | "default",
    inputProps?: React.InputHTMLAttributes<HTMLInputElement> | undefined
}) {
    const { defaultValue, onChangleHandler: handleChangeCallback, color, disabled, inputProps } = props;
    const [_state, setState] = React.useState<boolean>(defaultValue ?? false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setState(event.target.checked);
        handleChangeCallback(event.target.checked);
    };

    return (
        <Switch
            checked={_state}
            onChange={handleChange}
            disabled={disabled}
            color={color}
            inputProps={inputProps}
        />
    );
}