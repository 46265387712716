import { HttpHelper } from "../_commons/Helpers/HttpHelper";

//private
const AddCompletedLesson = async (token: string, lessonId: string, dataHandler: (data: any) => void) => {
    const lessonCompletionRequest =
    {
        lessonId: parseInt(lessonId),
        completed: true
    }

    let api = `api/Learner/UpdateLessonCompletion`;
    await HttpHelper.PostJSON(api, lessonCompletionRequest, token)
        .then(
            (response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('addCompletedLesson error');
            //console.log(e);
        });

}

//private
const PostVideoActionHistory = async (token: string, lessonId: number, historyData: any, autoStartPosition: any, lastWatchDateTime: any, respHandler = (resp: any) => { }, errHandler = (err: any) => { }) => {


    const bodydata = {
        lessonId: lessonId,
        autoStartPosition,
        lastWatchDateTime,
        playHistoryList: historyData
    }
    //console.debug(JSON.stringify(bodydata))
    await HttpHelper.PostJSON(`api/Learner/VideoPlayingHistory`, bodydata, token)
        .then(
            resp => respHandler(resp)
        ).catch(
            e => errHandler(e)
        )
}

const RequestOverwriteLearningProgress = async (token: string, dataHandler: (data: any) => void) => {


    await HttpHelper.Get(`api/Learner/OverwriteProgress`, token)
        .then(
            (response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('OverwriteLearningProgressRequest');
            //console.log(e);
        });

}
const RequestGetCourseLearnt = async (token: string, dataHandler: (data: any) => void) => {


    let api = `api/Learner/CourseProgress`;
    await HttpHelper.Get(api, token)
        .then(
            (response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('RequestGetCourseLearnt error');
            //console.log(e);
        });
}

const RequestAllLessonCompletionStatus = async (token: string, dataHandler: (data: any) => void) => {



    let api = `api/Learner/LessonCompleted`;
    await HttpHelper.Get(api, token)
        .then(
            (response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('Request all LessonCompletedForModulepage error');
            //console.log(e);
        });

}
const RequestGetCertificate = async (token: string, courseId: string) => {

    let api = `api/Learner/GetCertificate/${courseId}`;

    await HttpHelper.Get(api, token)
        .then(res => res.blob()) // 解析res值为blob
        .then(response => URL.createObjectURL(response))
        .then(url => window.open(url))
        .catch(err => console.error(err));




    // .then(response => response.body)
    // .then(rs => {
    //   const reader = rs.getReader();

    //   return new ReadableStream({
    //     async start(controller) {
    //       while (true) {
    //         const { done, value } = await reader.read();

    //         // When no more data needs to be consumed, break the reading
    //         if (done) {
    //           break;
    //         }

    //         // Enqueue the next data chunk into our target stream
    //         controller.enqueue(value);
    //       }

    //       // Close the stream
    //       controller.close();
    //       reader.releaseLock();
    //     }
    //   })
    // })
    // // Create a new response out of the stream
    // .then(rs => new Response(rs))
    // // Create an object URL for the response
    // .then(response => response.blob())
    //     .then(blob => URL.createObjectURL(blob))
    //     .then(url =>window.open(url))
    //     .catch(err => console.error(err));


}



export const LearnerService = {
    //private
    AddCompletedLesson,
    PostVideoActionHistory,
    RequestOverwriteLearningProgress,
    RequestGetCourseLearnt,
    RequestAllLessonCompletionStatus,
    RequestGetCertificate



}