import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CustomizedSnackbars from '../_commons/CustomizedSnackbars.jsx';

import { AcademyContext } from '../../_contexts/UserContext';
import FullScreenLoadingProgress from '../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';
import Copyright from './Copyright'
import { SystemService } from '../../_services/SystemService';
import { useRadacadAuth } from '../../_services/radacad-auth-react/Core/useRadacadAuth';
import { FacebookSSOButton } from '../../_services/radacad-auth-react/Components/FacebookButtons/FacebookSSOButton';
import { IFacebookAuthCredential, IGoogleAuthCredential, ILinkedInAuthCredential } from '../../_services/radacad-auth-react/Core/IUserCredential';
import { LinkedInSSOButton } from '../../_services/radacad-auth-react/Components/LinkedInButtons/LinkedInSSOButton';
import { GoogleSSOButton } from '../../_services/radacad-auth-react/Components/GoogleButtons/GoogleSSOButton';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    position: 'relative'

  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ErrorMessagesText = {
  VERSION_OUT_OF_DATE: "Version of this website you are using seems already out of date, please try pressing 'Ctrl+F5' to update this website.",
  PASSWORD_NOT_CORRECT: "The account and password cannot be empty, please type in valid information.",
  SERVER_ERROR: "Some error happens connecting to server, please try pressing 'Ctrl+F5' to update this website first to see if this problem could be fixed.",

}
export default function SignIn() {
  const classes = useStyles();

  let history = useHistory();


  const { passwordLogin, facebookLogin, linkedInLogin, googleLogin } = useRadacadAuth(); // a global states 


  const [UserLogin, setUserLogin] = useState("");
  const [Password, setPassword] = useState("");
  const [WaitingResult, setWaitingResult] = useState(false);
  const [ErrorMessages, setErrorMessages] = useState<string[]>([]);



  useEffect(() => {
    SystemService.GetSystemVersionNumber().then(async (resp) => {
      try {
        if (resp.status === 200) {
          let sysVersionNumber = await resp.text();

          if (process.env.REACT_APP_VERSION_NUMBER !== sysVersionNumber) {
            LoginFailedHandler(ErrorMessagesText.VERSION_OUT_OF_DATE);
          }
        } else {
          LoginFailedHandler(ErrorMessagesText.VERSION_OUT_OF_DATE);
        }
      } catch (e) {
        LoginFailedHandler(ErrorMessagesText.VERSION_OUT_OF_DATE);
      }


    })

    if (userLoginInputRef && userLoginInputRef.current) {
      userLoginInputRef.current.focus();

    }

  }, []);

  /**
   * Operation on UserLogin Input TextField
   */
  const userLoginInputRef = useRef<HTMLInputElement>(null);
  const UserLoginFocusHandler = () => {
    // console.log('focus');
    // console.log(userLoginInputRef?.current.value);
    if (userLoginInputRef && userLoginInputRef.current) {
      userLoginInputRef.current.setSelectionRange(0, userLoginInputRef.current.value.length);
    }

  }
  const UserLoginChangeHandler = (event: any) => {
    setUserLogin(event.target.value);
  }
  const UserLoginKeyDownHandler = (event: any) => {
    if (event.key === 'Enter') {
      if (passwordInputRef && passwordInputRef.current)
        passwordInputRef.current.focus();
    }
  }


  /**
   * Operation on User Password Input TextField
   */
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const PasswordFocusHandler = () => {
    if (passwordInputRef && passwordInputRef.current)
      passwordInputRef.current.setSelectionRange(0, passwordInputRef.current.value.length);
  }
  const PasswordChangeHandler = (event: any) => {
    setPassword(event.target.value);
  }
  const PasswordKeyDownHandler = (event: any) => {
    if (event.key === 'Enter') {
      LoginSubmitHandler(event);
    }
  }




  /**
   * Operation on Login Submit
   */
  const LoginSubmitHandler = (e: any) => {
    e.preventDefault();
    if (UserLogin === "" || Password === "") {
      LoginFailedHandler(ErrorMessagesText.PASSWORD_NOT_CORRECT);
      return;
    }

    setWaitingResult(true);
    passwordLogin && passwordLogin(
      UserLogin,
      Password,
      {
        afterSuccessHandler: LoginSuccessHandler,
        failureHandler: LoginFailedHandler
      }
    );
  }

  const handleFacebookApiSuccess = async (data: IFacebookAuthCredential) => {

    if (data.accessToken && facebookLogin) {
      setWaitingResult(true);
      facebookLogin(
        data,
        {
          afterSuccessHandler: LoginSuccessHandler,
          failureHandler: LoginFailedHandler
        }

      );
    }
  }
  const handleGooglApiSuccess = async (cred: IGoogleAuthCredential) => {
    if (cred.idToken !== "" && googleLogin) {
      setWaitingResult(true);
      googleLogin(
        {
          idToken: cred.idToken
        },
        {
          afterSuccessHandler: LoginSuccessHandler,
          failureHandler: LoginFailedHandler
        }
      );
    }
  }
  const handleLinkedInApiSuccess = async (data: ILinkedInAuthCredential) => {

    if (data.code !== "" && linkedInLogin) {
      setWaitingResult(true);
      linkedInLogin(data,
        {
          afterSuccessHandler: LoginSuccessHandler,
          failureHandler: LoginFailedHandler
        }

      );
    }
  }

  const LoginSuccessHandler = () => {
    history.push('/');
  }
  const LoginFailedHandler = (error: string) => {
    setErrorMessages(ErrorMessages => [...ErrorMessages, error]);
    setWaitingResult(false);
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} title={`RADACAD Version${process.env.REACT_APP_VERSION_NUMBER ?? "??"}`}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>


        <form className={classes.form} noValidate >
          {WaitingResult ?
            <FullScreenLoadingProgress style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 999 }}></FullScreenLoadingProgress>
            : <></>
          }
          <div style={WaitingResult ? { opacity: '0.1' } : {}}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username or Email Address"
              name="email"
              autoComplete="email"

              inputRef={userLoginInputRef}
              //autoFocus={true}
              onFocus={UserLoginFocusHandler}
              onChange={UserLoginChangeHandler}
              onKeyDown={UserLoginKeyDownHandler}
              className={"myThemeBorder"}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"

              inputRef={passwordInputRef}
              onFocus={PasswordFocusHandler}
              onChange={PasswordChangeHandler}
              onKeyDown={PasswordKeyDownHandler}

              className={"myThemeBorder"}
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={LoginSubmitHandler}
            >
              Sign In
            </Button>
          </div>
        </form>
        <Grid container>
          <Grid item xs>
            <Link href="https://learn.radacad.com/my-account/lost-password/" target="_blank" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="https://learn.radacad.com/academy/" target="_blank" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </div>


      <div><strong>Login With Other Accounts</strong></div>
      <Grid container spacing={1}>


        {facebookLogin &&
          <Grid item>
            <FacebookSSOButton
              onSuccess={handleFacebookApiSuccess}
            />
          </Grid>
        }
        {googleLogin &&
          <Grid item>
            <GoogleSSOButton
              onSuccess={handleGooglApiSuccess}
            />
          </Grid>
        }
        {linkedInLogin &&
          <Grid item>
            <LinkedInSSOButton
              onSuccess={handleLinkedInApiSuccess}
            />
          </Grid>
        }
      </Grid>


      {ErrorMessages !== null &&
        ErrorMessages.map((errorMessage, index) =>
        (
          <CustomizedSnackbars key={index} message={errorMessage} ></CustomizedSnackbars>
        ))
      }


      < Box mt={8}>
        <Copyright />
      </Box>
    </Container >
  );
}

