import { HubConnection } from '@microsoft/signalr'
import  { useCallback } from 'react'

export default function useLiveNotificationSender(connection: HubConnection) {
    const send = useCallback(
        (message: string) => {
            connection.invoke('CreateLiveNotification',{content:message})
        }, [connection])

    return { send }
}
