export interface IPagenatedList<T> {
    items: T[],
    pageIndex: number,
    totalPages: number,
    totalCount: number
}
export const defaultPagenatedList = {
    items: [],
    pageIndex: 0,
    totalPages: 0,
    totalCount: 0
}