import React, { useEffect, useState } from 'react'
import { ConfirmFormDiv } from '../_commons/ConfirmFormDiv';
import { DragDropList } from '../_commons/DragDropList';


export interface IOrderElement {
  id: number | string,
  order: number,
  label: string
}
interface IOrderEditorProps {
  initialDataList: IOrderElement[] | null,
  pageState: "Loading" | "Showing" | "Waiting" | "Error"
  submitHandler: (resultList: IOrderElement[]) => void,
  cancelHandler: () => void,
}

export function BaseOrderingEditor(props: IOrderEditorProps) {
  const { initialDataList, pageState, submitHandler, cancelHandler } = props;


  const [_list, setList] = useState<IOrderElement[] | null>(null);
  useEffect(() => {
    setList(initialDataList)
  }, [initialDataList])


  const handleSubmit = () => {
    _list && submitHandler(_list);
  }
  const handleCancel = () => {
    cancelHandler();
  }


  return <ConfirmFormDiv
    submitHandler={handleSubmit}
    cancelHandler={handleCancel}
    submitDisabled={pageState!=="Showing"||_list===null||_list.length === 0}
    cancelDisabled={pageState==="Waiting"}
  >

    {pageState !== "Showing" ? <div>{pageState}</div> :
      _list === null || _list.length === 0 ? <div>No Elements in this List</div>
        : (<DragDropList
          list={_list}
          setList={setList}
        />
        )
    }

  </ConfirmFormDiv>

}

