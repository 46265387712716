export const fakeLearningPathDiagramData =
    [
        {
            "persona": "BI Analyst",
            "imageURL": "/img/BI_Analyst.png"
        },
        {
            "persona": "BI Developer",
            "imageURL": "/img/BI_Developer.png"
        },
        {
            "persona": "BI Architect",
            "imageURL": "/img/BI_Architect.png"
        },
        {
            "persona": "Applied AI Specialist",
            "imageURL": "/img/Applied_AI_Specialist.png"
        },
        {
            "persona": "ETL Developer",
            "imageURL": "/img/ETL_Developer.png"
        },
        {
            "persona": "Power Platform Architect and Developer",
            "imageURL": "/img/Power_Platform_Architect_and_Developer.png"
        },
        {
            "persona": "Data Scientist",
            "imageURL": "/img/Data_Scientist.png"
        },
        {
            "persona": "Database Developer",
            "imageURL": "/img/Database_Developer.png"
        }
    ]