import React from "react";
import { ILMSModuleModel } from "../../../_services/CMSServices/Models";
import { PrivateResourceLink } from "../../_commons/PrivateResourceLink";
import { useLMSModuleTableStyles } from "./LMSModuleTable.style";

export function LMSModuleTable(props: {
    module: ILMSModuleModel[] | null | undefined,
    editButtonHandler: (module: ILMSModuleModel) => void,
    deleteButtonHandler: (moduleId: number) => void,
    orderButtonHandler: (moduleId: number) => void,
}
) {

    const { module, editButtonHandler, deleteButtonHandler, orderButtonHandler } = props;
    const classes = useLMSModuleTableStyles(7)();
    return module === undefined ? <div>isLoading...</div> :
        module === null ? <div>Data fetching failed</div> :
            <table className={classes.LMSTable}>
                <thead>
                    <tr>
                        <td>Module Title</td>
                        <td>ModuleOrder</td>
                        <td>Description</td>
                        <td>Material</td>
                        <td colSpan={3}>Actions</td>

                    </tr>
                </thead>
                <tbody>
                    {module.map((value: ILMSModuleModel, index: number) => {
                        return <tr key={value.moduleId}>
                            <td>{value.moduleTitle}</td>
                            <td>{value.moduleOrder}</td>
                            <td>{value.description}</td>
                            <td>
                                {
                                    value.moduleMaterialLink &&
                                    <PrivateResourceLink href={value.moduleMaterialLink}>
                                        <button>download</button>
                                    </PrivateResourceLink>
                                }
                            </td>
                            <td><button onClick={() => { orderButtonHandler(value.moduleId) }}>Order lessons in this module </button></td>
                            <td><button onClick={() => { editButtonHandler(value) }}>Edit this module</button></td>
                            <td><button onClick={() => { deleteButtonHandler(value.moduleId) }}>Remove this module</button></td>
                        </tr>
                    })}
                </tbody>
            </table>
}
