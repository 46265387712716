import { HttpHelper } from '../../_commons/Helpers/HttpHelper';
import { IMembershipPlanCourseModel } from './Models';

export interface IGetMembershipPlanCourseResponseData extends IMembershipPlanCourseModel {     courseTitle:string};
const GetAll = (token: string): Promise<IGetMembershipPlanCourseResponseData[] | null> => {
    return HttpHelper.Get(`/api/CMS/MembershipPlanCourse`, token).then(async (resp) => {
        if (resp.status === 200) {
            let data: IGetMembershipPlanCourseResponseData[] = await resp.json();
  
            return data;
        } else {
            return null;
        }

    })
}


const Update = (action: "ADD" | "EDIT", mpCourse: IMembershipPlanCourseModel, token: string): Promise<boolean> => {
    if (action === "ADD")
        return HttpHelper.PostJSON('api/CMS/MembershipPlanCourse/Add', mpCourse, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
    else
        return HttpHelper.PostJSON('api/CMS/MembershipPlanCourse/Edit', mpCourse, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
}

interface IDeleteRequestData {
    membershipPlanCourseId: number
}
const Delete = (reqData: IDeleteRequestData, token: string): Promise<boolean> => {
    return HttpHelper.PostJSON('api/CMS/MembershipPlanCourse/Delete', reqData, token).then(async (resp) => {
        if (resp.status === 200)
            return true;
        else
            return false;
    })
}

export const MembershipPlanCourseCMSApi = {
    GetAll,
    Update,
    Delete
}