import { makeStyles } from '@material-ui/core';
export const useLMSLessonFormStyle = makeStyles({


    fullwidthInputDiv: {
        width: "100%",
        "& input": {
            width: "100%"
        }
    },
    uploadContainer: {
        display: "flex",
        justifyContent: "space-between",
        "& img": {
            maxHeight: "200px",
            maxWidth: "200px"
        }
    },


})