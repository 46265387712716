import React, { Component } from 'react'
import { Grid, Chip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Dropdown } from 'semantic-ui-react'
import { CourseService } from '../../_services/radacad-academy-lms/CourseService';


import CourseCardUI from '../CoursePage/CourseCardComponent/CourseCardUIComponent/CourseCardUI';
import FullScreenLoadingProgress from '../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';
import { SearchPageLessonResult } from './SearchPageLessonResult';


const options = [
    { key: 1, text: 'Publish Date Newest First', value: 1 },
    { key: 2, text: 'Publish Date Oldest First', value: 2 },
    { key: 3, text: 'Name From A to Z', value: 3 },
    { key: 4, text: 'Name From Z to A', value: 4 }
]

export default class CourseSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            firstChange: true,
            keyword: "",
            allCourses: [],
            searchResult: [],
            lessonResultCount: 0,
            defaultSearchOption: 1,
            currentSearchOption: 1,
            resultOption: 1
        }

        this.searchBarOnChangeHandler = this.searchBarOnChangeHandler.bind(this);
        this.dropdownChangeHandler = this.dropdownChangeHandler.bind(this);
    }

    componentDidMount() {
        CourseService.getAllCoursesForSearch(
            (data) => {
                this.setState({ allCourses: data, loading: false });
                this.getSearchResult(this.state.keyword);
            }
        );
    }

    searchBarOnChangeHandler(e) {
        const value = e.target.value;
        this.setState({ keyword: e.target.value, firstChange: false })


        this.getSearchResult(value)
    }
    dropdownChangeHandler(event, data) {
        this.setState({ currentSearchOption: data.value });
        this.sortSearchResult(this.state.searchResult, data.value);
    }


    getSearchResult(keyword) {
        let result = this.state.allCourses.filter(
            course => course.courseTitle.toLowerCase().includes(keyword.toLowerCase())
        );
        this.sortSearchResult(result, this.state.currentSearchOption);
    }
    sortSearchResult(resultToSort, option) {
        switch (option) {
            case 3:
                // //console.log("sort by name ascending");
                this.setState(
                    {
                        searchResult:
                            resultToSort.sort(function (a, b) {
                                return ('' + a.courseTitle).localeCompare(b.courseTitle);
                            })
                    }
                )
                break;
            case 4:
                // //console.log("sort by name descending");
                this.setState(
                    {
                        searchResult:
                            resultToSort.sort(function (b, a) {
                                return ('' + a.courseTitle).localeCompare(b.courseTitle);
                            })
                    }
                )
                break;
            case 1:
                // //console.log("sort by publish date new to old ");
                this.setState(
                    {
                        searchResult:
                            resultToSort.sort(function (a, b) {
                                return new Date(b.publishDate) - new Date(a.publishDate);
                            })
                    }
                )
                break;
            case 2:
                // //console.log("sort by publish date old to new ");
                this.setState(
                    {
                        searchResult:
                            resultToSort.sort(function (a, b) {
                                return new Date(a.publishDate) - new Date(b.publishDate);
                            })
                    }
                );
                break;
            default:
        }
    }


    render() {
        return this.state.loading ?
            <FullScreenLoadingProgress></FullScreenLoadingProgress>
            :
            (
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{
                        position: "sticky",
                        top: "0px",
                        backgroundColor: "#202121",
                        zIndex: 1
                    }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={true}>
                                <TextField
                                    id="search bar"
                                    label="Search"
                                    style={{ margin: 8 }}
                                    placeholder="Search "
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    onChange={this.searchBarOnChangeHandler}
                                    className={'myThemeBorder'}
                                />
                            </Grid>
                            {
                                this.state.resultOption === 1 &&
                                <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                                    <Grid>Sort by: </Grid>
                                    <Grid>
                                        <Dropdown options={options} fluid selection
                                            defaultValue={this.state.defaultSearchOption}
                                            onChange={this.dropdownChangeHandler}
                                            className={'myThemeDropdown'}
                                        >
                                        </Dropdown>
                                    </Grid>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <Chip
                                    style={{ margin: '2px' }}
                                    className={`MuiChip-Dark MuiChip-Rect ${this.state.resultOption === 1 ? 'MuiChip-Dark-Selected' : ''}`}
                                    label={`Course: ${this.state.searchResult.length} results`} clickable onClick={() => { this.setState({ resultOption: 1 }) }}
                                />
                                {
                                    this.state.keyword === "" ?
                                        <Chip
                                            style={{ margin: '2px' }}
                                            className={`MuiChip-Dark MuiChip-Rect `}
                                            label={`Lesson`} clickable={false} onClick={() => { this.setState({ resultOption: 2 }) }}
                                        />
                                        : <Chip
                                            style={{ margin: '2px' }}
                                            className={`MuiChip-Dark MuiChip-Rect ${this.state.resultOption === 2 ? 'MuiChip-Dark-Selected' : ''}`}
                                            label={`Lesson${this.state.keyword === "" ? "" : `: ${this.state.lessonResultCount} results`}`} clickable onClick={() => { this.setState({ resultOption: 2 }) }}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Grid>



                    <Grid item xs={12} hidden={this.state.resultOption !== 1}>
                        <Grid container spacing={2} justify="space-around">
                            {
                                this.state.searchResult.map(
                                    course =>
                                    (
                                        <Grid item key={course.courseId} >
                                            <CourseCardUI
                                                courseId={course.courseId}
                                                courseTitle={course.courseTitle}
                                                courseLink={course.courseLink}
                                                coursePhotoLink={course.coursePhotoLink}
                                            />
                                        </Grid>
                                    )

                                )
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} hidden={this.state.resultOption !== 2}>
                        {this.state.keyword !== "" &&
                            <SearchPageLessonResult
                                keyword={this.state.keyword}
                                courses={this.state.allCourses}
                                notHidden={this.state.resultOption === 2}
                                resultCountCallback={(count) => this.setState({ lessonResultCount: count })}
                            />
                        }
                    </Grid>

                </Grid >
            )
    }
}
