import React, { useState, useEffect } from 'react';
import { Layout } from './_components/Layout';

import FullScreenLoadingProgress from './_components/_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';
import withAffiliateRef from './_services/radacad-academy-affiliate/withAffiliateRef';
import { ChatMessageService, GetUnreadMessageCountData } from './_services/radacad-academy-message/ChatMessageService';
import { useRadacadAuth } from './_services/radacad-auth-react/Core/useRadacadAuth';
import { AcademyContext } from './_contexts/UserContext';
import { Route } from 'react-router';
//@ts-ignore
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

function App() {

  const [_unreadCount, setUnreadCount] = useState<number>(0);
  const { loading: loadingAuth, refreshAuthState, authState } = useRadacadAuth();

  useEffect(() => {
    //console.log(`login State update ${JSON.stringify(_loginState)} from effect _loginState`)
    let unreadInterval: NodeJS.Timeout;

    //setup message reader
    if (authState.authenticated) {
      //add timer for unreadmessage counter
      const HandleSyncUnreadMessageCount = () => {
        if (authState.authenticated) {
          ChatMessageService.GetUnreadMessageCount(
            authState.accessToken!,
            async (resp) => {
              try {
                let data: GetUnreadMessageCountData = await resp.json();
                setUnreadCount(data.unreadMessageCount);
              }
              catch {
                setUnreadCount(0);
              }
            }
          );
        }
        else {
          setUnreadCount(0);
        }
      }
      unreadInterval = setInterval(HandleSyncUnreadMessageCount, 10000);
    }
    return () => {
      clearInterval(unreadInterval);
    }
  }, [authState])

  return (<>
    <Route exact path="/linkedin" component={LinkedInPopUp} />
    <AcademyContext.Provider
      value={{
        loginState: { isLoggedIn: authState.authenticated, accessToken: authState.accessToken },
        refreshLoginState: refreshAuthState,

        unreadCount: _unreadCount,
        setUnreadCount: setUnreadCount
      }}
    >
      {loadingAuth ?
        <FullScreenLoadingProgress></FullScreenLoadingProgress>
        : <Layout />
      }
    </AcademyContext.Provider>
  </>
  );

}


export default withAffiliateRef(App);