import React from "react";
import { Link } from "react-router-dom";
import { ICertificateTemplateModel, ILMSAuthorModel, ILMSCourseModel } from "../../../_services/CMSServices/Models";
import { PrivateResourceLink } from "../../_commons/PrivateResourceLink";
import { useLMSCourseTableStyles } from "./LMSCourseTable.style";

export function LMSCourseTable(props: {
    courses: ILMSCourseModel[] | undefined | null,
    authors: ILMSAuthorModel[] | undefined | null,
    certificateTemplates: ICertificateTemplateModel[] | undefined | null,
    editButtonHandler: (course: ILMSCourseModel) => void,
    deleteButtonHandler: (courseId: number) => void
}
) {

    const { courses, authors, certificateTemplates, editButtonHandler, deleteButtonHandler } = props;
    const classes = useLMSCourseTableStyles(12)();

    return courses === undefined ? <div>isLoading...</div> :
    courses === null ? <div>Data fetching failed</div> :
            <table className={classes.LMSTable}>
                <thead>
                    <tr>
                        <td>Course Title</td>
                        <td>Author  </td>
                        <td>courseDescription</td>
                        <td>courseLink</td>
                        <td>coursePhotoLink</td>
                        <td>Publish Status</td>
                        <td>Created Date</td>
                        <td>Certificate Template</td>
                        <td>Material</td>
                        <td colSpan={3}>Actions</td>

                    </tr>
                </thead>
                <tbody>
                    {courses.map((course: ILMSCourseModel, index: number) => {
                        return <tr key={course.courseId}>
                            <td>{course.courseTitle}</td>
                            <td>   {authors?.find(a => a.authorId === course.authorId)?.authorName}</td>
                            <td>{course.courseDescription}</td>
                            <td>{course.courseLink}</td>
                            <td>{course.coursePhotoLink}</td>
                            <td>{course.publishStatus + ""}</td>
                            <td>{course.createdDate}</td>
                            <td>   {certificateTemplates?.find(a => a.certificateTemplateId === course.certificateTemplateId)?.certificateTitle}</td>
                            <td>  {course.courseMaterialLink && <PrivateResourceLink href={course.courseMaterialLink}><button>download</button></PrivateResourceLink>}</td>
                            <td><button onClick={() => { editButtonHandler(course) }}>Edit</button></td>
                            <td><Link to={`/Course/${course.courseId}`} target="_blank" ><button >Preview</button></Link></td>
                            <td><button onClick={() => { deleteButtonHandler(course.courseId) }}>Remove </button></td>

                        </tr>

                    })}
                </tbody>
            </table>
}
