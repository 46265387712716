import React, { useState } from 'react'
import { SSOApi } from '../../apis/SSOApi';
import { useRadacadAuth } from '../../Core/useRadacadAuth';
import { IFacebookAuthCredential } from '../../Core/IUserCredential';
import { SSOEnum } from '../../Core/SSOType';
import { FacebookSSOButton } from './FacebookSSOButton';

export function FacebookSSOAddButton(props: {
    onStart?: () => void,
    onSuccess?: () => void,
    onFailure?: (errMsg: string) => void
}) {
    const { onStart,onSuccess, onFailure } = props;
    const { facebookLogin, authState: { accessToken: jwtToken } } = useRadacadAuth();

    const handleSuccess = async (data: IFacebookAuthCredential) => {
        if (jwtToken) {
            onStart&&onStart();
            let booleanResponse = await SSOApi.addSSO(SSOEnum.Facebook, data, jwtToken);
            if (booleanResponse) {
                const { success, errorMessage } = booleanResponse;
                if (success) {
                    onSuccess && onSuccess()
                } else {
                    onFailure && onFailure(errorMessage)
                }
            } else {
                onFailure && onFailure("Add Facebook login failed");
            }
        }
    }

    return facebookLogin ? <FacebookSSOButton
        onSuccess={handleSuccess}
    /> : <></>
}