import React, { useContext } from 'react'
import { useCertificateTemplateData } from '../../../_commons/Hooks/useCertificateTemplateData';
import useLMSAuthor from '../../../_commons/Hooks/useLMSAuthor';
import useLMSCourse from '../../../_commons/Hooks/useLMSCourse';
import { useDynamicContentDialog } from '../../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
import { AcademyContext } from '../../../_contexts/UserContext';
import { LMSCourseApi } from '../../../_services/CMSServices/LMSCourseApi';
import { ILMSCourseModel } from '../../../_services/CMSServices/Models';
import { useLMSCourseStyle } from './LMSCourse.style';
import { LMSCourseForm } from './LMSCourseForm';
import { LMSCourseTable } from './LMSCourseTable';




export default function LMSCourse() {


    const { loginState: { accessToken } } = useContext(AcademyContext);
    const token = accessToken ?? ""


    const classes = useLMSCourseStyle();
    const { course, handleRefresh } = useLMSCourse(token);
    const { authors } = useLMSAuthor(token);
    const { data: certificateTemplates } = useCertificateTemplateData(token);


    const useDialog = useDynamicContentDialog({ maxWidth: 'lg', fullWidth: true, disableBackdropClick: true });



    const handleAddOrEditCourse = (option: { action: "ADD" } | { action: "EDIT", course: ILMSCourseModel }) => {
        useDialog.open(
            <LMSCourseForm
                course={option.action === "ADD" ? undefined : option.course}
                authors={authors}
                certificateTemplates={certificateTemplates}
                submitHandler={(course: ILMSCourseModel) => { handleFormSubmit(option.action, course) }}
                cancelHandler={handleFormCancel}
            />
        )
    }


    const handleFormSubmit = (action: "ADD" | "EDIT", course: ILMSCourseModel) => {

        if (window.confirm("confirm to submit?"))
            LMSCourseApi.UpdateLMSCourse(action, course, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    useDialog.close();
                    handleRefresh();
                }

                else
                    alert("failed");

            })

    }
    const handleFormCancel = () => {

        useDialog.close();
    }
    const handleDeleteCourse = (courseId: number) => {
        if (window.confirm("confirm to submit?"))
            LMSCourseApi.DeleteLMSCourse({ courseId }, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    handleRefresh();
                }

                else
                    alert("failed");

            })
    }
    return (
        <div className={`${classes.root}`}>
            <div className={classes.header}>
                <button onClick={() => handleAddOrEditCourse({ action: "ADD" })}>Add New Course</button>
            </div>

            <div className={classes.body}>
                <LMSCourseTable
                    courses={course}
                    authors={authors}
                    certificateTemplates={certificateTemplates}
                    editButtonHandler={(course: ILMSCourseModel) => handleAddOrEditCourse({ action: "EDIT", course: course })}
                    deleteButtonHandler={handleDeleteCourse}
                />
            </div>
            <div className={classes.footer}>

            </div>
            {useDialog.render()}
        </div>
    )
}

