import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom';


import { Grid, Divider, Button, TextField } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FullScreenLoadingProgress from '../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';
import LessonCard from '../LessonPage/LessonCardComponent/LessonCard';

import { AuthContext } from '../../_services/radacad-auth-react/Core/AuthContext';
import { CourseService } from '../../_services/radacad-academy-lms/CourseService'
import { LearnerService } from '../../_services/LearnerService';


export default class ModuleComponent extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            courseId: this.props.match.params.CourseId,
            courseTitle: "",
            moduleList: [],
            lessonList: [],
            completionStatusList: [],
            loading: true,
            keywordForSearch: ""
        }
        this.searchBarOnChangeHandler = this.searchBarOnChangeHandler.bind(this);
    }
    componentDidMount() {
        if (this.state.courseId !== null) {
            CourseService.getCourseModulesWithOrderById(
                this.context.authState.accessToken,
                this.state.courseId,
                (data) => {
                    //console.log(data);
                    try {


                        data.modules ?
                            this.setState({ courseTitle: data.courseTitle, moduleList: data.modules, loading: false })
                            : data.lessons ? this.setState({ courseTitle: data.courseTitle, lessonList: data.lessons, loading: false })
                                : this.setState({ loading: false });


                        if (this.context.authState.authenticated) {
                            //console.log('send request to fetch learner progress');
                            LearnerService.RequestAllLessonCompletionStatus(
                                this.context.authState.accessToken,
                                (data) => {
                                    try {
                                        //console.log(data);
                                        this.setState({ completionStatusList: data.lessonsCompleted });
                                    }
                                    catch {
                                        console.console("completion status error ");
                                    }

                                }
                            );
                        }
                    }
                    catch {
                        this.setState({ loading: false });
                        console.console("Lesson list read  error ");
                    }
                }
            )

        }

    }

    renderLessonCards(lessonList) {
        lessonList = this.state.keywordForSearch === "" ?
            lessonList
            : lessonList.filter((lesson) => lesson.lessonTitle.toLowerCase().includes(this.state.keywordForSearch))
        return <Grid container spacing={2}>
            {
                lessonList.map((lesson, index) =>
                    <Grid item xs={12} key={lesson.lessonId}>

                        {
                            this.context.authState.authenticated ?
                                <LessonCard
                                    lesson={lesson}
                                    showProgress={true}
                                    completed={lesson.completed || this.state.completionStatusList.some(l => l.lessonId === lesson.lessonId)}
                                />
                                :
                                <LessonCard
                                    lesson={lesson}
                                    showProgress={false}
                                    completed={false}
                                />
                        }
                    </Grid>
                )
            }
        </Grid>



    }
    searchBarOnChangeHandler(event) {
        this.setState({ keywordForSearch: event.target.value.toLowerCase() })
    }

    render() {
        if (this.state.courseId === null)
            return (<Redirect to="/" push />)
        else
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}
                        style={{
                            position: "sticky",
                            top: "0px",
                            backgroundColor: "#202121",
                            zIndex: 1
                        }}>
                        <Grid container spacing={2}>
                            <Grid item >
                                <Button variant="outlined" color="primary" startIcon={<ArrowBackIosIcon />} className={'myThemeButton'}>
                                    <Link to={`/Course/${this.state.courseId}`}>Back to Course</Link>
                                </Button>
                            </Grid>
                            <Grid item xs={true}>
                                <TextField
                                    id="search bar"
                                    label="Search"
                                    placeholder="Search "
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    onChange={this.searchBarOnChangeHandler}
                                    className={'myThemeBorder'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>

                        {
                            this.state.loading ?
                                <FullScreenLoadingProgress></FullScreenLoadingProgress>
                                :
                                (
                                    <>
                                        <h1> {this.state.courseTitle} </h1>

                                        {
                                            this.state.moduleList !== null && this.state.moduleList.length > 0
                                                ?
                                                this.state.moduleList.map(
                                                    module =>

                                                        <Grid container key={module.moduleId} spacing={2}>

                                                            <Grid item xs={12}>
                                                                <Divider light={true}></Divider>
                                                                <h2 style={{ marginBottom: "0", fontStyle: "italic " }} dangerouslySetInnerHTML={{ __html: module.moduleTitle }}></h2>
                                                                <p dangerouslySetInnerHTML={{ __html: module.description }} ></p>

                                                            </Grid>
                                                            {
                                                                module.lessons != null ?
                                                                    <Grid item xs={12}>
                                                                        <p>{module.lessons.length} Lesson(s)</p>
                                                                        {
                                                                            this.renderLessonCards(module.lessons)
                                                                        }

                                                                    </Grid> :
                                                                    <Grid item xs={12}>
                                                                        No lesson in this module
                                                                    </Grid>
                                                            }
                                                        </Grid>
                                                )
                                                :
                                                this.state.lessonList !== null && this.state.lessonList.length > 0 ?
                                                    (
                                                        <Grid container>
                                                            <Grid item xs={12} >
                                                                <p>{this.state.lessonList.length} Lesson(s)</p>
                                                            </Grid >
                                                            <Grid item >

                                                                {
                                                                    this.renderLessonCards(this.state.lessonList)
                                                                }

                                                            </Grid >
                                                        </Grid>
                                                    ) :
                                                    (
                                                        <Grid container>
                                                            <Grid item >
                                                                <p>There is no lesson found for this course</p>
                                                            </Grid >
                                                        </Grid>
                                                    )
                                        }
                                    </>
                                )
                        }
                    </Grid>
                </Grid>
            )
    }
}
