import { IFacebookAuthCredential, IGoogleAuthCredential, ILinkedInAuthCredential, IMicrosoftAuthCredential, ITwitterAuthCredential } from './IUserCredential';
import { defaultAuthState, IAuthState } from "./IAuthState";


export declare type IPasswordLogin = (
    userLogin: string,
    password: string,
    options: ILoginOptions
) => void

export declare type IFacebookLogin = (
    authCredential: IFacebookAuthCredential,
    options: ILoginOptions
) => void

export declare type IMicrosoftLogin = (
    authCredential: IMicrosoftAuthCredential,
    options: ILoginOptions
) => void

export declare type ILinkedInLogin = (
    authCredential: ILinkedInAuthCredential,
    options: ILoginOptions
) => void

export declare type IGoogleLogin = (
    authCredential: IGoogleAuthCredential,
    options: ILoginOptions
) => void
export declare type ITwitterLogin = (
    authCredential: ITwitterAuthCredential,
    options: ILoginOptions
) => void



export declare type ILoginFailureHandler = (error: string) => void
export declare type ILoginSuccessAfterHandler = () => void
export declare type ILoginSuccessBeforeHandler = () => void
export declare interface ILoginOptions {
    beforeSuccessHandler?: ILoginSuccessBeforeHandler,
    afterSuccessHandler?: ILoginSuccessAfterHandler,
    failureHandler: ILoginFailureHandler
}


export declare type ILogout = () => void

export enum LoginErrorMessage {
    VERSION_OUT_OF_DATE = "Version of this website you are using seems already out of date, please try pressing 'Ctrl+F5' to update this website.",
    PASSWORD_NOT_CORRECT = "The account and password cannot be empty, please type in valid information.",
    USER_NOT_FOUND = "User Not Found",
    SERVER_ERROR = "Some error happens connecting to server, please try pressing 'Ctrl+F5' to update this website first to see if this problem could be fixed.",
}

export declare interface IAuthContext {
    loading: boolean,
    authState: IAuthState,
    passwordLogin?: IPasswordLogin,
    facebookLogin?: IFacebookLogin,
    microsoftLogin?: IMicrosoftLogin,
    linkedInLogin?: ILinkedInLogin,
    googleLogin?: IGoogleLogin,
    twitterLogin?: ITwitterLogin,
    logout: ILogout,
    refreshAuthState: () => void
}
export const defaulatAuthContext: IAuthContext = {
    loading: false,
    authState: defaultAuthState,
    passwordLogin: undefined,
    facebookLogin: undefined,
    microsoftLogin: undefined,
    linkedInLogin: undefined,
    googleLogin: undefined,
    twitterLogin: undefined,
    logout: () => { },
    refreshAuthState: () => { }
}
