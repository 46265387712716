import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core';

import { LessonService } from '../../../_services/radacad-academy-lms/LessonService';
import { LearnerService } from '../../../_services/LearnerService';

import PurchaseCourseCards from './PurchaseCourseCards'
import LessonVideoPlayer from './LessonVideoPlayer';
import FullScreenLoadingProgress from '../../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';
import MaterialDownloadDiv from './MaterialDownloadDiv';
import { useRadacadAuth } from '../../../_services/radacad-auth-react/Core/useRadacadAuth';


const useStyle = makeStyles({
    materialText: {
        '& *': {
            fontSize: '20px !important'
        }
    }
});

export default function LessonVideoFrame(props: {
    lessonHeader: { courseId: number, lessonId: string, moduleId: number | string | null, lessonTitle: string, isPreview: boolean },
    nextLessonNavigationHandler?: () => void
}) {

    const { lessonHeader, nextLessonNavigationHandler } = props;
    const { authState: { authenticated: userAuthenticated, accessToken } } = useRadacadAuth();
    const classes = useStyle();

    const [_lessonResource, setLessonResource] = useState({
        lessonURL: "",
        lessonMaterial: "",
        completed: false,
        autoStartPosition: 0,
        success: false,
        errors: []
    })
    const [_completed, setCompleted] = useState<boolean>(false);
    const [_isLoading, setIsLoading] = useState<boolean>(true);


    useEffect(() => {

        if (props.lessonHeader.isPreview) {
            LessonService.RequestPreviewLessonResource(
                accessToken,
                props.lessonHeader.lessonId,
                (data) => {
                    //console.log(data);
                    setCompleted(data.completed);
                    setLessonResource(data);
                    setIsLoading(false);
                });
        } else if (userAuthenticated && accessToken) {
            LessonService.RequestMemberLessonResource(
                props.lessonHeader.lessonId,
                (data) => {
                    //console.log(data);
                    setCompleted(data.completed);
                    setLessonResource(data);
                    setIsLoading(false);
                },
                accessToken)
        }
    }, []);



    //make sure the button clickable only once
    let clickable = useRef(true);
    const completeLessonHandler = useCallback(
        () => {
            if (!clickable.current || _completed) {
                return;
            }
            //console.log("complete handler")
            clickable.current = false;
            setCompleted(true);

            accessToken &&
                LearnerService.AddCompletedLesson(
                    accessToken,
                    lessonHeader.lessonId,
                    (data) => {
                        // console.log(data);
                        if (data.success) {
                            setCompleted(true);
                        } else {
                            setCompleted(false);
                        }

                    }

                )
        },
        [_completed, accessToken, lessonHeader.lessonId]);

    const renderVideoPlayer = useMemo(
        () => <LessonVideoPlayer
            lessonId={lessonHeader?.lessonId}
            autoStartPosition={_lessonResource.autoStartPosition ?? 0}
            src={_lessonResource.lessonURL}
            title={lessonHeader?.lessonTitle}
            accessToken={accessToken}
            completeLessonHandler={_completed ? null : completeLessonHandler}
            nextLessonNavigationHandler={nextLessonNavigationHandler ?? null}
        />,
        [_completed, _lessonResource.autoStartPosition, _lessonResource.lessonURL, completeLessonHandler, accessToken, lessonHeader, nextLessonNavigationHandler])




    return (!props.lessonHeader.isPreview && !userAuthenticated) ?
        <div>
            <div>
                <p>
                    This is not a preview lesson, Please login or choose one of options below to get access of this course.
                </p>
            </div>
            <PurchaseCourseCards courseId={lessonHeader.courseId} />
        </div>
        :
        (_lessonResource.success && !_isLoading) ?
            (
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        {renderVideoPlayer}
                    </Grid>
                    <Grid item>
                        <div className={classes.materialText} dangerouslySetInnerHTML={{ __html: _lessonResource.lessonMaterial }}></div>

                    </Grid>
                    <Grid item xs={12} >
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            {userAuthenticated && _completed ?
                                <Button variant="contained" className="myThemeContainedButton" disabled style={{ filter: 'grayscale(100%)' }}>
                                    Completed
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    className="myThemeContainedButton"
                                    disabled
                                >
                                    In Progress
                                </Button>
                            }
                            <MaterialDownloadDiv
                                courseId={lessonHeader.courseId}
                                moduleId={lessonHeader.moduleId}
                                lessonId={lessonHeader.lessonId}
                            />


                        </Grid>
                    </Grid >


                </Grid>
            )
            : !_isLoading ?
                <div>{_lessonResource.errors.map(
                    (e, index) => <div>
                        <div>
                            <p>
                                {e === 'Resource Unauthorized' ? "You don't have access to watch this lesson. To get access, choose one of options below."
                                    : e === 'Free Trial User Maximum Watch Reached' ?
                                        "You have reached the limitation of 10 hours video-content-watch for the free trial. You can renew your subscription early and access the content without any limitations using the button below."
                                        : e}
                            </p>
                        </div>
                        <PurchaseCourseCards courseId={lessonHeader.courseId} />
                    </div>

                )}</div> :
                <FullScreenLoadingProgress></FullScreenLoadingProgress>
}

