import React, { useState, useEffect, useCallback } from "react";
import { Redirect, Link, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';



import CourseCard from '../CourseCardComponent/CourseCard';
import { CourseService } from '../../../_services/radacad-academy-lms/CourseService';
import { CoursePrerequisiteService } from '../../../_services/radacad-academy-lms/CoursePrerequisiteService';

import FullScreenLoadingProgress from "../../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress";
import CourseScrollContainer from "../CourseScrollContainerComponent/CourseScrollContainer.jsx";
import PurchaseCoursePromptDiv from "../../LessonPage/LessonDetailComponent/PurchaseCoursePromptDiv";


export default function CourseDetail() {
    const params = useParams();
    const [_isLoading, setIsLoading] = useState(true);
    const [CourseId, setCourseId] = useState(params.CourseId);
    const [CourseTitle, setCourseTitle] = useState();
    const [CoursePhotoLink, setCoursePhotoLink] = useState();
    const [CourseDescription, setCourseDescription] = useState();
    const [LessonCount, setLessonCount] = useState();
    const [CourseLength, setCourseLength] = useState();
    const [AuthorName, setAuthorName] = useState();
    const [AuthorId, setAuthorId] = useState();
    const [PreCourses, setPreCourses] = useState([]);
    const [NextCourses, setNextCourses] = useState([]);


    useEffect(() => {
        setIsLoading(true);
        if (CourseId) {

            CourseService.getCourseByIdForDetail(CourseId,
                (resp) => {
                    if (resp.status === 200) {

                        return true;
                    } else {
                        //console.log(resp);
                    }
                },
                (data) => {
                    //console.log(data);
                    setCourseTitle(data.courseTitle);
                    setCoursePhotoLink(data.coursePhotoLink);
                    setCourseDescription(data.courseDescription);
                    setLessonCount(data.lessonCount);
                    setCourseLength(data.courseLength);
                    setAuthorName(data.authorName);
                    setAuthorId(data.authorId);
                    setIsLoading(false);
                    CoursePrerequisiteService.getPreCoursesById(CourseId,
                        (data) => {
                            //console.log("pre");
                            //console.log(data);
                            setPreCourses(data);
                        }
                    );
                    CoursePrerequisiteService.getNextCoursesById(CourseId,
                        (data) => {
                            //console.log("next");
                            //console.log(data);
                            setNextCourses(data);

                        }
                    );

                },
                (err) => {

                });
        }

    }, [CourseId]);

    useEffect(() => {
        setCourseId(params.CourseId);
    }, [params.CourseId])

    const displayHourAndMin = (CourseLengthHour, CourseLengthMin) => {
        return CourseLengthHour > 0 ?
            (<span >
                <strong>{CourseLengthHour}</strong> hour{CourseLengthHour > 1 ? (<span>s</span>) : (<span></span>)}
                &nbsp;
                <strong>{CourseLengthMin}</strong> minute{CourseLengthMin > 1 ? (<span>s</span>) : (<span></span>)}

            </span>
            ) :
            (<span>
                <strong>{CourseLengthMin}</strong> minute{CourseLengthMin > 1 ? (<span>s</span>) : (<span></span>)}
            </span>)

    }

    const classes = useStyles();
    const renderPromptDesc = useCallback(() => {
        return <div>
            To get access to this course, please choose one of options below.
        </div>
    }, [])

    return CourseId === null ?
        (<Redirect to="/" push />)
        : _isLoading ?
            <FullScreenLoadingProgress></FullScreenLoadingProgress>
            : (

                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12}>
                        <h1>{CourseTitle}</h1>
                    </Grid>

                    <Grid item xs={12} p="8px">

                        <Grid container spacing={2} >

                            <Grid item xs={12} md={true} >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className={classes.lessonInfo}>
                                            <p> {LessonCount} Lesson(s) <br />
                                                Length of the course: {displayHourAndMin(Math.floor(CourseLength / 60), CourseLength % 60)} in Total
                                            </p>
                                        </div>
                                        <Link
                                            to={{
                                                pathname: `/Course/${CourseId}/Modules`
                                            }}>
                                            <Button variant="outlined" color="primary" className={"myThemeButton"} >
                                                <h2>View Lessons</h2>
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} >

                                        <p className={classes.authorInfo}>
                                            Author: &nbsp;
                                            <Link
                                                to={{ pathname: `/Author/${AuthorId}` }}
                                                className={""}>
                                                {AuthorName}
                                            </Link>
                                        </p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        Description: <div dangerouslySetInnerHTML={{ __html: CourseDescription }}></div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.imageContainer} >
                                <img src={CoursePhotoLink} alt={CourseTitle} className={classes.courseImage}></img>
                            </Grid>

                        </Grid>

                    </Grid>
                    {
                        CourseId &&
                        <PurchaseCoursePromptDiv
                            renderDescription={renderPromptDesc}
                            courseId={CourseId} />
                    }


                    {
                        PreCourses.length > 0 &&
                        <Grid item xs={12}>
                            <h3>Courses to watch before starting this course</h3>

                            <CourseScrollContainer >
                                <Grid container spacing={4} wrap="nowrap" >
                                    {PreCourses.map(
                                        (course, index) =>
                                        (
                                            <Grid item key={index}>
                                                <CourseCard courseId={course.preCourseId} />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </CourseScrollContainer>
                        </Grid>
                    }
                    {
                        NextCourses.length > 0 &&
                        <Grid item xs={12} >
                            <h3>Courses to watch after completing this course</h3>


                            <CourseScrollContainer >
                                <Grid container spacing={4} wrap="nowrap" >
                                    {
                                        NextCourses.map(
                                            (course, index) =>
                                            (
                                                <Grid item key={index}>
                                                    <CourseCard courseId={course.nextCourseId} />
                                                </Grid>
                                            )
                                        )}
                                </Grid>
                            </CourseScrollContainer>

                        </Grid>

                    }




                </Grid >
            );

}



const useStyles = makeStyles(() => ({
    root: {
    },
    lessonInfo: {
        fontSize: '1.1rem',

    },
    authorInfo: {
        fontSize: '1.1rem',

    },
    authorButton: {
        textDecoration: 'underline'
    },
    courseImage: {
        // width: '100%'
        maxWidth: '100%'
    },
    imageContainer: {

    },


}));


