import { makeStyles } from '@material-ui/core';
export const useMembershipPlanCourseCMSStyle=makeStyles({
    root:{
        height:"100%"
    },

    header:{

    },
    body:{

    },
    footer:{

    }


})