import { useCallback, useEffect, useState } from 'react'
import { LMSCourseApi } from '../../_services/CMSServices/LMSCourseApi';
import { ILMSCourseModel } from '../../_services/CMSServices/Models';

type LMSCourseStateType = ILMSCourseModel[] | undefined | null;
export default function useLMSCourse(token: string): { course: LMSCourseStateType, handleRefresh: () => void } {
    const [course, setCourse] = useState<LMSCourseStateType>();
    const handleRefresh = useCallback(
        () => {
            LMSCourseApi.GetAllLMSCourse(token).then((data) => {
                setCourse(data);

            }).catch(() => {
                setCourse(null);
            })
        },
        [token],
    )
    useEffect(() => {
        handleRefresh();
    }, [handleRefresh])

    return { course, handleRefresh };
}
