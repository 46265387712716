import React, { useCallback } from 'react'
import useFetchingData from '../../../_commons/Hooks/useFetchingData';
import { useDynamicContentDialog } from '../../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
import { ILMSCourseModel, ILMSModuleModel } from '../../../_services/CMSServices/Models';
import { LMSModuleApi } from '../../../_services/CMSServices/LMSModuleApi';

import { LMSModuleForm } from './LMSModuleForm';
import { useLMSModuleStyle } from './LMSModule.style';
import { LMSModuleTable } from './LMSModuleTable';
import ModuleLessonOrderEditor from './ModuleLessonOrderEditor';
import { CourseModuleOrderEditor } from './CourseModuleOrderEditor';



export default function LMSModulePanel(props: { course: ILMSCourseModel, token: string }) {

    const { course: { courseId, courseTitle }, token } = props;

    const classes = useLMSModuleStyle();


    const { data: courseModules, handleRefresh: handleModuleDataRefresh } = useFetchingData<ILMSModuleModel[]>(

        useCallback(
            (setCourseModule) => LMSModuleApi.GetCourseModules(courseId, token).then((data) => {
                if (data)
                    setCourseModule(data);
                else
                    setCourseModule(null);
            }).catch(() => {
                setCourseModule(null);
            }),
            [courseId, token],
        )
    )





    const useDialog = useDynamicContentDialog({ maxWidth: 'lg', fullWidth: true, disableBackdropClick: true });


    const handleAddOrEdit = (option: { action: "ADD" } | { action: "EDIT", object: ILMSModuleModel }) => {
        useDialog.open(
            <LMSModuleForm
                module={option.action === "ADD" ? undefined : option.object}
                courseId={courseId}

                submitHandler={(module: ILMSModuleModel) => { handleFormSubmit(option.action, module) }}
                cancelHandler={handleFormCancel}

            />
        )
    }
    const handleSubmitButton = () => {
        handleModuleDataRefresh();
        useDialog.close();
    }
    const handleCancleButton = () => {
        useDialog.close();
    }
    const handleFormSubmit = (action: "ADD" | "EDIT", module: ILMSModuleModel) => {

        if (window.confirm("confirm to submit?"))
            LMSModuleApi.Update(action, module, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    handleSubmitButton();

                }

                else
                    alert("failed");

            })

    }
    const handleFormCancel = () => {
        handleCancleButton();
    }
    const handleDelete = (moduleId: number) => {
        if (window.confirm("confirm to submit?"))
            LMSModuleApi.Delete({ moduleId }, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    handleModuleDataRefresh();

                }

                else
                    alert("failed");

            })
    }
    const handleLessonOrderButton = (moduleId: number) => {
        useDialog.open(
            <ModuleLessonOrderEditor
                moduleId={moduleId}
                accessToken={token}
                submitHandler={handleSubmitButton}
                cancelHandler={handleCancleButton}
            />
        )
    }

    const handleOrderModuleButton = () => {
        useDialog.open(
            <CourseModuleOrderEditor
                courseId={courseId}
                accessToken={token}
                submitHandler={handleSubmitButton}
                cancelHandler={handleCancleButton}
            />
        )
    }

    return (
        <div className={`${classes.rightContainer}`}>
            <h2>Course: "{courseTitle}"</h2>
            <div className={classes.header}>
                <button onClick={() => handleAddOrEdit({ action: "ADD" })}>Add New Module to this course</button>
                <button onClick={() => handleOrderModuleButton()}>Order Modules for this course</button>
            </div>

            <div className={classes.body}>
                <LMSModuleTable
                    module={courseModules}
                    editButtonHandler={(module) => handleAddOrEdit({ action: "EDIT", object: module })}
                    deleteButtonHandler={handleDelete}
                    orderButtonHandler={handleLessonOrderButton}
                />
            </div>
            <div className={classes.footer}>

            </div>

            {useDialog.render()}
        </div>
    )
}