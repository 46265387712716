import React, { useCallback, useContext, useState } from 'react'
import useFetchingData from '../../../_commons/Hooks/useFetchingData';
import { useDynamicContentDialog } from '../../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
import { LMSCourseApi } from '../../../_services/CMSServices/LMSCourseApi';
import { ILMSCourseModel } from '../../../_services/CMSServices/Models';

import { useLMSModuleStyle } from './LMSModule.style';
import LMSModulePanel from './LMSModulePenal';
import useHTMLInput from '../../../_commons/Hooks/useHTMLInput';
import { AcademyContext } from '../../../_contexts/UserContext';



export default function LMSModule() {
    const {loginState:{accessToken}} = useContext(AcademyContext);
    const token = accessToken??""

    const classes = useLMSModuleStyle();
    const htmlInput = useHTMLInput();

    const { data: courses } = useFetchingData<ILMSCourseModel[]>(
        useCallback(
            (setData) => LMSCourseApi.GetAllLMSCourse(token).then((data) => {
                if (data)
                    setData(data);
                else
                    setData(null);
            }).catch(() => {
                setData(null);
            }),
            [token],
        )
       
    )

    const [_courseSelected, setCourseSelected] = useState<ILMSCourseModel | undefined>(undefined);


    const useDialog = useDynamicContentDialog({ maxWidth: 'lg', fullWidth: true });



    const renderLeftContainer = () => {
        return <>
            <label>Search Course {htmlInput.render()} </label>
            {
                courses === undefined ? <div>is Loading</div> :
                    courses === null ? <div> fetching failed</div> :
                        <ul>

                            {
                                courses.filter((value:ILMSCourseModel)=>value.courseTitle.toLowerCase().includes(htmlInput.value.toLowerCase())).map((value: ILMSCourseModel, index: number) => {

                                    return <li
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { setCourseSelected(value) }}>{value.courseTitle}
                                    </li>
                                })
                            }

                        </ul>

            }
        </>
    }
    const renderRightContainer = () => {
        return _courseSelected === undefined ? <div> select a course</div> :
            <LMSModulePanel
                course={_courseSelected}
                token={token}
            />

    }
    return (
        <div className={`${classes.root}`}>

            <div className={classes.leftContainer}>
                {renderLeftContainer()}
            </div>
            <div className={classes.rightContainer}>
                {renderRightContainer()}
            </div>

            {useDialog.render()}
        </div>
    )
}
