import React from 'react'
import { makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
    FacebookShareButton, FacebookIcon,
    LinkedinShareButton, LinkedinIcon,
    TwitterShareButton, TwitterIcon
} from "react-share";

import { IBadge } from '../../../_viewmodels/BadgeModel';
import { useDynamicContentDialog } from '../../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
interface IBadgeProps {

    badgeMeta: IBadge,
    userCompletionState: {
        completed: boolean,

    },

    className?: string,
    containerStyle?: React.CSSProperties,
    imgStyle?: React.CSSProperties,
    labelStyle?: React.CSSProperties,
}


const useStyles = makeStyles({
    root: {
        width: "200px",
    },
    badgeImg: {
        cursor: 'pointer',
        borderRadius: '10px',
        //  backgroundColor: "#C4EDD0",
        opacity: "0.8",
        "&:hover": {
            filter: "brigtness(1.5)",
            opacity: "1"
        }
    },
    inactiveImg: {
        filter: "brightness(0.6) grayscale(1)  ",

        opacity: "0.1"
    },
    greenColorCheck: {
        "& *": {
            color: "#4FAE57 !important"
        }
    },
    badgeDescDiv: {
        padding: "20px",
        "& h3": {
            textAlign: "center"
        },
        "& img": {
            maxWidth: "90%",
            display: 'block',
            margin: "5px auto"
        }
    },
    shareButton: {
        marginLeft: "2px",
        marginRight: "2px"
    }

}
)
export function Badge(props: IBadgeProps) {

    const { badgeMeta, userCompletionState, containerStyle, imgStyle, labelStyle, className } = props;
    const classes = useStyles();
    const useBadgeDescDialog = useDynamicContentDialog({ maxWidth: 'xs', fullWidth: true });

    const renderBadgeDescription = () => {
        return <div className={classes.badgeDescDiv}>
            <h3>{badgeMeta.badgeCategory.label}({badgeMeta.badgeLevel.levelLabel}){userCompletionState.completed && <CheckCircleIcon className={classes.greenColorCheck} />}</h3>
            {userCompletionState.completed && <img src={badgeMeta.badgeUrl} alt={`${badgeMeta.badgeCategory.label}(${badgeMeta.badgeLevel.levelLabel}) Large`} />}

            <p>


                {userCompletionState.completed ? "You have achieved:" : "To obtain this badge, you need to achieve: "}
            </p>
            <ul>
                <li>"{badgeMeta.badgeCategory.action?.replace("?", badgeMeta.quantity + "").replace("(s)", badgeMeta.quantity > 1 ? "s" : "")}"</li>
            </ul>
        </div>
    }
    return (
        <div style={containerStyle} className={`${classes.root} ${className}`}>

            <img

                src={badgeMeta.badgeUrl}
                alt={badgeMeta.badgeCategory.label}
                className={`${classes.badgeImg} ${userCompletionState.completed ? "" : classes.inactiveImg}`}
                style={imgStyle}
                onClick={() => { useBadgeDescDialog.open(renderBadgeDescription()) }}
                title="Click to check the detail"
            />



            <div
                style={labelStyle}
            >
                {userCompletionState.completed && <CheckCircleIcon className={classes.greenColorCheck} />}{badgeMeta.badgeCategory.label}({badgeMeta.badgeLevel.levelLabel})

            </div>
            <div>
                {userCompletionState.completed &&
                    <div>
                        <FacebookShareButton url={"Https://Radacad.Academy"}> <FacebookIcon size={30} round className={classes.shareButton} /></FacebookShareButton>
                        <LinkedinShareButton url={"Https://Radacad.Academy"} > <LinkedinIcon size={30} round className={classes.shareButton} /></LinkedinShareButton>
                        <TwitterShareButton url={"Https://Radacad.Academy"} > <TwitterIcon size={30} round className={classes.shareButton} /></TwitterShareButton>
                    </div>}
            </div>

            {useBadgeDescDialog.render()}
        </div>


    )
}
