import { Paper } from '@material-ui/core';
import React, { useRef, useState } from 'react'

export default function DragScrollPaper(props: any) {

    //Dragable Paper
    const _paperRef = useRef(null);
    const _dragDivRef = useRef(null);
    const [_dragState, setDragState] = useState({
        isDragging: false,
        orginalClientX: 0,
        orginalClientY: 0,
        orginalScrollLeft: 0,
        orginalScrollTop: 0
    })



    const dragStartHandler = (e: any, eleRef: React.MutableRefObject<any>) => {

        setDragState({
            isDragging: true,
            orginalClientX: e.clientX,
            orginalClientY: e.clientY,
            orginalScrollLeft: eleRef.current.scrollLeft,
            orginalScrollTop: eleRef.current.scrollTop
        })

    }

    const dragHandler = (e: any, eleRef: React.MutableRefObject<any>) => {


        if (_dragState.isDragging) {

            const dx = e.clientX - _dragState.orginalClientX;
            const dy = e.clientY - _dragState.orginalClientY;

            eleRef.current.scrollLeft = _dragState.orginalScrollLeft - dx;
            eleRef.current.scrollTop = _dragState.orginalScrollTop - dy;
        }

    }
    const dragEndHandler = (e: any, eleRef: React.MutableRefObject<any>) => {

        if (_dragDivRef)
            //@ts-ignore
            _dragDivRef.current.style.cursor = 'grab';

        setDragState({
            isDragging: false,
            orginalClientX: 0,
            orginalClientY: 0,
            orginalScrollLeft: 0,
            orginalScrollTop: 0
        });


    }

    const mouseDownHandler = (e: any, eleRef: React.MutableRefObject<any>) => {
        eleRef.current.style.cursor = 'grabbing';
    }

    //ref to Why is clientX reset to 0 on last drag-event?
    //https://stackoverflow.com/questions/36308460/why-is-clientx-reset-to-0-on-last-drag-event
    const dragOverHandler = (e: any) => {
        e.preventDefault();

    }
    //console.log(props.style);
    return (
        <Paper className={props.className}
            style={{
                overflow: 'auto',
                ...props.style
            }}

            onDrag={(e) => dragHandler(e, _paperRef)}
            onDragStart={(e) => dragStartHandler(e, _paperRef)}
            onDragEnd={(e) => dragEndHandler(e, _paperRef)}
            onDragOver={dragOverHandler}


            ref={_paperRef}
        >
            <div
                style={{
                    display: 'inline-block',
                    cursor: 'grab'
                }}
                ref={_dragDivRef}
                onMouseDown={(e) => mouseDownHandler(e, _dragDivRef)}>

                {props.children}
            </div>
        </Paper>
    )
}
