import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Container } from '@material-ui/core';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
        <div
            className={classes.tabPanel}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                < >
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        '& *': {
            // color: 'black !important',
            // border: '1px solid red !important',

        },
        '& button': {
            // backgroundColor: 'gray',
        },
        '& .Mui-selected': {
            backgroundColor: '#DEE1E6 !important'
        }
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tabPanel: {
        height: "100%"
    }
}));

export default function VerticalTabs(props: { children: { label: string, element: JSX.Element }[] }) {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                {
                    props.children.map(
                        (child: { label: string, element: JSX.Element }, index: number): JSX.Element => {

                            return <Tab label={child.label} {...a11yProps(index)} key={index} />

                        }
                    )
                }

            </Tabs>
            <Container maxWidth={false}>
                {
                    props.children.map(
                        (child: { label: string, element: JSX.Element }, index: number): JSX.Element => {

                            return (
                                <TabPanel value={value} index={index} key={index}>
                                    {child.element}
                                </TabPanel>
                            )

                        }
                    )
                }

            </Container>
        </div>
    );
}
