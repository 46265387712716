import React from 'react';
import { FacebookService } from '../../apis/FacebookService';
import { IFacebookAuthCredential } from '../../Core/IUserCredential';

import { ReactComponent as FacebookIcon } from '../../assets/facebook_icon.svg';
import { ISSOButtonProps } from '../../Core/ISSOButtonProps';

export function FacebookSSOButton(props: ISSOButtonProps<IFacebookAuthCredential> & {
    style?: React.CSSProperties
}) {
    const { onSuccess, onFailure, style } = props;

    const FacebookLoginHandler = async () => {

        FacebookService.FacebookLogin(
            (response: any) => {

                if (FacebookService.LoginSuccess(response)) {
                    const accessTokenModel = {
                        accessToken: FacebookService.GetAccessToken(response)
                    }
                    onSuccess(accessTokenModel);
                }
                else {
                    onFailure && onFailure({ errorMessage: "Facebook login failed, please check your facebook account and password. Try again." });
                }
            }
        );

    }

    return <FacebookIcon
        style={{ cursor: 'pointer', height: "30px", width: "30px", ...style }}
        onClick={FacebookLoginHandler}
    />

}

