import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom';
import { AcademyContext } from '../../../_contexts/UserContext';
import { UserService } from '../../../_services/UserService';
import { LearnerService } from '../../../_services/LearnerService';

import FullScreenLoadingProgress from '../../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';

import Moment from 'moment';
import { Button, Link as MaterialUILink, Divider, Grid } from '@material-ui/core';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import ShopIcon from '@material-ui/icons/Shop';
import UserMetrics from '../UserMetrics/UserMetrics';
import UserBadges from '../UserBadges/UserBadges';
import { UserSSOStatus } from '../UserSSOStatus';
import UserEmailNotificationStatusSwitch from '../UserEmailNotificationStatus';





export default class UserProfile extends Component {

    static contextType = AcademyContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,

            userProfile: {
                userDisplayName: "",
                userEmail: "",
                registerDate: ""
            },
            membershipState: {
                isMembership: false,
                freeTrialWatchLimitationReached: false,
                membershipStatus: undefined,
                membershipName: "",
                membershipType: "",
                isActive: false,
                lastPurchaseDate: "",
                expiredDate: ""
            },

            courseMembershipPlan: []

        }
        this.syncProgressHandler = this.syncProgressHandler.bind(this);


    }
    syncProgressHandler() {
        let confirmRes = window.confirm('Do you confirm to overwrite learning progress from old platform to new platform?');

        if (confirmRes)
            LearnerService.RequestOverwriteLearningProgress(
                this.context.loginState.accessToken,
                (data) => {
                    //console.log(data);
                    if (data.success) {
                        alert("Overwrite Successfully")
                    }
                    else {
                        alert("Overwrite Failed")
                    }
                }
            );
    }

    componentDidMount() {
        if (this.context.loginState.isLoggedIn) {

            UserService.GetUserProfile(
                (data) => {

                    this.setState({
                        userProfile: data.userProfile,
                        membershipState: data.membershipState,
                        courseMembershipPlan: data.courseMembershipPlan,
                    }, () => {
                        this.setState({ loading: false })
                    });
                },
                this.context.loginState.accessToken
            )

        }
    }

    render() {

        if (this.context.loginState.isLoggedIn === false)
            return (<Redirect to="/SignIn" push />)
        else if (this.state.loading)
            return <FullScreenLoadingProgress></FullScreenLoadingProgress>
        else return (
            <div>
                <p>User Name: {this.state.userProfile.userDisplayName}</p>
                <p>Email: {this.state.userProfile.userEmail}</p>
                <p>Register Date: {Moment(this.state.userProfile.registerDate).format("MMMM DD YYYY")}</p>
                <Grid container spacing={1}>
                    <Grid item ><span>LINK your other accounts for more login: </span></Grid>
                    <Grid item > <UserSSOStatus /></Grid>
                </Grid>
                <p>Academy Membership: {this.state.membershipState.isMembership ?
                    <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon> : <CheckBoxOutlineBlankOutlinedIcon></CheckBoxOutlineBlankOutlinedIcon>}</p>
                {
                    this.state.membershipState.isMembership
                    &&
                    <>
                        <ul>
                            <li>Status: {this.state.membershipState.isActive ? 'Active' : 'Inactive'} {this.state.membershipState.membershipStatus && <span>({this.state.membershipState.membershipStatus})</span>}</li>
                            <li>Membership Plan: {this.state.membershipState.membershipName} ({this.state.membershipState.membershipType})</li>
                            <li>Last Purchase Date: {Moment(this.state.membershipState.lastPurchaseDate).format("MMMM DD YYYY")}</li>
                            <li>Expiry Date: {Moment(this.state.membershipState.expiredDate).format("MMMM DD YYYY")}</li>
                        </ul>
                        {
                            this.state.membershipState.freeTrialWatchLimitationReached &&
                            <p className={"warning"}>
                                * You have reached the limitation of 10 hours video-content-watch for the free trial. You can renew your subscription early and access the content without any limitations using the button below.
                            </p>
                        }
                    </>

                }
                {
                    this.state.courseMembershipPlan != null
                    && this.state.courseMembershipPlan.length > 0
                    &&
                    <div>Purchased Course Membership Plans:
                        <ul>
                            {
                                this.state.courseMembershipPlan.map((plan, index) => (
                                    <li key={index}>
                                        <Link to={`/Course/${plan.courseId}`}>
                                            {plan.membershipPlanName}
                                        </Link>
                                    </li>

                                ))}
                        </ul>
                    </div>
                }

                <MaterialUILink href="https://learn.radacad.com/my-account/">
                    <Button variant='outlined' color='primary'
                        className={'myThemeButton'}
                        startIcon={<ShopIcon></ShopIcon>}
                        style={{ textTransform: 'none' }}
                    >
                        View, Modify or Cancel the Subscription
                    </Button>
                </MaterialUILink>
                <div>Daily Email Notification: <UserEmailNotificationStatusSwitch /></div>

                {/* hide temporarilly */}
                {/* <Button variant='outlined' color='secondary'
                    
                    className={'myDangerButton'}
                    onClick={this.syncProgressHandler}
                    startIcon={<GetAppOutlinedIcon></GetAppOutlinedIcon>}
                    >
                    Overwrite Progress from old platform
                 </Button> */}

                <Divider style={{ marginTop: "20px", marginBottom: "10px" }}></Divider>
                <h2>STATS(Beta):</h2>
                <UserMetrics accessToken={this.context.loginState.accessToken}>

                </UserMetrics>

                <Divider style={{ marginTop: "20px", marginBottom: "10px" }}></Divider>
                <h2>Badges:</h2>
                <UserBadges accessToken={this.context.loginState.accessToken}>

                </UserBadges>
            </div >
        )
    }
}

