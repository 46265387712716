import { Grid } from '@material-ui/core';
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthorService } from '../../../_services/radacad-academy-lms/AuthorService';
import CourseCardList from '../../CoursePage/CourseCardListComponent/CourseCardList';
import FullScreenLoadingProgress from '../../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';

export default class AuthorPage extends Component {
    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            authorId: this.props.match.params.AuthorId,
            authorName: "",
            authorPhotoLink: "noLink",
            authorBio: "No Bio",
            courseList: [],

            isLoading: true,
            redirect: false
        }
    }
    componentDidMount() {
        let id = this.state.authorId;

        if (id == null)
            this.setState({ redirect: true });
        else {


            AuthorService.RequestGetAuthor(id, (data) => {
                //console.log(data);
                this.setState(
                    {
                        authorName: data.author.authorName,
                        authorPhotoLink: data.author.photoLink,
                        authorBio: data.author.bio,
                        courseList: data.courses,
                        isLoading: false
                    });
            })
        }

    }



    render() {
        if (this.state.redirect)
            return <Redirect to="/"></Redirect>
        if (this.state.isLoading)
            return <FullScreenLoadingProgress></FullScreenLoadingProgress>

        else return (
            <Grid container>
                <Grid item xs={12}>

                    <Grid container spacing={2}>



                        <Grid item xs={12} md={true}>

                            <h1>{this.state.authorName}</h1>
                            <p dangerouslySetInnerHTML={{ __html: this.state.authorBio }}></p>

                        </Grid>
                        <Grid item xs={12} md={5} style={{ maxWidth: '500px' }} >
                            <img src={this.state.authorPhotoLink} style={{ width: "100%", marginRight: '0' }} alt="Author" ></img>
                        </Grid>



                    </Grid>

                </Grid>
                <Grid item xs={12} spacing={1}>

                    <h2>Explore this author's courses</h2>

                    <CourseCardList courses={this.state.courseList}></CourseCardList>

                </Grid>


            </Grid>
        )
    }
}

