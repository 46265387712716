import { makeStyles } from '@material-ui/core';
export const useMaterialDownloadDivStyles = makeStyles({
    root: {
        display: "flex",
        flexWrap: 'wrap',
        alignContent: "center"
    },
    downloadText: {
        fontSize: "x-large"
    },
    downloadBtnWrapper: {
        marginLeft: "5px"
    },
 
    courseMaterialBtn: {
        backgroundColor: "#F6763C",
        "&:hover": {
            backgroundColor: "#ff9e73"
        }
    },
    moduleMaterialBtn: {
        backgroundColor: "#1C7A84",
        "&:hover": {
            backgroundColor: "#4cc0cc"
        }
    },
    lessonMaterialBtn: {
        backgroundColor: "#064934",
        "&:hover": {
            backgroundColor: "#17956e"
        }
    }
})