import React from 'react'

export default function HTMLTextDiv(props: { messageText: string, style?:React.CSSProperties }) {
    const { messageText ,style} = props;

    return (
        <div
            style={{ overflowWrap: "break-word", ...style }}
            dangerouslySetInnerHTML={{ __html: messageText }}
        />
    )
}
