import { IFacebookAuthCredential, IGoogleAuthCredential, ILinkedInAuthCredential, IMicrosoftAuthCredential, ITwitterAuthCredential } from '../Core/IUserCredential';
import { HttpHelper } from "../Helpers/HttpHelper";


interface IAuthenticationRequest {
    userLogin: string,
    password: string,
}
export interface IAuthenticationResponse {
    accessToken: string
}
//public
const RadacadLoginRequest = (userModel: IAuthenticationRequest, dataHandler: (data: IAuthenticationResponse | { errors: string[] }) => void, errorHandler: (e: any) => void) => {

    let ApiURL = `api/Auth/authenticate`;

    HttpHelper.PostJSON(ApiURL, userModel)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            errorHandler(e);
        });

}
//public
const FacebookLoginRequest = async (userCredential: IFacebookAuthCredential, dataHandler: (data: IAuthenticationResponse | { errors: string[] }) => void, errorHandler: (e: any) => void) => {
    let ApiURL = `api/Auth/authenticate/facebook`;

    HttpHelper.PostJSON(ApiURL, userCredential)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            errorHandler(e)
        });

}

//public
const MicrosoftLoginRequest = async (userCredential: IMicrosoftAuthCredential, dataHandler: (data: IAuthenticationResponse | { errors: string[] }) => void, errorHandler: (e: any) => void) => {
    let ApiURL = `api/Auth/authenticate/microsoft`;

    HttpHelper.PostJSON(ApiURL, userCredential)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            errorHandler(e)
        });

}



//public
const LinkedInLoginRequest = async (userCredential: ILinkedInAuthCredential, dataHandler: (data: IAuthenticationResponse | { errors: string[] }) => void, errorHandler: (e: any) => void) => {
    let ApiURL = `api/Auth/authenticate/linkedIn`;

    HttpHelper.PostJSON(ApiURL, userCredential)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            errorHandler(e)
        });

}


//public
const GoogleLoginRequest = async (userCredential: IGoogleAuthCredential, dataHandler: (data: IAuthenticationResponse | { errors: string[] }) => void, errorHandler: (e: any) => void) => {
    let ApiURL = `api/Auth/authenticate/google`;

    HttpHelper.PostJSON(ApiURL, userCredential)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            errorHandler(e)
        });

}
//public
const TwitterLoginRequest = async (userCredential: ITwitterAuthCredential, dataHandler: (data: IAuthenticationResponse | { errors: string[] }) => void, errorHandler: (e: any) => void) => {
    let ApiURL = `api/Auth/authenticate/twitter`;

    HttpHelper.PostJSON(ApiURL, userCredential)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            errorHandler(e)
        });

}



//public
const SilentRefreshRequestAsync = async (failedHandler: (() => void) | null, successHandler: ((data: IAuthenticationResponse) => void) | null) => {
    let apiUrl = `api/Auth/refresh-token`;

    await HttpHelper.Get(apiUrl)
        .then(async (resp) => {
            if (resp.status === 401) {
                if (failedHandler) {
                    failedHandler();
                }

            } else {
                if (successHandler) {
                    let data: IAuthenticationResponse = await resp.json();

                    //console.log(data);
                    successHandler(data);
                }

            }
        }).catch(
            () => {
                if (failedHandler) {
                    failedHandler();
                }
            }
        )
}

//private 
const LogoutRequest = async (token: string) => {
    HttpHelper.PostJSON(`api/Auth/Logout`, null, token);

}



const CheckLoginSuccess = (loginResponse: IAuthenticationResponse) => {

    //console.log(loginResponse);
    if (loginResponse.accessToken != null)
        return true;

    return false;
}





export const AuthenticationService = {
    //public 
    RadacadLoginRequest,
    MicrosoftLoginRequest,
    FacebookLoginRequest,
    LinkedInLoginRequest,
    GoogleLoginRequest,
    TwitterLoginRequest,
    SilentRefreshRequestAsync,
    //private 
    LogoutRequest,
    //function
    CheckLoginSuccess
}