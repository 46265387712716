import { useCallback, useEffect, useState } from 'react'

type IStateType<T> = T | undefined | null;

export default function useFetchingData<T>(fetchHandler: (dataSetter: (data: IStateType<T>) => void) => void): { data: IStateType<T>, handleRefresh: () => void } {
    const [data, setData] = useState<IStateType<T>>();

    const handleFetching = fetchHandler;
    const handleRefresh = useCallback(
        () => {
            handleFetching(setData);
        },
        [handleFetching],
    )

    useEffect(() => {
        handleFetching(setData);

    }, [handleFetching])

    return { data, handleRefresh };
}
