import { makeStyles } from '@material-ui/core';
export const useLMSCourseFormStyle = makeStyles({
    root: {
        margin: "20px auto",
        padding: "20px ",
        backgroundColor: "white",
        "& $courseTitle,$courseLink,$coursePhotoLink ": {
            width: "100%",
            "& input": {
                width: "100%"
            }
        }
    },

    inputDiv: {
    },
    uploadContainer: {
        display: "flex",
        justifyContent: "space-between",
        "& img": {
            maxHeight: "200px",
            maxWidth: "200px"
        }
    },

    footerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px"
    }

})