import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const [layoutState, setLayoutState] = React.useState({
    DialogTitle: undefined,
    DialogContext: "",
    CancleButtonText: "",
    ConfirmButtonText: "",
    ConfirmHandler: undefined,
    CloseHandler: undefined
  })


  useEffect(() => {
    setLayoutState(props.layoutState);
  }, [props.layoutState]);



  return (
    <div>
      <Dialog
        open={props.open}
        onClose={layoutState.CloseHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {layoutState.DialogTitle &&
          <DialogTitle id="alert-dialog-title">{layoutState.DialogTitle}</DialogTitle>
        }
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {layoutState.DialogContext}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {layoutState.CloseHandler &&

            <Button onClick={layoutState.CloseHandler} >
              {layoutState.CancleButtonText}
            </Button>
          }
          {layoutState.ConfirmHandler &&
            <Button variant='outlined' onClick={layoutState.ConfirmHandler} color="secondary" >
              {layoutState.ConfirmButtonText}
            </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}
