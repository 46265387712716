import React, { useEffect, useState } from 'react'
import { ILMSLessonModel } from '../../../_services/CMSServices/Models';
import { LMSLessonApi } from '../../../_services/CMSServices/LMSLessonApi';
import { BaseOrderingEditor } from '../BaseOrderingEditor';
import { IOrderElement } from '../../_commons/DragDropList';



interface IProps {
  moduleId: number,
  accessToken: string,
  submitHandler: () => void,
  cancelHandler: () => void
}
export default function OrderEditor(props: IProps) {
  const { moduleId, accessToken, submitHandler, cancelHandler } = props;

  const [_pageState, setPageState] = useState<"Loading" | "Waiting" | "Showing" | "Error">("Loading");

  const [_list, setList] = useState<ILMSLessonModel[] | null>(null);
  useEffect(() => {
    setPageState("Loading")
    const FetchModule = async () => {

      LMSLessonApi.Get(undefined,moduleId, accessToken)
        .then(
          async (data) => {
            if (data) {
             // console.debug(data)
              data.sort((a: ILMSLessonModel, b: ILMSLessonModel) => a.orderInModule - b.orderInModule);
              setList(data);
              setPageState("Showing")

            } else {
              setList(null);
              setPageState("Error")

            }

          }
        ).catch(
          (err) => {
            setList(null);
            setPageState("Error")

          }
        )
    }
    FetchModule();

  }, [moduleId, accessToken]);


  const handleSubmit = (resultList: IOrderElement[]) => {
    setPageState("Waiting")

    LMSLessonApi.UpdateOrder(
      "MODULE_LESSON",
      {
        parentId: moduleId,
        lessonOrders: resultList.map((ele: IOrderElement) => {
          return {
            lessonId: ele.id,
            lessonOrder: ele.order
          }
        })
      },
      accessToken!
    ).then(
      (succ) => {
        if (succ) {
          alert('succ');
          submitHandler();

        }

        else alert('failed')
      }
    ).catch(
      () => {
        alert('failed')
      }).finally(() => {
        setPageState("Showing");
      })

  }
  const handleCancel = () => {
    cancelHandler();
  }

  return <BaseOrderingEditor
    pageState={_pageState}
    initialDataList={_list ? _list.map((ele: ILMSLessonModel) => { return { id: ele.lessonId, order: ele.orderInModule, label: ele.lessonTitle } }) : null}
    submitHandler={handleSubmit}
    cancelHandler={handleCancel}
  />
}

