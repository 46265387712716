import React, { useState } from 'react';
import {
    BarChart, Bar,
    XAxis, YAxis, Tooltip, Legend, LabelList
} from 'recharts';
import moment from 'moment';
import { Time } from '../../../_commons/Utils/Time';

import { Chip } from '@material-ui/core';

interface IProps {
    learnTimesByMonth: {
        monthDate: string,
        monthLearnTime: number,
    }[]
    last7DaysLearnTime: {
        date: string,
        learnTime: number
    }[],
    last8WeeksLearnTime: {
        startDate: string,
        endDate: string,
        learnTime: number
    }[],
}

export default function MetricsCharts(props: IProps) {

    //console.log(props);
    const [_selectedIndex, setSelectedIndex] = useState(0);
    return (
        <>
            <div >

                {props.learnTimesByMonth &&
                    <Chip
                        style={{ margin: '2px' }}
                        className={`MuiChip-Dark MuiChip-Rect ${_selectedIndex === 0 ? 'MuiChip-Dark-Selected' : ''}`}
                        label={'Last 12 Months'} clickable onClick={() => { setSelectedIndex(0) }} />
                }
                {props.last8WeeksLearnTime &&
                    <Chip
                        style={{ margin: '2px' }}
                        className={`MuiChip-Dark MuiChip-Rect ${_selectedIndex === 1 ? 'MuiChip-Dark-Selected' : ''}`}
                        label={'Last 8 Weeks'} clickable onClick={() => { setSelectedIndex(1) }} />
                }
                {
                    props.last7DaysLearnTime &&
                    <Chip
                        style={{ margin: '2px' }}
                        className={`MuiChip-Dark MuiChip-Rect ${_selectedIndex === 2 ? 'MuiChip-Dark-Selected' : ''}`}
                        label={'Last 7 Days'} clickable onClick={() => { setSelectedIndex(2) }} />
                }
            </div>

            <div style={{ overflowX: 'auto' }}>
                {_selectedIndex === 0 && props.learnTimesByMonth &&
                    <div className="area-chart-wrapper my-dark-wrapper">
                        <BarChart
                            width={1000}
                            height={300}
                            data={props.learnTimesByMonth.map(data => {
                                return {
                                    date: data.monthDate,
                                    learnTime: Time.SecsToMins(data.monthLearnTime)
                                }

                            })}
                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}

                        >
                            <YAxis
                                className={'chart-label-dark'}
                                type="number"

                            // tickFormatter={(value: any, index: any) => { return Time.SecsToMins(value) + "" }}
                            />
                            <XAxis
                                dataKey="date"
                                type="category"

                                tickFormatter={(value: any, index: any) => { return moment(value).format('MMM') }}
                            />
                            <Legend verticalAlign="top" height={36} />
                            <Bar dataKey="learnTime" fill="#387908" name="Learning Length in Minutes" >
                                {/*
 // @ts-ignore */}
                                <LabelList position="top" className={'chart-label-dark'} />
                            </Bar>
                            <Tooltip
                                labelFormatter={(label: any, payload: any) => { return <div>{moment(label).format('MMM, YYYY')}</div> }}
                                formatter={(value: any, name: any, props: any) => { return [Time.MinsToReadableStr(value), "Learn"] }}
                            />
                        </BarChart>
                    </div>
                }
                {_selectedIndex === 1 && props.last8WeeksLearnTime &&
                    <div className="area-chart-wrapper my-dark-wrapper">
                        <BarChart
                            width={1000}
                            height={300}
                            data={props.last8WeeksLearnTime.map(data => {
                                return {
                                    date: JSON.stringify({
                                        startDate: data.startDate,
                                        endDate: data.endDate
                                    }),
                                    learnTime: Time.SecsToMins(data.learnTime)
                                }

                            })}
                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}

                        >
                            <YAxis
                                className={'chart-label-dark'}
                                type="number"
                            // tickFormatter={(value: any, index: any) => { return Time.SecsToMins(value) + "" }}
                            />
                            <XAxis
                                className={'chart-label-dark'}
                                dataKey="date"
                                type="category"

                                tickFormatter={(value: any, index: any) => {
                                    let dateObj = JSON.parse(value)
                                    return moment(dateObj.startDate).format('DD MMM') + " - " + moment(dateObj.endDate).format('DD MMM')
                                }
                                }
                            />

                            <Legend verticalAlign="top" height={36} />
                            <Bar dataKey="learnTime" fill="#387908" name="Learning Length in Minutes"  >
                                {/*
 // @ts-ignore */}
                                <LabelList position="top" className={'chart-label-dark'} />
                            </Bar>
                            <Tooltip
                                labelFormatter={(label: any, payload: any) => {
                                    let dateObj = JSON.parse(label)
                                    return <div>
                                        {moment(dateObj.startDate).format('DD MMM, YYYY')}
                                -
                                {moment(dateObj.endDate).format('DD MMM, YYYY')}
                                    </div>
                                }
                                }
                                formatter={(value: any, name: any, props: any) => { return [Time.MinsToReadableStr(value), "Learn"] }}
                            />
                        </BarChart>
                    </div>
                }
                {_selectedIndex === 2 && props.last7DaysLearnTime &&
                    <div className="area-chart-wrapper my-dark-wrapper">
                        <BarChart
                            width={1000}
                            height={300}
                            data={props.last7DaysLearnTime.map(data => {
                                return {
                                    date: data.date,
                                    learnTime: Time.SecsToMins(data.learnTime)
                                }

                            })}
                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}

                        >
                            <YAxis
                                type="number"
                            // tickFormatter={(value: any, index: any) => { return Time.SecsToMins(value) + "" }}
                            />
                            <XAxis
                                dataKey="date"
                                type="category"

                                tickFormatter={(value: any, index: any) => { return moment(value).format('DD MMM') }}
                            />
                            <Legend verticalAlign="top" height={36} />
                            <Bar dataKey="learnTime" fill="#387908" name="Learning Length in Minutes">
                                {/*
                                // @ts-ignore */}
                                <LabelList position="top" className={'chart-label-dark'} />
                            </Bar>
                            <Tooltip
                                labelFormatter={(label: any, payload: any) => { return <div>{moment(label).format('DD MMM, YYYY')}</div> }}
                                formatter={(value: any, name: any, props: any) => { return [Time.MinsToReadableStr(value), "Learn"] }}
                            />
                        </BarChart>
                    </div>
                }

            </div>
        </>
    )
}
