import { HttpHelper } from "../../_commons/Helpers/HttpHelper";
import { LMSMaterialLinkHandler } from "../Common/Handlers";
import { LMSMaterialLinkType } from "../Common/Types";

const GetMaterialLink = (moduleId: number | string, token: string | null): Promise<LMSMaterialLinkType> => {
    let api = `api/Module/MaterialLink/${moduleId}`;
    return LMSMaterialLinkHandler(HttpHelper.Get(api, token));

}
export const ModuleService = {
    GetMaterialLink
}