import React from 'react';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import { ISSOButtonProps } from '../../Core/ISSOButtonProps';
import { IGoogleAuthCredential } from '../../Core/IUserCredential';
import { ReactComponent as GoogleIcon } from '../../assets/google_icon.svg';



export function GoogleSSOButton(props: ISSOButtonProps<IGoogleAuthCredential> & {
  withText?: boolean,
  label?: string,
  style?: React.CSSProperties,
  customButton?: (props: {
    onClick: () => void;
    disabled?: boolean | undefined;
  }) => JSX.Element
}) {
  const { onSuccess, onFailure, customButton, withText, label, style } = props;


  return <GoogleLogin
    clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
    buttonText={label ?? "Login with Google"}
    render={withText !== true && customButton === undefined ?
      renderProps =>
        <button
          style={{
            width: '30px',
            height: "30px",
            border: '0',
            borderRadius: '4px',
            padding: '4px',
            ...style
          }}
          onClick={renderProps.onClick} disabled={renderProps.disabled}>
          <GoogleIcon />
        </button>
      : customButton}
    onSuccess={(response: GoogleLoginResponse | any) => { onSuccess({ idToken: response.getAuthResponse().id_token }) }}
    onFailure={onFailure}
    cookiePolicy={'single_host_origin'}
  />
}

