import React, { useCallback, useEffect, useState } from 'react'
import { AuthenticationService, IAuthenticationResponse } from './apis/AuthenticationApi';
import { FacebookService } from './apis/FacebookService';
import { AuthContext } from './Core/AuthContext';
import { ILoginOptions, LoginErrorMessage } from './Core/IAuthContext';
import { IAuthState, defaultAuthState } from './Core/IAuthState';
import { IFacebookAuthCredential, IGoogleAuthCredential, ILinkedInAuthCredential, IMicrosoftAuthCredential, ITwitterAuthCredential } from './Core/IUserCredential';
import { JwtHelper } from './Helpers/JwtHelper';





export default function RadacadAuthProvider({ config, children }: {
  config: {
    allowPasswordLogin: boolean,
    allowFacebookLogin: boolean,
    allowMicrosoftLogin: boolean,
    allowTwitterLogin: boolean,
    allowGoogleLogin: boolean,
    allowLinkedInLogin: boolean,
  }
  , children: React.ReactNode
}) {
  const [_loading, setLoading] = useState<boolean>(true);
  const [_authState, setAuthState] = useState<IAuthState>(defaultAuthState);
  useEffect(() => {
    //init this page 
    //init refresh handler
    handleSilentAccessTokenRefresh().then(()=>setLoading(false));
  }, [])

  useEffect(() => {
    if (_authState.authenticated) {
      localStorage.setItem('login', Date.now().toString())
    }
  }, [_authState.authenticated])

  useEffect(() => {
    //Add event listeners Sync different tabs 
    const handleSyncBrowserTabAuthState = (event: any) => {
      if (event.key === 'logout') {
        //sync logout for other tabs
        //console.log('logged out from storage!')
        if (_authState.authenticated === true)
          setAuthState(defaultAuthState);
      }
      else if (event.key === 'login') {
        //sync login for other tabs
        //console.log('login  from storage!')
        if (_authState.authenticated === false)
          handleSilentAccessTokenRefresh();
      }
    }
    window.addEventListener('storage', handleSyncBrowserTabAuthState)
    return () => {
      window.removeEventListener('storage', handleSyncBrowserTabAuthState);
    }
  }, [_authState.authenticated])

  useEffect(() => {
    //console.log(`login State update ${JSON.stringify(_authState.accessToken)} from effect _authState.accessToken`)
    if (_authState.accessToken != null) {
      //when a new access Token is comming, start a timer to required next access token after life time
      let currentTimer: NodeJS.Timeout;
      let countdownSec = JwtHelper.tokenLiveSecs(_authState.accessToken);
      //console.log(`refresh countdown start after ${countdownSec} sec`);
      currentTimer = setTimeout(handleSilentAccessTokenRefresh, (countdownSec > 90 ? (countdownSec - 60) : countdownSec - 15) * 1000);
      return () => {
        clearTimeout(currentTimer);
      }
    }
  }, [_authState.accessToken])


  /**
    * Operation on Login Submit
    */
  const handlePasswordLogin = (
    userLogin: string,
    password: string,
    options: ILoginOptions
  ) => {

    let failureHandler = options.failureHandler;
    let beforeSuccessHandler = options.beforeSuccessHandler;
    let afterSuccessHandler = options.afterSuccessHandler;


    if (userLogin === "" || password === "") {
      return options.failureHandler && options.failureHandler(LoginErrorMessage.PASSWORD_NOT_CORRECT);
    }
    AuthenticationService.RadacadLoginRequest({ userLogin: userLogin, password: password },
      (respData) => {
        try {
          LoginResponseDataHandler(respData, beforeSuccessHandler, afterSuccessHandler, (errors: any[]) => errors[0] && failureHandler(errors[0]));
        }
        catch {
          failureHandler(LoginErrorMessage.SERVER_ERROR)
        }
      },
      (error) => {
        failureHandler(LoginErrorMessage.SERVER_ERROR)
      }
    );
  }


  const handleFacebookLogin = (
    facebookAuthCredential: IFacebookAuthCredential,
    options: ILoginOptions
  ) => {
    let failureHandler = options.failureHandler;
    let beforeSuccessHandler = options.beforeSuccessHandler;
    let afterSuccessHandler = options.afterSuccessHandler;
    AuthenticationService.FacebookLoginRequest(
      facebookAuthCredential,
      (loginResponse) => {
        LoginResponseDataHandler(loginResponse, beforeSuccessHandler, afterSuccessHandler, (errors: any[]) => errors[0] && failureHandler(errors[0]));
      },
      (error) => {

      }
    );

  }

  const handleLinkedInLogin = (
    linkedInAuthCredential: ILinkedInAuthCredential,
    options: ILoginOptions
  ) => {
    let failureHandler = options.failureHandler;
    let beforeSuccessHandler = options.beforeSuccessHandler;
    let afterSuccessHandler = options.afterSuccessHandler;

    AuthenticationService.LinkedInLoginRequest(
      linkedInAuthCredential,
      (loginResponse) => {
        LoginResponseDataHandler(loginResponse, beforeSuccessHandler, afterSuccessHandler, (errors: any[]) => errors[0] && failureHandler(errors[0]));
      },
      (error) => {
      }
    );

  }

  const handleMicrosoftLogin = (
    microsoftAuthCredential: IMicrosoftAuthCredential,
    options: ILoginOptions
  ) => {
    let failureHandler = options.failureHandler;
    let beforeSuccessHandler = options.beforeSuccessHandler;
    let afterSuccessHandler = options.afterSuccessHandler;

    AuthenticationService.MicrosoftLoginRequest(
      microsoftAuthCredential,
      (loginResponse) => {
        LoginResponseDataHandler(loginResponse, beforeSuccessHandler, afterSuccessHandler, (errors: any[]) => errors[0] && failureHandler(errors[0]));
      },
      (error) => {
      }
    );

  }

  const handleGoogleLogin = (
    googleAuthCredential: IGoogleAuthCredential,
    options: ILoginOptions
  ) => {
    let failureHandler = options.failureHandler;
    let beforeSuccessHandler = options.beforeSuccessHandler;
    let afterSuccessHandler = options.afterSuccessHandler;

    AuthenticationService.GoogleLoginRequest(
      googleAuthCredential,
      (loginResponse) => {
        LoginResponseDataHandler(loginResponse, beforeSuccessHandler, afterSuccessHandler, (errors: any[]) => errors[0] && failureHandler(errors[0]));
      },
      (error) => {
      }
    );

  }


  const handleTwitterLogin = (
    twitterAuthCredential: ITwitterAuthCredential,
    options: ILoginOptions
  ) => {
    let failureHandler = options.failureHandler;
    let beforeSuccessHandler = options.beforeSuccessHandler;
    let afterSuccessHandler = options.afterSuccessHandler;

    AuthenticationService.TwitterLoginRequest(
      twitterAuthCredential,
      (loginResponse) => {
        LoginResponseDataHandler(loginResponse, beforeSuccessHandler, afterSuccessHandler, (errors: any[]) => errors[0] && failureHandler(errors[0]));
      },
      (error) => {
      }
    );
  }


  const LoginResponseDataHandler = (
    loginResponse: any,
    beforeSuccessHandler: (() => void) | undefined,
    afterSuccessHandler: (() => void) | undefined,
    failureHandler: (errors: string[]) => void
  ) => {
    if (AuthenticationService.CheckLoginSuccess(loginResponse)) {
      beforeSuccessHandler && beforeSuccessHandler();
      LoginSuccessHandler(loginResponse);
      afterSuccessHandler && afterSuccessHandler();
    }
    else {
      failureHandler(loginResponse.errors);
    }
  }

  const LoginSuccessHandler = (loginSuccessResp: IAuthenticationResponse) => {
    setAuthState({
      authenticated: true,
      accessToken: loginSuccessResp.accessToken
    })
  }

  const handleSilentAccessTokenRefresh = async () => {
    const refreshFailedHandler = () => {
      //console.log('silent refresh failed');
      //   setIsLoading(false);
    }
    const refreshSuccessHandler = (data: IAuthenticationResponse) => {
      //console.log('silent refresh success');
      setAuthState({
        authenticated: true,
        accessToken: data.accessToken
      })
      //   setIsLoading(false);
    };
    //console.log(`refresh countdown start `);
    await AuthenticationService.SilentRefreshRequestAsync(refreshFailedHandler, refreshSuccessHandler);
  };

  const handleLogout = useCallback(
    () => {
      if (_authState.accessToken) {
        AuthenticationService.LogoutRequest(_authState.accessToken);

        config.allowFacebookLogin &&
          FacebookService.CheckLoginState(() => {
            FacebookService.FacebookLogout(() => { });
          });
      }

      setAuthState(defaultAuthState);
      window.localStorage.setItem('logout', Date.now().toString());
    },
    [_authState.accessToken, config],
  )
    



  return (
    <AuthContext.Provider
      value={{
        loading:_loading,
        authState: _authState,
        
        passwordLogin: config.allowPasswordLogin ? handlePasswordLogin : undefined,
        facebookLogin: config.allowFacebookLogin ? handleFacebookLogin : undefined,
        microsoftLogin: config.allowMicrosoftLogin ? handleMicrosoftLogin : undefined,
        googleLogin: config.allowGoogleLogin ? handleGoogleLogin : undefined,
        linkedInLogin: config.allowLinkedInLogin ? handleLinkedInLogin : undefined,
        twitterLogin: config.allowTwitterLogin ? handleTwitterLogin : undefined,
        logout: handleLogout,
        refreshAuthState:handleSilentAccessTokenRefresh
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
