import React, { useEffect, useState } from 'react'
import { AdminService } from '../../../_services/AdminService';
import { useRadacadAuth } from '../../../_services/radacad-auth-react/Core/useRadacadAuth'

export default function EmailCredentialManagement() {
    return (
        <div>
            <EmailCredentialEditor />
            <EmailCredentialTester />
        </div>
    )
}
function EmailCredentialEditor() {
    const { authState: { accessToken } } = useRadacadAuth();
    const [currentAccount, setCurrentAccount] = useState<string>("");
    const [accountInput, setAccountInput] = useState<string>("");
    const [passwordInput, setPasswordInput] = useState<string>("");
    const handleSubmit = (account:string,password:string) => {
        accessToken && AdminService.SetEmailCredential({
            account: account,
            password: password
        }, accessToken).then(
            result => {
                if (result.success) {
                    alert("success")
                    setCurrentAccount(account)
                } else {
                    alert("failed")
                }
            }
        )
    }
    useEffect(() => {
        accessToken&&AdminService.GetEmailAccountSetting(accessToken).then(
            (data:string|null)=>{
                if(data){
                    setCurrentAccount(data)
                }else{
                    setCurrentAccount("No Account");
                }
            }
        )
    }, [])
    return <div>
        <div>currentAccount: {currentAccount}</div>
        <div>Account: <input type="email" value={accountInput} onChange={(e) => setAccountInput(e.target.value)} /></div>
        <div>Password: <input type="password" value={passwordInput} onChange={(e) => setPasswordInput(e.target.value)} autoComplete="new-password" /></div>
        <button onClick={() => handleSubmit(accountInput,passwordInput)}>Submit</button>
    </div>
}
function EmailCredentialTester() {

    const { authState: { accessToken } } = useRadacadAuth();
    const [receiverEmailAddress, SetReceiverEmaillAddress] = useState<string>("");
    const handleSend = (receiver:string)=>{
        accessToken&&AdminService.SendTestingEmail(receiver,accessToken).then(
            (boolResult)=>{
                if(boolResult.success){
                    alert("success")
                }else{
                    alert("failed")
                }
            }
        )
    }
    return <div>
        Send a testing email to: <input type="email" value={receiverEmailAddress} onChange={(e) => SetReceiverEmaillAddress(e.target.value)} />
        <button onClick={()=>handleSend(receiverEmailAddress)}>Send</button>

    </div>
}