import React, { useState } from 'react'
import { SSOApi } from '../../apis/SSOApi';
import { useRadacadAuth } from '../../Core/useRadacadAuth';
import { ILinkedInAuthCredential } from '../../Core/IUserCredential';
import { SSOEnum } from '../../Core/SSOType';
import { LinkedInSSOButton } from './LinkedInSSOButton';

export function LinkedInSSOAddButton(props: {
    onStart?: () => void,
    onSuccess?: () => void,
    onFailure?: (errMsg: string) => void
}) {
    const { onStart,onSuccess, onFailure } = props;
    const { linkedInLogin, authState: { accessToken: jwtToken } } = useRadacadAuth();

    const handleSuccess = async (data: ILinkedInAuthCredential) => {

        if (jwtToken) {
            onStart && onStart();
            let booleanResponse = await SSOApi.addSSO(SSOEnum.LinkedIn, data, jwtToken);

            if (booleanResponse) {
                const { success, errorMessage } = booleanResponse;
                if (success) {
                    onSuccess && onSuccess()
                } else {
                    onFailure && onFailure(errorMessage)
                }
            } else {
                onFailure && onFailure("Add LinkedIn login failed");
            }


        }


    }

    return linkedInLogin ?
        <LinkedInSSOButton
            onSuccess={handleSuccess}
            onFailure={(error: { errorMessage: string }) => { onFailure && onFailure(error.errorMessage) }}
        /> : <></>
}