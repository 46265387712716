import React, { Component } from 'react';
import CourseCardList from '../CoursePage/CourseCardListComponent/CourseCardList';

import {LearningPathService} from '../../_services/radacad-academy-lms/LearningPathService';
import Grid from '@material-ui/core/Grid';//material-UI
import FullScreenLoadingProgress from '../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';


export default class LearningPath extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      learningPathList: []
    }

  }

  componentDidMount() {
    LearningPathService.getAllLearningPathsWithCourses(
      (data) => {
        //console.log(data);
        this.setState({ learningPathList: data, loading: false });
      }
    );
  }

  static renderLearningTable(learningPathList) {

    return (
      <Grid container direction="column">
        {
          learningPathList.map((learningPath,index) =>{
            return (
              <Grid container  key={learningPath.learningPathId} id={learningPath.learningPathId}>
         
                <h3 >{learningPath.persona}</h3>
    
                 <CourseCardList courses={learningPath.courses} />
    
                <hr />
            
              </Grid>
              )

          })
        }
      
      </Grid>
    );
  }

  render() {
    let contents = this.state.loading
      ? <FullScreenLoadingProgress></FullScreenLoadingProgress>
      : LearningPath.renderLearningTable(this.state.learningPathList);

    return (
      <div>
        <h1 id="tabelLabel" >Learning Path </h1>
        {contents}
      </div>
    );
  }
}

