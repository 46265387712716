import { HttpHelper } from '../../_commons/Helpers/HttpHelper';
import { ICertificateTemplateModel } from './Models';

export type IGetResponseData = ICertificateTemplateModel;
type IUpdateModel = ICertificateTemplateModel;
interface IDeleteRequestData {
    objectId: number
}

const GetAll = (token: string): Promise<IGetResponseData[] | null> => {
    return HttpHelper.Get(`/api/CMS/CertificateTemplate`, token).then(async (resp) => {
        if (resp.status === 200) {
            let data: IGetResponseData[] = await resp.json();
  
            return data;
        } else {
            return null;
        }

    })
}


const Update = (action: "ADD" | "EDIT", mpCourse: IUpdateModel, token: string): Promise<boolean> => {
    if (action === "ADD")
        return HttpHelper.PostJSON('api/CMS/CertificateTemplate/Add', mpCourse, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
    else
        return HttpHelper.PostJSON('api/CMS/CertificateTemplate/Edit', mpCourse, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
}


const Delete = (reqData: IDeleteRequestData, token: string): Promise<boolean> => {
    return HttpHelper.PostJSON('api/CMS/CertificateTemplate/Delete', reqData, token).then(async (resp) => {
        if (resp.status === 200)
            return true;
        else
            return false;
    })
}


const Sync = (token: string): Promise<boolean> => {
    return HttpHelper.Get('api/CMS/CertificateTemplate/Sync', token).then(async (resp) => {
        if (resp.status === 200)
            return true;
        else
            return false;
    })
}

export const CertificateTemplateCMSApi = {
    GetAll,
    Update,
    Delete,
    Sync
}