import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    borderRadius: 10,

    margin: 5,
    marginBottom: 30,

    // transition: '0.2s',
    // '&:hover': {
    //   transform: 'scale(1.05)',
    //   boxShadow: `0 10px 12px 5px grey`,
    // },
    '& *': {
      color: 'black'
    }
  },

  media: {
    height: 200
  },
  content: {
    height: 75,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  ,
  title: {

    width: '90%',
    textAlign: 'center',
  },
  courseCount: {
    height: 30,
    width: '90%',
    textAlign: 'right'
  }

}));


export default function AuthorCard(props) {
  const classes = useStyles();

  // const [authorId, setAuthorId] = React.useState(props.authorId);
  // const [authorName, setAuthorName] = React.useState(props.authorName);
  // const [authorPhotoLink, setAuthorPhotoLink] = React.useState(props.authorPhotoLink);
  // const [courseCount, setCourseCount] = React.useState(props.courseCount);


  //console.log(props);

  return (
    <>
      <Link to={{ pathname: `/Author/${props.authorId}` }}>
        <Card className={[classes.root, 'myThemeCard', 'myHoverCard'].join(" ")}>

          <CardMedia
            className={classes.media}
            image={props.authorPhotoLink}
            title={props.authorName}
          />
          <div className={classes.content}>
            <h3 className={classes.title} >
              {props.authorName}
            </h3>

          </div>
          <div className={classes.courseCount}>
            <p>{props.courseCount} course(s)</p>
          </div>

        </Card>
      </Link>
    </>
  );
}