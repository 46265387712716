import React from 'react';
import { Route } from 'react-router';
import Container from "@material-ui/core/Container";
import NavMenu from './NavMenu/NavMenu';
import LearningPath from './LearningPathCoursePage/LearningPath';
import CourseDetail from './CoursePage/CourseDetailComponent/CourseDetail';
import CourseSearch from './SearchPage/SearchPage';
import ModuleComponent from './ModulePage/ModuleComponent';
import LessonPage from './LessonPage/LessonDetailComponent/LessonPage';
import SignIn from './SignInPage/SignIn';
import UserProfile from './UserPage/UserProfile/UserProfile';
import AuthorPage from './AuthorPage/AuthorPageComponent/AuthorPage';
import Home from './HomePage/Home';
import AuthorList from './AuthorPage/AuthorListComponent/AuthorList';
import MessagePage from './MessagePage/MessagePage';
import NotFoundPage from './ErrorPage/NotFoundPage';
import AdminPage from './AdminPage/AdminPage';
import LearningPathDiagramPage from './LearningPathDiagramPage/LearningPathDiagramPage'
import { Box } from '@material-ui/core';
import  LiveNotificationDialog  from './LiveNotification/LiveNotification';

export function Layout(props: any) {

  return (<>
    <Box display="flex" flexDirection="column" height={"100vh"} style={{ background: 'black !important' }}>
      <Box>
        <NavMenu></NavMenu>
      </Box>
      <Box width="100%" flexGrow={1} style={{ overflow: "auto" }}>
        <Container maxWidth={false} style={{ height: "100%" }}>

          <Route exact path="/Home" component={Home} />
          <Route exact path='/SignIn' component={SignIn} />
          <Route exact path='/Admin' component={AdminPage} />
          <Route exact path='/LearningPath' component={LearningPath} />
          <Route exact path='/LearningPathDiagram' component={LearningPathDiagramPage} />
          <Route exact path='/Coaches' component={AuthorList} />
          <Route exact strict path="/CourseSearch" component={CourseSearch} />
          <Route exact strict path="/Author/:AuthorId" component={AuthorPage} />
          <Route exact strict path="/Course/:CourseId" component={CourseDetail} />
          <Route exact strict path="/Course/:CourseId/Modules" component={ModuleComponent} />
          <Route exact strict path="/Lesson/:LessonId" component={LessonPage} />
          <Route exact strict path="/UserProfile" component={UserProfile} />
          <Route exact strict path="/Messages" render={(props: any) => (<MessagePage></MessagePage>)} />
          <Route exact path='/NotFound' component={NotFoundPage} />
          <Route exact path="/" component={Home} />

        </Container>
      </Box>
    </Box>
    <LiveNotificationDialog />
  </>
  );
}
