import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";




import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Button } from '@material-ui/core';
import CardMembershipIcon from '@material-ui/icons/CardMembership';

import { LearnerService } from '../../../../_services/LearnerService';
import CourseProgressBar from '../../../_commons/CourseProgressBarComponent/CourseProgressBar';
import { useRadacadAuth } from '../../../../_services/radacad-auth-react/Core/useRadacadAuth';

export interface CourseForCard {
    courseId: string,
    courseTitle?: string | null,
    courseLink?: string | null,
    coursePhotoLink?: string | null,
    includeProgress?: boolean,
    lastDate?: string
    percentage?: string,
    certificateLink?: string
}
export default function CourseCardUI(props: CourseForCard) {
    const { authState: { accessToken } } = useRadacadAuth();
    const classes = useStyles();
    const [courseId, setCourseId] = useState(props.courseId);
    const [courseTitle, setCourseTitle] = useState(props.courseTitle ? props.courseTitle : "");
    const [coursePhotoLink, setCoursePhotoLink] = useState(props.coursePhotoLink ? props.coursePhotoLink : "");
    const _titleRef = useRef<HTMLDivElement | null>(null);

    const ViewCertificateHandler = (accessToken: string) => {
        LearnerService.RequestGetCertificate(accessToken, courseId)
    };

    useEffect(() => {

        if (courseTitle !== "") {

            const letterNumber = courseTitle.length;
            let p = document.createElement("p");
            p.innerText = courseTitle;
            p.style.fontSize = '1em';
            if (letterNumber <= 10) {

                p.style.fontSize = '1.4em';

            } else if (letterNumber <= 30) {
                p.style.fontSize = '1.3em';
            } else if (letterNumber <= 40) {
                p.style.fontSize = '1.1em';
            } else if (letterNumber > 45) {

                p.innerText = courseTitle.substring(0, 44) + " ..."
            }
            _titleRef?.current?.appendChild(p);
        }
    }, [])

    return (
        <>
            {courseTitle !== ""
                &&
                <Link to={`/Course/${courseId}`}>
                    <Card className={[classes.cardRoot, 'myThemeCard', 'myHoverCard'].join(" ")} >

                        <CardActionArea >
                            <CardMedia
                                className={classes.media}
                                image={coursePhotoLink}
                                title={courseTitle}
                            />
                            <CardContent className={classes.title} ref={_titleRef}>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Link>
            }

            {
                props.includeProgress
                &&
                <>
                    <CardContent style={{ padding: '.5em' }}>
                        <CourseProgressBar value={props.percentage}></CourseProgressBar>
                        <small> Last viewed: <span>{props.lastDate}</span></small>
                        {
                            props.certificateLink && accessToken &&
                            <div>
                                <Button variant="contained" color="primary" size="small"
                                    className={`${"myThemeContainedButton"} ${classes.certificateButton}`}
                                    startIcon={<CardMembershipIcon />}
                                    onClick={() => ViewCertificateHandler(accessToken)}>
                                    View Certificate
                                </Button>
                            </div >
                        }
                    </CardContent>
                </>
            }
        </>
    );
}


const useStyles = makeStyles({
    cardRoot: {
        width: 225,
        borderRadius: 10,
        margin: 5,
        boxShadow: '3px 3px 2px 0px rgb(70, 70, 70)',
        '& *': {
            color: 'black'
        }
    },
    media: {
        height: 200
    },
    title: {
        height: 80,
        textAlign: 'center',
        verticalAlign: 'middle ',

        '& p': {
            height: '100%',
            width: '100%'
        }
    },
    certificateButton: {
        transform: 'scale(0.65)',
        transformOrigin: 'top left',
        '&:hover': {
            filter: 'brightness(120%)'
        }
    }
});
