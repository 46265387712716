import React from 'react'
import { SSOApi } from '../../apis/SSOApi';
import { useRadacadAuth } from '../../Core/useRadacadAuth';
import { SSOEnum } from '../../Core/SSOType';

import { ReactComponent as LinkedInIcon } from '../../assets/linkedin_icon.svg';

export function LinkedInSSORemoveButton(props: {
    onStart?: () => void,
    onSuccess?: () => void,
    onFailure?: (errMsg: string) => void
}) {
    const { onStart, onSuccess, onFailure } = props;
    const { linkedInLogin, authState: { accessToken } } = useRadacadAuth();

    const handleOnClick = async (jwtToken: string) => {
        onStart && onStart()

        let booleanResponse = await SSOApi.removeSSO(SSOEnum.LinkedIn, jwtToken);
        if (booleanResponse) {
            const { success, errorMessage } = booleanResponse;
            if (success) {
                onSuccess && onSuccess()
            } else {
                onFailure && onFailure(errorMessage)
            }
        } else {
            onFailure && onFailure("Remove LinkedIn login failed");
        }
    }
    return linkedInLogin && accessToken ? <LinkedInIcon
        style={{ cursor: 'pointer', height: "30px", width: "30px" }}
        onClick={() => handleOnClick(accessToken)} /> : <></>
}