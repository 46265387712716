
import { HttpHelper } from '../../_commons/Helpers/HttpHelper'


export interface ILessonMessageModel {
    messageId: number,
    senderId: number,
    senderName: string,
    receiverId: number,
    datetime: string,
    replyId: number | null,
    messageContent: string,
    readDatetime: string | null
}
const GetLessonMessageList = (token: string, lessonId: number): Promise<any> => {
    return HttpHelper.Get(`api/ChatMessage/LessonMessageList?lessonId=${lessonId}`, token);
}



interface ILessonMessageRequest {
    LessonId: number,
    Datetime: string,
    ReplyId?: number | null,
    MessageContent: string,
}
//private
const PostLessonMessage = (token: string, dataModel: ILessonMessageRequest, respHandler?: (resp: any) => void,
    errorHandler?: (e: any) => void): Promise<any> => {
    return HttpHelper.PostJSON(`api/ChatMessage/LessonMessage`, dataModel, token);
}


export interface HistoryMessageModel {
    messageId: number,
    senderId: number,
    senderName: string,
    receiverId: number,
    datetime: string,
    replyId: number | null,
    messageContent: string,
    lesson: {
        lessonId: number,
        lessonTitle: string
    } | null,
    course: {
        courseId: number,
        courseTitle: string
    },
    title: string | null,
    readDatetime: string | null

}
//private
const GetHistoryMessageList = (chatTargetUserId: string | undefined, token: string): Promise<HistoryMessageModel[] | null> => {
    return HttpHelper.JsonResponse<HistoryMessageModel[]>(HttpHelper.Get(`api/ChatMessage/HistoryMessageList${chatTargetUserId ? `?chatTargetUserId=${chatTargetUserId}` : ""}`, token));
}

interface IReplyMessageRequest {
    LessonId: number,
    Datetime: string,
    ReplyId: number,
    MessageContent: string,

    CourseId: number,
    ReceiverId: number,
    Title?: string
}
//private
const PostReplyMessage = (token: string, dataModel: IReplyMessageRequest, respHandler?: (resp: any) => void, errorHandler?: (e: any) => void): Promise<any> => {
    let api = `api/ChatMessage/ReplyMessage`;
    return HttpHelper.PostJSON(api, dataModel, token);
}




interface INewReadMessageRequest {
    MessageIds: number[]
}
//private
const PostNewReadMessage = (
    token: string,
    bodydata: INewReadMessageRequest,
    respHandler: (resp: any) => void,
    errorHandler: (e: any) => void,
    finalHandler?: () => void
): void => {
    HttpHelper.PostJSON('api/ChatMessage/PostNewReadMessage', bodydata, token)
        .then(
            resp => {
                respHandler(resp);
            })
        .catch(
            err => {
                errorHandler(err)
            }
        ).finally(
            finalHandler
        );
}


export interface GetUnreadMessageCountData {
    unreadMessageCount: number
}
//private
const GetUnreadMessageCount = (token: string, respSuccessHandler: (resp: any) => void,
    respFailedHandler?: (resp: any) => void, errorHandler?: (err: any) => void) => {

    HttpHelper.Get('api/ChatMessage/GetUnreadMessageCount', token)
        .then(
            resp => {
                if (resp.status === 200) {
                    respSuccessHandler(resp)
                } else {
                    if (respFailedHandler)
                        respFailedHandler(resp);
                }
            }
        ).catch(err => {
            if (errorHandler)
                errorHandler(err)
        });
}


interface IChatConversationHeaderMessage {
    messageContent: string,
    messageDateTime: Date
}
export interface IChatConversationHeader {
    targetUser: {
        userId: string,
        userName?: string
    },
    latestMessageSent: IChatConversationHeaderMessage | null
    latestMessageReceived: IChatConversationHeaderMessage | null,
    unreadMessageCount: number
}
const getChatConversationHeaders = async (token: string) => {
    return await HttpHelper.JsonResponse<IChatConversationHeader[]>(HttpHelper.Get('api/ChatMessage/ConversationHeaders', token));
}

export const ChatMessageService = {
    //private
    PostLessonMessage,
    PostReplyMessage,
    GetLessonMessageList,
    GetHistoryMessageList,
    PostNewReadMessage,
    GetUnreadMessageCount,
    getChatConversationHeaders
}