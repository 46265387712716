import { makeStyles } from '@material-ui/core';
export const useLMSLessonStyle=makeStyles({
    root:{
        height:"100%",
        display:"flex"
    },
    leftContainer:{
        width:"300px",
        borderRight:"1px grey solid"
    },
    rightContainer:{

    },

    header:{

    },
    body:{

    },
    footer:{

    }


})