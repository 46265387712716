import { HttpHelper } from '../_commons/Helpers/HttpHelper';

//private
const getUserList = async (dataHandler: any, token: string) => {
    await HttpHelper.Get(`api/Admin/UserList`, token)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
        });
}

//private
const switchUser = (id: any, dataHandler: any, token: string) => {
    HttpHelper.Get(`api/Admin/SwitchUser/${id}`, token)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
        })
}


interface IModule {
    moduleId: number | string,
    moduleOrder: number
}

//private
const UpdateCourseModule = (courseId: number, newModuleList: IModule[], token: string): Promise<Response> => {
    let api = `api/Admin/UpdateModule`;
    return HttpHelper.PostJSON(api,
        {
            courseId: courseId,
            moduleList: newModuleList
        }
        , token)

}


interface IEmailNotificationTemplateResp {
    sourceCode: string
}
interface IEmailNotificationTemplateReq {
    sourceCode: string
}

//private
const GetEmailNotificationTemplate = (token: string): Promise<Response> => {
    let api = `api/Admin/EmailNotificationTemplate`;
    return HttpHelper.Get(api, token);
}

//private
const UpdateEmailNotificationTemplate = (sourceCode: string, token: string): Promise<Response> => {
    let api = `api/Admin/EmailNotificationTemplate`;
    let bodydata: IEmailNotificationTemplateReq = {
        sourceCode: sourceCode
    }
    return HttpHelper.PostJSON(api, bodydata, token);
}
//private
const GetEmailTemplateForUnreadMessageNotification = (token: string): Promise<Response> => {
    let api = `api/Admin/EmailTemplateForUnreadMessageNotification`;
    return HttpHelper.Get(api, token);
}

//private
const UpdateEmailTemplateForUnreadMessageNotification = (sourceCode: string, token: string): Promise<Response> => {
    let api = `api/Admin/EmailTemplateForUnreadMessageNotification`;
    let bodydata: IEmailNotificationTemplateReq = {
        sourceCode: sourceCode
    }
    return HttpHelper.PostJSON(api, bodydata, token);
}

interface IEmailTaskSettingReq {
    emailSubject: string
}
interface IEmailTaskSetting {
    emailSettingId:number,
    emailSubject: string,
    emailTaskName:string
}

//private
const GetEmailTaskSettingForNewMessage =async ( token: string): Promise<IEmailTaskSetting|null> => {
    let api = `api/Admin/EmailTaskSettingForNewMessageNotification`;
    let resp= await HttpHelper.Get(api, token);
    try{
        let data:IEmailTaskSetting = await resp.json();
        return data;
    }catch{
        return null;
    }
}

//private
const UpdateEmailTaskSubjectLineForNewMessage = (subject: string, token: string): Promise<Response> => {
    let api = `api/Admin/EmailTaskSettingForNewMessageNotification`;
    let bodydata: IEmailTaskSettingReq = {
        emailSubject: subject
    }
    return HttpHelper.PostJSON<IEmailTaskSettingReq>(api, bodydata, token);
}

//private
const GetEmailTaskSettingForUnreadMessage =async ( token: string): Promise<IEmailTaskSetting|null> => {
    let api = `api/Admin/EmailTaskSettingForUnreadMessageNotification`;
    let resp= await HttpHelper.Get(api, token);
    try{
        let data:IEmailTaskSetting = await resp.json();
        return data;
    }catch{
        return null;
    }
}

//private
const UpdateEmailTaskSubjectLineForUnreadMessage = (subject: string, token: string): Promise<Response> => {
    let api = `api/Admin/EmailTaskSettingForUnreadMessageNotification`;
    let bodydata: IEmailTaskSettingReq = {
        emailSubject: subject
    }
    return HttpHelper.PostJSON<IEmailTaskSettingReq>(api, bodydata, token);
}




interface IFreeTrialUserSetting {
    freeTrialMaximumWatchMinutes: number
}
const UpdateFreeTrialUserMaximumWatchMinutes = (mins: number, token: string): Promise<boolean> => {
    let api = `api/Admin/FreeTrialUserMaximumWatchMinutes`;
    let bodydata: IFreeTrialUserSetting = {
        freeTrialMaximumWatchMinutes: mins
    }
    return HttpHelper.PostJSON(api, bodydata, token).then(resp => {
        return resp.status === 200;
    });
}

const GetFreeTrialUserMaximumWatchMinutes = (token: string): Promise<number | null> => {
    let api = `api/Admin/FreeTrialUserMaximumWatchMinutes`;
    return HttpHelper.JsonResponse<number>(HttpHelper.Get(api, token));
}

interface IEmailCredential {
    account: string,
    password: string
}
interface IBooleanResult {
    success: boolean,
    error?: string
}
const SetEmailCredential = async (credential: IEmailCredential, token: string): Promise<IBooleanResult> => {
    let api = `api/Admin/EmailCredential`;
    const resp = await HttpHelper.PostJSON(api, credential, token);
    if (resp.status === 200) {
        return { success: true };
    } else {
        return { success: false };
    }
}
const SendTestingEmail = async (receiverEmailAddress: string, token: string): Promise<IBooleanResult> => {
    let api = `api/Admin/SendTestingEmail/${receiverEmailAddress}`;
    const resp = await HttpHelper.Get(api, token);
    if (resp.status === 200) {
        return { success: true };
    } else {
        return { success: false };
    }
}
const GetEmailAccountSetting = async (token: string): Promise<string|null> => {
    let api = `api/Admin/EmailAccountSetting`;
    const resp = await HttpHelper.Get(api, token);
    try{
        return await resp.text();
    }catch{
        return null;
    }

}

export const AdminService = {
    //private 
    getUserList,
    switchUser,
    UpdateCourseModule,
    GetEmailNotificationTemplate,
    UpdateEmailNotificationTemplate,
    EmailTemplate:{
        GetEmailTemplateForUnreadMessageNotification,
        UpdateEmailTemplateForUnreadMessageNotification
    },
    EmailTaskSetting:{
        GetEmailTaskSettingForNewMessage,
        UpdateEmailTaskSubjectLineForNewMessage,
        GetEmailTaskSettingForUnreadMessage,
        UpdateEmailTaskSubjectLineForUnreadMessage
    },
    FreeTrialUserMaximumWatchMinutes: {
        Get: GetFreeTrialUserMaximumWatchMinutes,
        Update: UpdateFreeTrialUserMaximumWatchMinutes
    },
    SetEmailCredential,
    SendTestingEmail,
    GetEmailAccountSetting
}

