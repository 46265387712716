// @ts-ignore
import jwt_decode from 'jwt-decode';



const TokenIsExpired = (token: string) => {
    try {
        return jwt_decode<any>(token).exp < Date.now() / 1000;
    } catch (error) {
        return true;
    }

}



const GetUserName = (token: string | null): string | null => {
    //console.log(token)
    if (token)
        return jwt_decode<any>(token).unique_name ?? null;
    else
        return null;
}

const GetUserId = (token: string | null): string => {
    if (token)
        return jwt_decode<any>(token).nameid ?? "-1";
    else
        return "-1"
}

const IsRole = (token: string | null, roleType: string): boolean => {
    try {
        if (token) {
            let roles: string | string[] | null = jwt_decode<any>(token).role;

         
            if (roles)//if it is not null
            {

                if (Array.isArray(roles))// if is type of string []
                {
                    return roles.includes(roleType);
                }
                else// if is type of string 
                {
                    return roles===roleType;
                }
            }


        }

        return false;
    } catch {
        return false;
    }
}

const DecodeToken = (token: string | null): string | null => {
    if (token)
        return jwt_decode(token);
    else
        return null;
}

const tokenLiveSecs = (token: string) => {
    let decodedToken = JwtHelper.DecodeToken(token);
    //@ts-ignore
    return decodedToken.exp - decodedToken.iat;
}



export const JwtHelper = {
    GetUserName,
    GetUserId,
    IsRole,
    DecodeToken,
    tokenLiveSecs,
    TokenIsExpired
} 