import React, { useCallback } from 'react'
import useFetchingData from '../../../_commons/Hooks/useFetchingData';
import { useDynamicContentDialog } from '../../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
import { LMSLessonApi } from '../../../_services/CMSServices/LMSLessonApi';
import { ILMSCourseModel, ILMSLessonModel, ILMSModuleModel } from '../../../_services/CMSServices/Models';
import { LMSModuleApi } from '../../../_services/CMSServices/LMSModuleApi';
import CourseLessonOrderEditor from './CourseLessonOrderEditor';
import { useLMSLessonStyle } from './LMSLesson.style';
import { LMSLessonTable } from './LMSLessonTable';
import { LMSLessonForm } from './LMSLessonForm';



export default function LMSLessonPanel(props: { course: ILMSCourseModel, token: string }) {

    const { course: { courseId, courseTitle }, token } = props;

    const classes = useLMSLessonStyle();


    const { data: courseLessons, handleRefresh: handleCourseLessonsRefresh } = useFetchingData<ILMSLessonModel[]>(
        useCallback(
            (setCourseLessons) => LMSLessonApi.Get(courseId, undefined, token).then((data) => {
                if (data)
                    setCourseLessons(data);
                else
                    setCourseLessons(null);
            }).catch(() => {
                setCourseLessons(null);
            }),
            [courseId, token],
        )

    )

    const { data: courseModules } = useFetchingData<ILMSModuleModel[]>(
        useCallback(
            (setData) => LMSModuleApi.GetCourseModules(courseId, token).then((data) => {
                if (data)
                    setData(data);
                else
                    setData(null);
            }).catch(() => {
                setData(null);
            }), [courseId, token])
    )



    const useDialog = useDynamicContentDialog({ maxWidth: 'lg', fullWidth: true });




    const handleCancel = () => {
        useDialog.close();
    }
    const handleAddOrEdit = (option: { action: "ADD" } | { action: "EDIT", object: ILMSLessonModel }) => {
        useDialog.open(
            <LMSLessonForm
                lesson={option.action === "ADD" ? undefined : option.object}
                courseId={courseId}
                courseModules={courseModules}
                submitHandler={(lesson: ILMSLessonModel) => { handleFormSubmit(option.action, lesson) }}
                cancelHandler={handleCancel}
               
            />
        )
    }
    const handleFormSubmit = (action: "ADD" | "EDIT", lesson: ILMSLessonModel) => {

        if (window.confirm("confirm to submit?"))
            LMSLessonApi.Update(action, lesson, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    useDialog.close();
                    handleCourseLessonsRefresh();
                }

                else
                    alert("failed");

            })

    }
    const handleDelete = (lessonId: number) => {
        if (window.confirm("confirm to submit?"))
            LMSLessonApi.Delete({ lessonId }, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    handleCourseLessonsRefresh();
                }

                else
                    alert("failed");

            })
    }

    const handleOrderButton = () => {
        useDialog.open(
            <CourseLessonOrderEditor
                courseId={courseId}
                accessToken={token}
                submitHandler={() => {
                    useDialog.close();
                    handleCourseLessonsRefresh();
                }}
                cancelHandler={
                    handleCancel
                }
            />
        )
    }

    return (
        <div className={`${classes.rightContainer}`}>
            <h2>Course: {courseTitle}</h2>
            <div className={classes.header}>
                <button onClick={() => handleAddOrEdit({ action: "ADD" })}>Add New Lesson to this Course</button>
                <button onClick={() => handleOrderButton()}>Edit lesson order for this course</button>
            </div>

            <div className={classes.body}>
                <LMSLessonTable
                    lessons={courseLessons}
                    courseModules={courseModules}
                    editButtonHandler={(lesson) => handleAddOrEdit({ action: "EDIT", object: lesson })}
                    deleteButtonHandler={handleDelete}
                />


            </div>
            <div className={classes.footer}>

            </div>

            {useDialog.render()}
        </div>
    )
}