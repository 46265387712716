import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Chip } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';


const useIconStyles = makeStyles({
  greenIcon: {
    "& *": {
      color: "green !important"
    }


  }
});

export default function LessonCard(props) {
  
  const classes = useIconStyles();

  const [_lessonId, setLessonId] = useState(props.lesson.lessonId);
  const [_lessonTitle, setLessonTitle] = useState(props.lesson.lessonTitle);
  const [_preview, setPreview] = useState(props.lesson.preview);
  const [_showProgress, setShowProgress] = useState(props.showProgress);
  const [_completed, setCompleted] = useState(props.completed);

  useEffect(() => {
    setCompleted(props.completed)
  }, [props.completed]);

  useEffect(() => {
    setShowProgress(props.showProgress)
  }, [props.showProgress]);


  return (
    <Grid container spacing={1} >
      <Grid item style={{ paddingTop: '11px', transform: 'scale(1.1)' }}>
        {
          _showProgress &&
          (
            _completed ?
              <CheckCircleOutlineOutlinedIcon className={classes.greenIcon} /> :
              <RadioButtonUncheckedIcon />
          )

        }
      </Grid>
      <Grid item xs={true}>
        <Grid container>
          <Grid item>
            <Link
              to={`/Lesson/${_lessonId}`}
            >

              <SimpleCard
                lessonTitle={_lessonTitle}
                completed={_completed}
              > </SimpleCard>

            </Link>
          </Grid>
          <Grid item xs={12}>
            {
              _preview
              &&
              <Chip label="Preview" size='small' color='primary' style={{ backgroundColor: 'green' }} variation='outlined'></Chip>
            }
          </Grid>
        </Grid>
      </Grid>


    </Grid>


  )

}


const useStyles = makeStyles({
  root: {
    width: '100%',


  },
  completedBg: {
    backgroundColor: ' #1f6040'
  }
  ,
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    padding: '10px'
  },
  pos: {
    marginBottom: 12,
  }
});

function SimpleCard(props) {
  const classes = useStyles();


  return (

    <Card className={`${classes.root} ${props.completed ? classes.completedBg : 'myThemeCard'} ${'myHoverLessonCard'}`}>
      <div className={classes.title}>
        {props.lessonTitle}
      </div>
    </Card>


  );
}