import React, { useCallback, useState } from 'react'

export default function useHTMLInput(defaultValue?: string) {
    const [_input, setInput] = useState<string>(defaultValue ?? "");

    const render = useCallback(
        () => <input type="text" onChange={(e) => setInput(e.target.value)} value={_input}></input>
        ,
        [_input],
    )
    return { value: _input, render }
}
