import React, { useEffect, useState } from 'react'
import { AdminService } from '../../../_services/AdminService';
import { useRadacadAuth } from '../../../_services/radacad-auth-react/Core/useRadacadAuth';

export default function MembershipManagement() {

    return (
        <div>
            <FreeTrialUserMaximumWatchMinutes />
        </div>
    )
}

function FreeTrialUserMaximumWatchMinutes() {

    const { authState: { accessToken } } = useRadacadAuth();
    const [minutes, setMinutes] = useState<number | undefined>(undefined);
    useEffect(() => {
        accessToken &&
            AdminService.FreeTrialUserMaximumWatchMinutes.Get(accessToken).then(
                data => {
                    if (data)
                        setMinutes(data);
                    else {
                        alert("falied when fetching free trial user maximum watch minutes")
                    }
                }


            )

    }, [])

    const handleOnSubmit =(mins:number)=>{
        accessToken &&
            AdminService.FreeTrialUserMaximumWatchMinutes.Update(mins,accessToken).then(
                data => {
                    if (data)
                         alert("succeed to update free trial user maximum watch minutes")
                    else {
                        alert("falied to update free trial user maximum watch minutes")
                    }
                }


            )
    }
    return (
        <div>
            Free Trial User Maximum Watch Minutes
            <input type="number" value={minutes} onChange={(e)=>{setMinutes(parseInt(e.target.value))}}/>
            <button disabled={minutes===undefined} onClick={()=>{minutes&&handleOnSubmit(minutes)}}>submit </button>
        </div>

    )
}
