
const GetPlayer = (iframe) => {
    try{
        return new window.Vimeo.Player(iframe);
    }catch{
        return null;
    }
   
}

const GetPlaybackRateAsync = async (player) => {
    if (player)
        return await player.getPlaybackRate().then(function (playbackRate) {
            return playbackRate;
        });
}
const GetCurrentTimeAsync = async (player) => {
    if (player)
        return await player.getCurrentTime().then(function (seconds) {
            return seconds;
        });
}

const GetTextTracksAsync = async (player) => {
    if (player)
        return await player.getTextTracks().then(function (tracks) {
            return tracks;
        });
}
const GetTextTracksActiveStatusAsync = async (player) => {
    if (player)
        return await player.getTextTracks().then(function (tracks) {
            return tracks[0]?.mode === "showing" ? true : false;
        });
}

const GetVideoIdAsync = async (player) => {
    if (player)
        return await player.getVideoId().then(id => id);


}
const GetPauseStateAsync = async (player) => {
    if (player)
        return await player.getPaused().then(paused => paused);
}
const DisableTextTrack = async (player) => {
    if (player)
        player.disableTextTrack().then(() => { });
}


const AddListenerToPlayer = (player, event, callback) => {
    if (player)
        player.on(event, callback);
}

const RemoveListenerToPlayer = (player, event, callback) => {
    if (player)
        player.off(event, callback);
}

const RemoveAllListenerToPlayer = (player, event) => {
    if (player)
        player.off(event);
}

const PlayerReady = (player, callback) => {
    if (player)
        player.ready().then(callback);
}
const SetCurrentTime = (player, timePosition, callback) => {

    if (player)
        player.setCurrentTime(timePosition ?? 0).then(
            function (seconds) {
                // `seconds` indicates the actual time that the player seeks to
                callback(seconds);
            }).catch(function (error) {
                switch (error.name) {
                    case 'RangeError':
                        // The time is less than 0 or greater than the video's duration
                        break;

                    default:
                        // Some other error occurred
                        break;
                }
            });
}

const PlayerPlay = (player, callback) => {
    if (player)
        player
            .play()
            .then(callback)
            .catch(function (error) {
                //console.log(error);
                switch (error.name) {
                    case 'PasswordError':
                        // The video is password-protected
                        break;

                    case 'PrivacyError':
                        // The video is private
                        break;

                    default:
                        // Some other error occurred
                        break;
                }
            });
}



export const VimeoService = {
    GetPlayer,
    GetCurrentTimeAsync,
    GetPlaybackRateAsync,
    GetTextTracksAsync,
    GetVideoIdAsync,
    GetTextTracksActiveStatusAsync,
    GetPauseStateAsync,
    DisableTextTrack,

    AddListenerToPlayer,
    RemoveListenerToPlayer,
    RemoveAllListenerToPlayer,
    PlayerReady,
    SetCurrentTime,
    PlayerPlay
}