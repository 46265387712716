import { Divider, Grid } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react'
import { AcademyContext } from '../../_contexts/UserContext';


import { CourseService } from '../../_services/radacad-academy-lms/CourseService';
import { LearnerService } from '../../_services/LearnerService';

import CourseCardList from '../CoursePage/CourseCardListComponent/CourseCardList';
import CourseScrollContainer from '../CoursePage/CourseScrollContainerComponent/CourseScrollContainer';
import LearningPathCard from '../LearningPathCoursePage/LearningPathCard';
import FullScreenLoadingProgress from '../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';

export default function Home() {

    const loginContext = useContext(AcademyContext);
    const [isLoading, setIsLoading] = useState(true);

    const [NewCourses, setNewCourses] = useState([]);
    const [InProgressCourses, setInProgressCourses] = useState([]);
    const [CompletedCourses, setCompletedCourses] = useState([]);




    useEffect(() => {

        FetchHomePageCoursesData();


    }, []);
    const FetchHomePageCoursesData = () => {

        CourseService.GetNewCourseCards(
            (data) => {
                //console.log(data);
                setNewCourses(data.newCourses);


                if (loginContext.loginState.isLoggedIn) {
                    LearnerService.RequestGetCourseLearnt(
                        loginContext.loginState.accessToken,
                        (data) => {
                            //console.log(data);
                            setInProgressCourses(data.coursesInProgress);
                            setCompletedCourses(data.coursesCompleted);
                            setIsLoading(false);
                        }
                    )
                }
                else {
                    setIsLoading(false);
                }

            });


    }

    const RenderLearningPath = () => {

        const fakeLearningPathData =
            [
                {
                    "learningPathId": 1,
                    "persona": "BI Analyst",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2019/05/2019-05-24_08h21_54.png"
                },
                {
                    "learningPathId": 2,
                    "persona": "BI Developer",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2017/06/Module3-1.png"
                },
                {
                    "learningPathId": 3,
                    "persona": "BI Architect",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/05/2020-05-18_08h45_58.png"
                },
                {
                    "learningPathId": 7,
                    "persona": "App Developer",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/04/2020-05-25_20h09_25.png"
                },
                {
                    "learningPathId": 8,
                    "persona": "Applied AI Specialist",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/05/2020-06-09_19h46_06.png"
                },
                {
                    "learningPathId": 9,
                    "persona": "ETL Developer",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/05/2020-05-28_19h20_45.png"
                },
                {
                    "learningPathId": 10,
                    "persona": "Power Platform Architect",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/08/2020-08-14_17h03_52.png"
                },
                {
                    "learningPathId": 11,
                    "persona": "Data Scientist",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/04/2020-04-30_16h15_24.png"
                },
                {
                    "learningPathId": 13,
                    "persona": "Conference Attendee",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/04/2020-04-02_13h10_19.png"
                },
                {
                    "learningPathId": 14,
                    "persona": "Database Developer",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/06/2020-06-29_10h02_20.png"
                },
                {
                    "learningPathId": 15,
                    "persona": "Dynamics 365 Developer",
                    "imageURL": "https://learn.radacad.com/wp-content/uploads/2020/07/2020-07-17_11h24_36.png"

                }
            ]
        return (

            <Grid container spacing={2}>
                <Divider ></Divider>
                <Grid item xs={12}>
                    <h3>Explore Learning Paths</h3>
                </Grid>
                <Grid item xs={12}>
                    <CourseScrollContainer>

                        <Grid container spacing={5} wrap="nowrap">

                            {
                                fakeLearningPathData.map(
                                    (learningPath, index) =>
                                        <Grid item key={index}>
                                            <LearningPathCard learningPath={learningPath}></LearningPathCard>
                                        </Grid>
                                )

                            }


                        </Grid >

                    </CourseScrollContainer>
                </Grid>
            </Grid >

        )



    }


    return <Grid>
        {isLoading ?
            (<FullScreenLoadingProgress></FullScreenLoadingProgress>)
            :
            (
                <div>
                    <h3>Recent courses</h3>
                    <CourseCardList courses={NewCourses}></CourseCardList>

                    {loginContext.loginState.isLoggedIn &&
                        (
                            <>
                                {InProgressCourses != null && InProgressCourses.length > 0 &&
                                    (
                                        <>
                                            <h3>Courses In Progress </h3>
                                            <CourseCardList courses={InProgressCourses} withPercent={true}></CourseCardList>
                                        </>
                                    )
                                }

                                {CompletedCourses != null && CompletedCourses.length > 0 &&
                                    (
                                        <>
                                            <h3>Courses Completed</h3>
                                            <CourseCardList courses={CompletedCourses} withPercent={true}></CourseCardList>
                                        </>
                                    )
                                }
                            </>
                        )

                    }



                </div>
            )}
        {RenderLearningPath()}
    </Grid>
}
