import { useEffect, useState } from 'react'
import { LMSAuthorApi } from '../../_services/CMSServices/LMSAuthorApi';
import { ILMSAuthorModel } from '../../_services/CMSServices/Models';

type LMSAuthorStateType = ILMSAuthorModel[] | undefined | null;
export default function useLMSAuthor(token: string) {
    const [authors, setAuthors] = useState<LMSAuthorStateType>();

    useEffect(() => {
        LMSAuthorApi.GetAllAuthors(token).then((data) => {
            setAuthors(data);

        }).catch(() => {
            setAuthors(null);
        })
    }, [token])

    return { authors };
}
