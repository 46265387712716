import React from "react";
import { ICertificateTemplateModel } from "../../../_services/CMSServices/Models";
import { useCertificateTemplateCMSFormStyle } from "./CertificateTemplateCMSForm.style.";

type DataModelType = ICertificateTemplateModel;
export interface ICourseTitleDto { courseId: number, courseTitle: string }
export function CertificateTemplateCMSForm(
    props: {
        dataObject: DataModelType | undefined,

        submitHandler: (dataObject: DataModelType) => void,
        cancelHandler: () => void

    }
) {

    const { dataObject, submitHandler, cancelHandler } = props;
    const classes = useCertificateTemplateCMSFormStyle();



    const handleOnChange = (e: any, setState: (state: any) => void) => {
        setState(e.target.value);
    }

    const handleSubmit = () => {

        submitHandler({
            certificateTemplateId:dataObject?.certificateTemplateId??0,
            certificateTitle: dataObject?.certificateTitle??"",
            certificateURL: dataObject?.certificateURL??""
        })

    }
    const handleCancel = () => {
        cancelHandler();
    }
    return <div className={classes.root}>
        <div >
            <label> Certificate Title   </label>
            <input
                type="text"
                value={dataObject?.certificateTitle??""}
                // onChange={(e: any) => { handleOnChange(e, setMembershipPlanId) }}
                disabled
            />

        </div>
        <div >
            <label> Certificate URL   </label>
            <input
                type="text"
                value={dataObject?.certificateURL??""}
                // onChange={(e: any) => { handleOnChange(e, setMembershipPlanId) }}
                disabled
            />

        </div>

        <div className={classes.footerContainer}>

            <div>
                <button disabled onClick={() => { handleSubmit() }}>Submit</button>
                <button onClick={() => { handleCancel() }}>Cancel</button>
            </div>


        </div>
    </div >
}

