import React from "react";
import { ILMSLessonModel, ILMSModuleModel } from "../../../_services/CMSServices/Models";
import { PrivateResourceLink } from "../../_commons/PrivateResourceLink";
import { useLMSLessonTableStyles } from "./LMSLessonTable.style";

export function LMSLessonTable(props: {
    lessons: ILMSLessonModel[] | null | undefined,
    courseModules: ILMSModuleModel[] | null | undefined,
    editButtonHandler: (lesson: ILMSLessonModel) => void,
    deleteButtonHandler: (lessonId: number) => void
}
) {

    const { lessons, courseModules, editButtonHandler, deleteButtonHandler } = props;
    const classes = useLMSLessonTableStyles(11)();

    return lessons === undefined ? <div>isLoading...</div> :
        lessons === null ? <div>Data fetching failed</div> :
            <table className={classes.LMSTable}>
                <thead>

                    <tr>
                        <td>Lesson Title</td>
                        <td>Module</td>
                        <td>Order In Course</td>

                        <td>Order Id Module</td>
                        <td>Is Preview</td>
                        <td>Lesson Length(mins)</td>
                        <td>Lesson Video URL</td>
                        <td>Lesson Material HTML</td>
                        <td>Material</td>
                        <td colSpan={2}>Actions</td>

                    </tr>
                </thead>
                <tbody>
                    {lessons.map((value: ILMSLessonModel, index: number) => {


                        return <tr key={value.lessonId}>

                            <td>{value.lessonTitle}</td>
                            <td>{courseModules ? courseModules.find((module: ILMSModuleModel) => value.moduleId && module.moduleId === value.moduleId)?.moduleTitle ?? "null" : "loading"}</td>
                            <td>{value.orderInCourse}</td>


                            <td>{value.orderInModule}</td>
                            <td>{value.isPreview + ""}</td>
                            <td>{value.lessonLengthInSecond}</td>
                            <td>{value.lessonVideoUrl}</td>
                            <td>{value.lessonMaterialHtml}</td>
                            <td>{value.lessonMaterialLink && <PrivateResourceLink href={value.lessonMaterialLink}><button>download</button></PrivateResourceLink>}</td>
                            <td><button onClick={() => { editButtonHandler(value) }}>Edit</button></td>
                            <td><button onClick={() => { deleteButtonHandler(value.lessonId) }}>Remove </button></td>

                        </tr>

                    })}
                </tbody>
            </table>




}
