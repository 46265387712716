import React from 'react';
import { ReactComponent } from '../../../assets/linkedin_icon.svg';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png'


export const LinkedInIcon = (props: { style?: React.CSSProperties, withText?: boolean, label?: string }) => {
    const { style, withText, label } = props;
    return withText ? <img src={linkedin} alt={label ?? "Log in with LinkedIn "} style={{ maxWidth: '180px', ...style }} />
        : <ReactComponent style={{ width: '30px', height: "30px", marginRight: "6px", ...style }} />

}
