import { makeStyles } from "@material-ui/core";
import React from "react";
const useFormStyle = makeStyles({
    root: {
        margin: "20px auto",
        padding: "20px ",
        backgroundColor: "white"
    },
    footerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px"
    }

})
export function ConfirmFormDiv(
    props: {
        submitHandler: () => void,
        cancelHandler: () => void,
        submitDisabled?:boolean,
        cancelDisabled?:boolean,
        children: JSX.Element | undefined,


    }
) {
    const classes= useFormStyle();
    const { submitHandler, cancelHandler, submitDisabled, cancelDisabled,children } = props;

    const handleSubmit = () => {

        submitHandler();

    }
    const handleCancel = () => {
        cancelHandler();
    }
    return <div className={classes.root}>
        {children}
        <div className={classes.footerContainer}>

            <div>
                <button disabled={submitDisabled} onClick={() => { handleSubmit() }}>Submit</button>
                <button  disabled={cancelDisabled} onClick={() => { handleCancel() }}>Cancel</button>
            </div>
        </div>
    </div >
}

