import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { makeStyles } from '@material-ui/core/styles'
import ReplyIcon from '@material-ui/icons/Reply';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import MessageList from './MessageList';
import { MessageEditor } from './MessageEditor';
import HTMLTextDiv from './HTMLTextDiv';
import { HTMLTextOneLineDiv } from './HTMLTextOneLineDiv';
import { Box } from '@material-ui/core';



export interface ILessonMessage {
    "text": string,
    "id": string,
    "datetime": string,
    "replyId": number | null,

    "lesson": null | {
        lessonId: number,
        lessonTitle: string
    },
    "course": null | {
        courseId: number,
        courseTitle: string
    },
    "title": string | null,
    "sender": {
        "name": string,
        "uid": string,
        "avatar"?: string,
    }
}
export interface IMessageUser {
    "uid": string
}
export interface ITempLessonMessage {
    // index:number,
    messageText: string,
    tempMessageState: ITempMessageStateType,
    messageReplyTo: ILessonMessage
}
export enum ITempMessageStateType {
    sending = "Sending",
    failed = "Failed to send"

}


MessageBox.defaultProps = {
    messages: [],
    user: {
        "uid": "user1"
    },
    isLoading: false,
    onSubmit: (newMessage: string, messageReplyTo: ILessonMessage) => console.log(newMessage)
};


export default function MessageBox(props: {
    isLoading: boolean,
    messages: (ILessonMessage | ITempLessonMessage)[],
    user: IMessageUser,
    receiverId: number | undefined,
    onSubmit: (newMessage: string, messageReplyTo: ILessonMessage) => void
    renderMessage?: (messages: ILessonMessage[], user: IMessageUser) => void,
    typingListner?: () => void,
    typingIndicator?: JSX.Element,
    EmptyContentTitle: string

}) {

    const classes = useStyles();
    let { messages, isLoading, user, receiverId } = props;

    const [_message, setMessage] = useState<string>('');
    const [_openReplyInput, setOpenReplyInput] = useState<boolean>(false);
    const [_messageReplyTo, setMessageReplyTo] = useState<ILessonMessage | null>();

    const ResetMessageEditorState = () => {
        setMessage('');
        setOpenReplyInput(false);
        setMessageReplyTo(null);
    }

    useEffect(() => {
        ResetMessageEditorState();
    }
        , [receiverId])
    useEffect(() => {
        setMessage('');
    }
        , [_messageReplyTo])


    const HandleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        props.onSubmit(_message, _messageReplyTo!);
        ResetMessageEditorState();

    }



    const renderTempMessage = (tempMessage: ITempLessonMessage) => {

        let isUser = true;
        return (
            <div

                className='chat-bubble-row'
                style={{
                    flexDirection: isUser ? 'row-reverse' : 'row'
                }}>
                <div>
                    <div className={`chat-bubble 
                    ${isUser ? 'is-user' : 'is-other'} 
                    ${tempMessage.tempMessageState === ITempMessageStateType.sending ? 'is-sending' : 'is-failed'} 
                    `}>

                        <div
                            className='message'
                            style={{
                                color: isUser ? '#FFF' : '#2D313F',
                                backgroundColor: 'white !important'
                            }}>
                            <HTMLTextDiv messageText={tempMessage.messageText} />
                        </div>

                    </div>
                    {tempMessage.tempMessageState}

                </div >
            </div >
        )
    }
    const renderLessonMessage = (message: ILessonMessage, user: IMessageUser) => {

        let isUser = user.uid === message.sender.uid;

        return (
            <div
                className='chat-bubble-row'
                style={{ flexDirection: isUser ? 'row-reverse' : 'row' }}>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: !isUser ? 'flex-start' : 'flex-end'
                        }}
                    >
                        <div className='sender-name'>{isUser ? 'Me' : `${message.sender.name}`}</div>

                        <div
                            className={classes.datetime}>
                            {Moment(new Date(message.datetime)).format('YYYY-MM-DD h:mm:ss')}

                        </div>
                    </div>

                    <div className={`chat-bubble ${isUser ? 'is-user' : 'is-other'}`}>

                        {message.title &&
                            <div
                                className={classes.replyMessage}
                            >
                                (Re: <HTMLTextDiv style={{ display: "inline-block" }} messageText={message.title} />)
                            </div>
                        }
                        <div
                            className='message'
                            style={{
                                color: isUser ? '#FFF' : '#2D313F',
                                backgroundColor: 'white !important'
                            }}

                        >
                            <HTMLTextDiv messageText={message.text} />

                        </div>

                    </div>

                    {
                        isUser === false &&
                        <div className={classes.messageIcon}>

                            {message.course &&
                                <div
                                    style={{ marginLeft: '2px' }}
                                    title={" View the related course"}
                                    onClick={() => HandleClickViewCourseButton(message.course!.courseId)}
                                >
                                    <LibraryBooksIcon fontSize="small"  >
                                    </LibraryBooksIcon>
                                </div>
                            }
                            {message.lesson &&
                                <div
                                    style={{ marginLeft: '2px' }}
                                    title={"View the related lesson"}
                                    onClick={() => HandleClickViewLessonButton(message.lesson!.lessonId)}

                                >
                                    <OndemandVideoIcon fontSize="small" >
                                    </OndemandVideoIcon>
                                </div>
                            }


                            <div
                                className={classes.rightIcon}
                                title={"Reply to this message"}
                                onClick={() => HandleClickReplyButton(message)}
                            >
                                <ReplyIcon fontSize="small" >
                                </ReplyIcon>
                            </div>


                        </div>
                    }

                </div>
            </div >
        );
    }

    const HandleRenderMessageList = (messages: (ILessonMessage | ITempLessonMessage)[], user: IMessageUser) => {

        return messages.map((message: any, index) => {

            return <div key={index}>
                {
                    message.tempMessageState ?
                        renderTempMessage(message)
                        : renderLessonMessage(message, user)
                }
            </div>

        })


    }

    const HandleClickReplyButton = (messageReplyTo: ILessonMessage) => {
        setOpenReplyInput(true);
        setMessageReplyTo(messageReplyTo);
    }

    const HandleClickViewLessonButton = (lessonId: number) => {
        const newWindows = window.open(`/Lesson/${lessonId}`, "_blank");
        newWindows?.focus();
    }

    const HandleClickViewCourseButton = (courseId: number) => {
        const newWindows = window.open(`/Course/${courseId}`, "_blank");
        newWindows?.focus();
    }




    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%" className={classes.chatBox}>

            <Box className={classes.msgPage} flexGrow={1}>
                <MessageList
                    isLoading={isLoading}
                    messages={messages}
                    user={user}
                    renderMessage={() => HandleRenderMessageList(messages, user)}
                    EmptyContentTitle={props.EmptyContentTitle}
                    EmptyContentSubTitle={''}
                />
            </Box>

            <Box flexGrow={0} hidden={!_openReplyInput} width="100%">
                {_messageReplyTo &&
                    <div className={classes.replyHintDiv}>
                        {`Reply to (${_messageReplyTo?.sender.name}:`}
                        <HTMLTextOneLineDiv style={{ display: "inline-block" }} messageText={_messageReplyTo.text} />)
                    </div>
                }
                <form
                    onSubmit={HandleSendMessage}>
                    <div >
                        <MessageEditor
                            defaultHtmlStr={_message}
                            htmlStrSetter={setMessage}
                        />
                    </div >
                    <button
                        style={{ width: "100%" }}
                        className={'myThemeContainedButton'}
                        disabled={_message === ""}

                    >Submit</button>
                </form>
            </Box>


        </Box >
    );
}
const useStyles = makeStyles({
    chatBox: {
        position: 'relative',
        letterSpacing: '0.5px',
        padding: '0 !important',
        boxSizing: 'border-box'
    },

    msgPage: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '2px'
    },
    formControl: {
        border: 'none !important',
        backgroundColor: '#555 !important',
        resize: 'none',
        '&.focused': {

            borderColor: 'none !important',
            boxShadow: 'none !important',

            borderRadius: '20px'
        }

    },
    replyHintDiv: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

    },
    messageIcon: {
        display: 'flex',
        '&>div': {
            cursor: 'pointer',
            borderRadius: '5px',
            padding: '2px',
            '&:hover': {
                backgroundColor: '#555555',
            }

        },


    },
    rightIcon: {
        marginLeft: 'auto',

    },
    replyIcon: {
        display: 'flex',
        flexDirection: 'row-reverse'

    },
    replyMessage: {
        fontSize: "10px",
        opacity: '0.5'
    },
    datetime: {
        fontSize: '9px',
        opacity: '0.7'
    }
})
