import { HttpHelper } from './../_commons/Helpers/HttpHelper';



const GetSystemVersionNumber = (): Promise<Response> => {
    return HttpHelper.Get("api/System/Version");
}

export const SystemService = {
    GetSystemVersionNumber
}