import React from "react";
import { IMembershipPlanCourseViewModel } from "../../../_viewmodels/MembershipPlanCourseViewModel";
import { IMembershipPlanCourseModel, IMembershipPlanModel } from "../../../_services/CMSServices/Models";

export function MembershipPlanCourseCMSTable(props: {
    memberhipPlanCourse: IMembershipPlanCourseViewModel[] | undefined | null,
    membershipPlans:IMembershipPlanModel[] | undefined | null,
    editButtonHandler: (course: IMembershipPlanCourseModel) => void,
    deleteButtonHandler: (courseId: number) => void
}
) {

    const { memberhipPlanCourse, membershipPlans,editButtonHandler, deleteButtonHandler } = props;


    return memberhipPlanCourse === undefined ? <div>isLoading...</div> :
        memberhipPlanCourse === null ? <div>Data fetching failed</div> :
            <table>
                <thead>

                    <tr>
                        <td>MembershipPlan Id</td>
                        <td>Course Title</td>
                        <td colSpan={2}>Actions</td>

                    </tr>
                </thead>
                <tbody>
                    {memberhipPlanCourse.map((value: IMembershipPlanCourseViewModel, index: number) => {
                        return <tr key={value.membershipPlanCourseId}>
                            <td>{membershipPlans?.find(mp=>mp.membershipPlanId===value.membershipPlanId)?.membershipPlanName}</td>
                            <td>{value.courseTitle}</td>

                            <td><button onClick={() => { editButtonHandler(value) }}>Edit</button></td>
                            <td><button onClick={() => { deleteButtonHandler(value.membershipPlanCourseId) }}>Remove </button></td>

                        </tr>

                    })}
                </tbody>
            </table>




}
