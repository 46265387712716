import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'moment';

import MessageList from '../../MessagePage/MessageList';
import HTMLTextDiv from '../../MessagePage/HTMLTextDiv';

interface IChatBoxProps {
    messages: (IMessage | ITempMessage)[],
    isLoading: boolean,
    user: IMessageUser,
    onSubmit: (message: string) => void
    renderMessage?: (messages: IMessage[], user: IMessageUser) => void,
    typingListner?: () => void,
    typingIndicator?: JSX.Element,

}
export interface IMessage {
    "text": string,
    "id": string,
    "datetime": string,
    "readDatetime": string | null,
    "sender": {
        "name": string,
        "uid": string,
        "avatar"?: string,
    }
}
export interface IMessageUser {
    "uid": string
}

export interface ITempMessage {
    // index:number,
    messageText: string,
    tempMessageState: ITempMessageStateType
}
export enum ITempMessageStateType {
    sending = "Sending",
    failed = "Failed to send"

}


ChatBox.defaultProps = {
    messages: [],
    user: {
        "uid": "user1"
    },
    isLoading: false,
    onSubmit: (message: string) => console.log(message)
};


export default function ChatBox(props: IChatBoxProps) {
    //console.log(props)
    const classes = useStyles();
    let { messages, isLoading, user } = props;

    const [_message, setMessage] = useState<string>('');


    const HandleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        props.onSubmit(_message);


        setMessage('');

    }
    const HandleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value)
    }


    const renderTempMessage = (tempMessage: ITempMessage) => {

        let isUser = true;
        return (
            <div

                className='chat-bubble-row'
                style={{
                    flexDirection: !isUser ? 'row-reverse' : 'row'
                }}>
                <div>
                    <div className={
                        `chat-bubble 
                        ${isUser ? 'is-user' : 'is-other'} 
                        ${tempMessage.tempMessageState === ITempMessageStateType.sending ? 'is-sending' : 'is-failed'} 
                    `}
                    >



                        <div
                            className='message'
                            style={{
                                color: isUser ? '#FFF' : '#2D313F',
                                backgroundColor: 'white !important'
                            }}>
                            <HTMLTextDiv messageText={tempMessage.messageText} />
                        </div>

                    </div>
                    {tempMessage.tempMessageState}

                </div >
            </div >
        )
    }

    const renderQuestionMessage = (message: IMessage, user: IMessageUser) => {


        let isUser = user.uid === message.sender.uid;


        return (
            <div
                className='chat-bubble-row'
                style=
                {
                    {
                        flexDirection: !isUser ? 'row-reverse' : 'row',
                    }

                }
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: isUser ? 'flex-start' : 'flex-end'
                    }}
                >
                    <div className='sender-name'>{isUser ? 'Me' : `${message.sender.name}(Coach)`}</div>
                    <div
                        className={classes.datetime}>
                        {Moment(new Date(message.datetime)).format('YYYY-MM-DD h:mm:ss')}

                    </div>
                    <div className={`chat-bubble ${isUser ? 'is-user' : 'is-other'}`}>

                        <div
                            className='message'
                            style={{
                                color: isUser ? '#FFF' : '#2D313F'
                            }}>
                            <HTMLTextDiv messageText={message.text} />

                        </div>
                    </div>
                </div>
            </div>
        );


    }


    const HandleRenderMessageList = (messages: (IMessage | ITempMessage)[], user: IMessageUser) => {

        return messages.map((message: any, index) => {

            return <div key={index}>
                {
                    message.tempMessageState ?
                        renderTempMessage(message)
                        : renderQuestionMessage(message, user)
                }
            </div>

        })


    }

    const HandleRenderEmptyContent = () => {
        return (
            <div>
                <h2> Have any question about this lesson? </h2>
                <h6>
                    Send your question
                </h6>
            </div>
        )
    }




    return (
        <div className={classes.chatBox}>
            <div className={classes.msgPage}>
                <MessageList
                    isLoading={isLoading}
                    messages={messages}
                    user={user}
                    renderMessage={() => HandleRenderMessageList(messages, user)}
                    EmptyContentTitle={'Have any question about this lesson?'}
                    EmptyContentSubTitle={'Send messages below.'}
                />

            </div>
            <div >
                <form
                    onSubmit={HandleSendMessage}>
                    <div >
                        <textarea
                            className={'form-control'}
                            rows={4}
                            placeholder='Type your questions...'
                            value={_message}
                            onChange={HandleChangeMessage}
                            required
                        />
                    </div >
                    <button style={{ width: "100%" }} className={'myThemeContainedButton'}>Submit</button>
                </form>
            </div>
        </div>
    );
}


const useStyles = makeStyles({
    chatBox: {
        position: 'relative',
        letterSpacing: '0.5px',
        padding: '0 !important',
        boxSizing: 'border-box',

        '& ': {
            backgroundColor: '#333232 !important'
        }
    },

    msgPage: {
        display: 'flex',
        flexDirection: 'column',
        height: '584px',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '2px',
    },
    formControl: {
        border: 'none !important',
        backgroundColor: '#555 !important',
        resize: 'none',
        '&.focused': {

            borderColor: 'none !important',
            boxShadow: 'none !important',

            borderRadius: '20px'
        }

    },

    datetime: {
        fontSize: '7px',
        opacity: '0.7'
    }
})