import React from 'react'

export const HTMLTextOneLineDiv = (props: { messageText: string,style?:React.CSSProperties }) => {
    const { messageText,style } = props;

    const renderLatestMessage = () => {
        const divElement = document.createElement('div');
        divElement.innerHTML = messageText;
        const innerText = divElement.innerText;
        return <div style={style}>{innerText}</div>

    }


    return renderLatestMessage();
}
