import React, { useState,  useCallback } from "react";
import { Dialog, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    title: {
        textAlign: "center",

        '& h2': {
            fontWeight: 700,
            fontSize: "2em",
        },
    },
});

interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    fullWidth?: boolean,
    maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false,
    disableBackdropClick?:boolean
    children: JSX.Element
}

export default function SimpleDialog({ onClose, open, title, children, maxWidth, fullWidth,disableBackdropClick }: SimpleDialogProps) {
    const classes = useStyles();


    const handleClose = () => {
        onClose();
    };


    return (
        <Dialog disableEnforceFocus  disableBackdropClick={disableBackdropClick} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={maxWidth ?? false} fullWidth={fullWidth}>
            {title && <DialogTitle className={classes.title} id="simple-dialog-title">{title}</DialogTitle>}
            {children}
        </Dialog>
    );
}



interface IUseSimpleDialog {
    maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false,
    fullWidth?: boolean,
    defaultContent?: JSX.Element,
    title?: string,
    disableBackdropClick?:boolean
}
export function useDynamicContentDialog({ maxWidth, fullWidth, defaultContent, title,disableBackdropClick }: IUseSimpleDialog): {
    open: (contentComponent: JSX.Element) => void,
    close: () => void,
    render: () => JSX.Element
} {

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogChildren, setDialogChildren] = useState<JSX.Element | undefined>(defaultContent);

    const dialogOpenHandler = (contentComponent: JSX.Element) => {
        setDialogOpen(true);
        setDialogChildren(contentComponent)
    }

    const dialogCloseHandle = () => {
        setDialogOpen(false);
    }

    const render = useCallback(
        () => <SimpleDialog disableBackdropClick={disableBackdropClick} open={dialogOpen} onClose={dialogCloseHandle} maxWidth={maxWidth} fullWidth={fullWidth} title={title}>
            {dialogChildren ?? <></>}
        </SimpleDialog>,
        [disableBackdropClick,dialogChildren, dialogOpen, fullWidth, maxWidth, title],
    )


    return { open: dialogOpenHandler, close: dialogCloseHandle, render }
}