import { Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useRadacadAuth } from '../../_services/radacad-auth-react/Core/useRadacadAuth';
import { UserService } from '../../_services/UserService';

export default function UserEmailNotificationStatusSwitch() {

    const { authState: { accessToken } } = useRadacadAuth();
    const [userEmailNotificationOpenStatus, setUserEmailNotificationOpenStatus] = useState<boolean >(true);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmailNotificationOpenStatus(event.target.checked);
        accessToken && UserService.SetUserEmailNotificationSetting(event.target.checked,accessToken);
    };


    useEffect(() => {
        //get status from server


        accessToken && UserService.GetUserEmailNotificationSetting(accessToken).then(
            (data) => {
                setUserEmailNotificationOpenStatus(data||data===null)
            }
        )
    }, [])



    return <><Switch
        checked={userEmailNotificationOpenStatus}
        onChange={handleChange}
        value={userEmailNotificationOpenStatus}
        defaultChecked={userEmailNotificationOpenStatus}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
    />
        <span>
            {
                userEmailNotificationOpenStatus === true ? "On" : "Off"
            }
        </span>
    </>
}
