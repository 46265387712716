import React, { useCallback, useContext } from 'react'
import useResponseData from '../../../_commons/Hooks/useResponseData';
import { useDynamicContentDialog } from '../../../_commons/Utils/React/Components/Dialogs/SimpleDialog';
import { AcademyContext } from '../../../_contexts/UserContext';
import { CertificateTemplateCMSApi } from '../../../_services/CMSServices/CertificateTemplateCMS';
import { ICertificateTemplateModel } from '../../../_services/CMSServices/Models';
import { useCertificateTemplateCMSStyle } from './CertificateTemplateCMS.style';
import { CertificateTemplateCMSForm } from './CertificateTemplateCMSForm';
import { CertificateTemplateCMSTable } from './CertificateTemplateCMSTable';




type DataModelType = ICertificateTemplateModel;
export default function CertificateTemplateCMS() {


    const { loginState: { accessToken } } = useContext(AcademyContext);
    const token = accessToken ?? ""

    const classes = useCertificateTemplateCMSStyle();


    const { data: certificateTemplates, handleRefresh } = useResponseData<ICertificateTemplateModel[]>(
        useCallback(
            () => CertificateTemplateCMSApi.GetAll(token)
            ,
            [token]
        ));


    const useDialog = useDynamicContentDialog({ maxWidth: 'lg', fullWidth: true, disableBackdropClick: true });


    const handleAddCourse = () => {
        useDialog.open(
            <CertificateTemplateCMSForm
                dataObject={undefined}
                submitHandler={(course: DataModelType) => { handleFormSubmit("ADD", course) }}
                cancelHandler={handleFormCancel}
            />
        )
    }
    const handleEditCourse = (dataObject: ICertificateTemplateModel) => {
        useDialog.open(
            <CertificateTemplateCMSForm
                dataObject={dataObject}
                submitHandler={(course: DataModelType) => { handleFormSubmit("EDIT", course) }}
                cancelHandler={handleFormCancel}
            />
        )
    }

    const handleFormSubmit = (action: "ADD" | "EDIT", dataModel: DataModelType) => {

        if (window.confirm("confirm to submit?"))
            CertificateTemplateCMSApi.Update(action, dataModel, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    useDialog.close();
                    handleRefresh();
                }

                else
                    alert("failed");

            })

    }
    const handleFormCancel = () => {
        useDialog.close();
    }
    const handleDeleteCourse = (objectId: number) => {
        if (window.confirm("confirm to submit?"))
            CertificateTemplateCMSApi.Delete({ objectId }, token).then((succ: boolean) => {
                if (succ) {
                    alert("succeed");
                    handleRefresh();
                }

                else
                    alert("failed");

            })
    }

    const handleSync=()=>{
        if (window.confirm("confirm to sync?"))
        CertificateTemplateCMSApi.Sync(token).then((succ: boolean) => {
            if (succ) {
                alert("succeed");
                handleRefresh();
            }

            else
                alert("failed");

        })
    }
    return (
        <div className={`${classes.root}`}>
            <div className={classes.header}>
                <button disabled onClick={() => handleAddCourse()}>Add New Course</button>
                <button  onClick={() => handleSync()}>Sync From Wordpress</button>
            </div>

            <div className={classes.body}>
                <CertificateTemplateCMSTable
                    dataList={certificateTemplates}
                    editButtonHandler={handleEditCourse}
                    deleteButtonHandler={handleDeleteCourse}
                />
            </div>
            <div className={classes.footer}>

            </div>
            {useDialog.render()}
        </div>
    )
}

