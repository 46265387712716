import React, { useEffect, useState } from 'react'
import { ILMSModuleModel } from '../../../_services/CMSServices/Models';
import {  BaseOrderingEditor, IOrderElement } from '../BaseOrderingEditor';
import { LMSModuleApi } from '../../../_services/CMSServices/LMSModuleApi';
import { AdminService } from '../../../_services/AdminService';



interface IProps {
  courseId: number,
  accessToken: string,
  submitHandler: () => void,
  cancelHandler: () => void
}
function OrderEditor(props: IProps) {
  const { courseId, accessToken, submitHandler, cancelHandler } = props;

  const [_pageState, setPageState] = useState<"Loading" | "Waiting" | "Showing" | "Error">("Loading");

  const [_list, setList] = useState<ILMSModuleModel[] | null>(null);
  useEffect(() => {
    setPageState("Loading");

    const FetchModule = async () => {

      LMSModuleApi.GetCourseModules(courseId, accessToken)
        .then(
          async (data) => {
            if (data) {
              data.sort((a: ILMSModuleModel, b: ILMSModuleModel) => a.moduleOrder - b.moduleOrder);
              setList(data);
              setPageState("Showing");
            } else {
              setList(null);
              setPageState("Error");
            }

          }
        ).catch(
          (err) => {
            setList(null);
            setPageState("Error");
          }
        )
    }
    FetchModule();

  }, [courseId, accessToken]);



  const handleSubmit = (resultList: IOrderElement[]) => {
    setPageState("Waiting");
  
    AdminService.UpdateCourseModule(courseId, resultList.map((ele: IOrderElement) => { return { moduleId: ele.id, moduleOrder: ele.order } }), accessToken)
      .then(
        (resp) => {
          if (resp.status === 200) {

            alert('succ');
            submitHandler();

          }
          else alert('failed')
        }
      ).catch(
        () => {
          alert('failed')
        }).finally(() => {
          setPageState("Showing");
        })


  }



  const handleCancel = () => {
    cancelHandler();
  }

  return <BaseOrderingEditor
    pageState={_pageState}
    initialDataList={_list? _list.map((ele: ILMSModuleModel) => { return { id: ele.moduleId, order: ele.moduleOrder, label: ele.moduleTitle } }) : null}
    submitHandler={handleSubmit}
    cancelHandler={handleCancel}
  />
}

export const CourseModuleOrderEditor = OrderEditor;