import React from "react";
import { useRef } from "react";
import { FileStorageService } from "../../_services/FileStorageService";
import { useRadacadAuth } from "../../_services/radacad-auth-react/Core/useRadacadAuth";

export function PrivateResourceLink(props: { href: string, children: JSX.Element, className?: string }) {
    const { href, children, className } = props;

    const { authState: { accessToken } } = useRadacadAuth();

    const aRef = useRef<HTMLAnchorElement>(null);
    const handleDownloadClick = async (url: string, token: string, e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,) => {

        let downloadUrl = await FileStorageService.getTemporayDownloadUrl(url, token);

        if (downloadUrl !== null) {
            aRef!.current!.href = downloadUrl;
            aRef!.current?.click();
        } else {
            //handle error
            alert("fetch resource error")

        }
    }

    return accessToken ?
        <>
            {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid 
                <a
                    className={className}
                    hidden
                    ref={aRef}
                    //  target="_blank"
                    //  rel="noopener noreferrer" 
                    download
                >
                    Download
                </a>
            }

            {
                React.cloneElement(
                    children,
                    {
                        onClick: (e: any) => handleDownloadClick(href, accessToken, e)
                    }
                )
            }

        </>
        :
        <></>
}