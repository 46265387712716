import React, { useContext, useState, useEffect, useCallback, useLayoutEffect, useMemo } from 'react'

import { Link, Redirect, useHistory, useParams } from 'react-router-dom';

import { AcademyContext } from '../../../_contexts/UserContext';

import { LessonService } from '../../../_services/radacad-academy-lms/LessonService';
import NotFoundPage from '../../ErrorPage/NotFoundPage';

import { Button, Grid } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FullScreenLoadingProgress from '../../_commons/FullScreenLoadingProgressComponent/FullScreenLoadingProgress';
import LessonVideoFrame from './LessonVideoFrame';
import LessonPageTabs from './LessonPageTabs';
import { JwtHelper } from '../../../_services/radacad-auth-react/Helpers/JwtHelper'


export default function LessonPage() {



    let history = useHistory();
    let loginContext = useContext(AcademyContext);
    const params = useParams();


    const [_pageState, setPageState] = useState(0);//0:loading, -1: error, 1: success
    const [_lessonDetail, setLessonDetail] = useState({
        courseId: 0,
        lessonTitle: "",
        moduleId: 0,
        isPreview: false,
        preLesson: null,
        nextLesson: null,
        ownLesson: false
    });

    useLayoutEffect(() => {
        setPageState(0);
    }, [params.LessonId])

    useEffect(() => {
        if (_pageState === 0) {
            if (params.LessonId !== null) {
                console.log("start lesson ap")
                LessonService.RequestLessonPublicDetail(
                    loginContext.loginState.accessToken,
                    params.LessonId,
                    (resp) => {
                        if (resp.status === 200) {

                            resp.json().then(
                                (data) => {
                                    setLessonDetail({ lessonId: params.LessonId, ...data });
                                    setPageState(1);
                                }

                            ).catch(
                                (e) => {
                                    setPageState(-1);
                                }
                            )
                        }
                        else {
                            setPageState(-1);

                        }
                    },
                    (error) => {
                        setPageState(-1);
                    });
            }
        }
    }, [_pageState, params.LessonId, loginContext.loginState.accessToken])




    const ChangeLessonFromLesson = useCallback(
        (lessonId) => {
            history.push(`/Lesson/${lessonId}`)
        },
        [history],
    )

    const renderVideoFrame = useMemo(
        () => <LessonVideoFrame
            lessonHeader={
                {
                    courseId: _lessonDetail.courseId,
                    lessonId: _lessonDetail.lessonId,
                    moduleId: _lessonDetail.moduleId,
                    lessonTitle: _lessonDetail.lessonTitle,
                    isPreview: _lessonDetail.isPreview
                }
            }
            nextLessonNavigationHandler={
                () => {
                    if (_lessonDetail.nextLesson !== null)
                        ChangeLessonFromLesson(_lessonDetail.nextLesson.lessonId)
                }
            }
        >
        </LessonVideoFrame>,
        [ChangeLessonFromLesson, _lessonDetail],
    )


    const RenderAdjLessonButton = () => {

        return <Grid container spacing={2} justify="center" style={{ marginTop: '5px' }}>
            <Grid item xs={12} sm={6}>
                {
                    _lessonDetail.preLesson !== null &&
                    (
                        <Link to={`/Lesson/${_lessonDetail.preLesson.lessonId}`}>
                            <Button
                                variant='outlined'
                                color='primary'
                                className={"myThemeButton"}
                                style={{ textTransform: 'none', width: '100%', height: '100%' }}
                                startIcon={<NavigateBeforeIcon></NavigateBeforeIcon>}

                            >
                                {_lessonDetail.preLesson.lessonTitle}
                            </Button>
                        </Link>
                    )
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                {
                    _lessonDetail.nextLesson !== null
                    &&
                    (
                        <Link to={`/Lesson/${_lessonDetail.nextLesson.lessonId}`}>
                            <Button
                                variant='outlined'
                                color='primary'
                                className={"myThemeButton"}
                                style={{ textTransform: 'none', width: '100%', height: '100%' }}
                                endIcon={<NavigateNextIcon></NavigateNextIcon>}

                            >
                                {_lessonDetail.nextLesson.lessonTitle}
                            </Button>
                        </Link>
                    )
                }
            </Grid>
        </Grid>
    }


    return params.LessonId === null ? <Redirect to="/" push />
        : _pageState === -1 ? <NotFoundPage></NotFoundPage>
            : _pageState === 0 ? <FullScreenLoadingProgress></FullScreenLoadingProgress>
                : <Grid container spacing={2}>
                    <Grid item xs={12} md={true}>

                        <Grid container spacing={2}>

                            <Grid item>
                                <Link to={`/Course/${_lessonDetail.courseId}/Modules`}>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        className='myThemeButton'
                                    >
                                        Back to Lesson List
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item>
                                <h2>{_lessonDetail.lessonTitle}</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Grid item xs={12}>
                                        {renderVideoFrame}
                                    </Grid>
                                    <Grid item xs={12}>{RenderAdjLessonButton()}</Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    {loginContext.loginState.isLoggedIn && _lessonDetail.ownLesson
                        && <Grid item xs={12} md={'auto'} style={{ minWidth: '22.5%' }}>
                            <LessonPageTabs
                                userId={JwtHelper.GetUserId(loginContext.loginState.accessToken)}
                                lessonInfo={{ courseId: _lessonDetail.courseId, lessonId: _lessonDetail.lessonId }}
                            ></LessonPageTabs>
                        </Grid>}
                </Grid>


}
