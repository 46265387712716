import React, { useState } from "react";
import { ILMSModuleModel } from "../../../_services/CMSServices/Models";
import HTMLEditor from "../../_commons/Editor/HTMLEditor";
import { ConfirmFormDiv } from "../../_commons/ConfirmFormDiv";
import { useLMSModuleFormStyle } from "./LMSModuleForm.style";
import { PrivateResourceLink } from "../../_commons/PrivateResourceLink";
import { LMSMaterialUploadButton } from "../../_commons/Button/AcademyUploadButton";

export function LMSModuleForm(
    props: {
        courseId: number
        module: ILMSModuleModel | undefined,
        submitHandler: (course: ILMSModuleModel) => void,
        cancelHandler: () => void
    }
) {
    const { courseId, module, submitHandler, cancelHandler } = props;
    const classes = useLMSModuleFormStyle();

    const [_moduleTitle, setModuleTitle] = useState<string>(module?.moduleTitle ?? "");
    const [_description, setDescription] = useState<string>(module?.description ?? "");
    const [_moduleMaterialLink, setModuleMaterialLink] = useState<string>(module?.moduleMaterialLink ?? "");


    const handleOnChange = (e: any, setState: (state: any) => void) => {
        setState(e.target.value);
    }

    const handleSubmit = () => {

        submitHandler({
            moduleId: module?.moduleId ?? 0,
            moduleOrder: module?.moduleOrder ?? 0,
            courseId: courseId,
            moduleTitle: _moduleTitle,
            description: _description,
            moduleMaterialLink: _moduleMaterialLink === "" ? null : _moduleMaterialLink
        })

    }
    const handleCancel = () => {
        cancelHandler()
    }


    return <ConfirmFormDiv
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
    >
        <>
            <div className={classes.fullWidthInputDiv}>
                <label> Module Title</label>
                <input type="text" value={_moduleTitle} onChange={(e: any) => { handleOnChange(e, setModuleTitle) }}></input>
            </div>
            <div>
                <label> Module Description </label>
                <HTMLEditor
                    defaultHtmlStr={_description}
                    htmlStrSetter={setDescription}
                />
            </div>

            <div className={classes.fullWidthInputDiv}>
                <label> Module Material Download Link </label>
                <input type="text" value={_moduleMaterialLink} onChange={(e: any) => { handleOnChange(e, setModuleMaterialLink) }}></input>
                <div className={classes.uploadContainer}>
                    {
                        <LMSMaterialUploadButton
                            label={"Upload Material File"}
                            succ={(resp) => setModuleMaterialLink(resp)}
                        />
                    }
                    {
                        _moduleMaterialLink !== ""
                        &&
                        <PrivateResourceLink href={_moduleMaterialLink}><button>Download</button></PrivateResourceLink>
                    }

                </div>
            </div>
        </>
    </ConfirmFormDiv>
}
