
import { HttpHelper } from "../_commons/Helpers/HttpHelper";
const uploadApi = "api/FileStorage/upload"
function getUploadOption(token: string, isPublic?: boolean) {
    return {
        action: uploadApi + (isPublic === true ? "?isPublic=true" : ""),
        headers: HttpHelper.CreateHeaderForAuthorization(token),
        onSuccess: (resp: any, file: any, xhr: any) => {
            alert("Upload succeed!");
        },
        onError:
            (err: any, ret: any, file: any) => {
                alert(err);
            }
    }
}


export interface IBlobUploadOption {
    clientUrl: string,
    containerName: string,
    blobName: string,
    sasToken: string
}
function getBlobUploadOption(fileName: string, token: string, accessType?: "Public" | "Private"): Promise<IBlobUploadOption | null> {
    // const api = `api/FileStorage/uploadOption?${accessType ? ` fileName=123.png` : ""}`;
    //  const api = `api/FileStorage/uploadOption?fileName=${fileName} & accessType=1`;
    const api = `api/FileStorage/uploadOption/${accessType==="Public"?"Public":"Private"}?fileName=${fileName}`;
    return HttpHelper.Get(api, token).then(
        async (resp) => {
            if (resp.status === 200) {
                let result: IBlobUploadOption = await resp.json();
                return result;
            } else {
                return null;
            }
        }
    ).catch(
        (e) => {
            return null;
        }
    )
}

const sasApi = "api/FileStorage/temporyPublicUrl"
function getTemporayDownloadUrl(url: string, token: string): Promise<string | null> {
    return HttpHelper.Get(`${sasApi}?url=${url}`, token).then(
        async (resp) => {
            if (resp.status === 200) {
                let result = await resp.text();
                return result ?? null;
            } else {
                return null;
            }
        }
    ).catch(
        (e) => {
            return null;
        }
    )
}

export const FileStorageService = {
    getUploadOption,
    getBlobUploadOption,
    getTemporayDownloadUrl
}
