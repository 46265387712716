import { HttpHelper } from '../../_commons/Helpers/HttpHelper';
import { ILMSLessonModel, ILMSLessonOrderViewMode } from './Models';

export type IGetLessonResponseData = ILMSLessonModel[];
const Get = (courseId: number | undefined, moduleId: number | undefined, token: string): Promise<IGetLessonResponseData | null> => {


    return HttpHelper.Get(`/api/LMS/Lesson?courseId=${courseId ?? ""}&&moduleId=${moduleId ?? ""}`, token).then(async (resp) => {
        if (resp.status === 200) {
            let data: IGetLessonResponseData = await resp.json();
            return data;
        } else {
            return null;
        }

    })
}


const Update = (action: "ADD" | "EDIT", lesson: ILMSLessonModel, token: string): Promise<boolean> => {
    if (action === "ADD")
        return HttpHelper.PostJSON('api/LMS/Lesson/Add', lesson, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
    else
        return HttpHelper.PostJSON('api/LMS/Lesson/Edit', lesson, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
}
interface IDeleteLMSLessonRequestData {
    lessonId: number
}
const Delete = (reqData: IDeleteLMSLessonRequestData, token: string): Promise<boolean> => {
    return HttpHelper.PostJSON('api/LMS/Lesson/Delete', reqData, token).then(async (resp) => {
        if (resp.status === 200)
            return true;
        else
            return false;
    })
}


export interface IUpdateLessonOrderReqModel {
    parentId: number|string,
    lessonOrders: ILMSLessonOrderViewMode[]
}
const UpdateOrder = (action: | "COURSE_LESSON" | "MODULE_LESSON", lessonOrder: IUpdateLessonOrderReqModel, token: string): Promise<boolean> => {

    let api = 'api/LMS/Lesson/Edit/';


    if (action === "COURSE_LESSON") {
        api += "CourseLessonOrder"

    }
    else if (action === "MODULE_LESSON") {
        api += "ModuleLessonOrder"
    } 

    return HttpHelper.PostJSON(api, lessonOrder, token).then(async (resp) => {
        if (resp.status === 200)
            return true;
        else
            return false;
    })
}


export const LMSLessonApi = {
    Get,
    Update,
    UpdateOrder,
    Delete
}