import { HttpHelper } from '../../_commons/Helpers/HttpHelper';
import { ILMSCourseModel } from './Models';

type IGetAllLMSCourseResponseData = ILMSCourseModel[];
const GetAllLMSCourse = (token: string): Promise<IGetAllLMSCourseResponseData | null> => {
    return HttpHelper.Get('/api/LMS/Course/All', token).then(async (resp) => {
        if (resp.status === 200) {
            let data: IGetAllLMSCourseResponseData = await resp.json();
            return data;
        } else {
            return null;
        }

    })
}


const UpdateLMSCourse = (action: "ADD" | "EDIT", course: ILMSCourseModel, token: string): Promise<boolean> => {
    if (action === "ADD")
        return HttpHelper.PostJSON('api/LMS/Course/Add', course, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
    else
        return HttpHelper.PostJSON('api/LMS/Course/Edit', course, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
}

interface IDeleteLMSCourseRequestData {
    courseId: number
}
const DeleteLMSCourse = (reqData: IDeleteLMSCourseRequestData, token: string): Promise<boolean> => {
    return HttpHelper.PostJSON('api/LMS/Course/Delete', reqData, token).then(async (resp) => {
        if (resp.status === 200)
            return true;
        else
            return false;
    })
}



export const LMSCourseApi = {
    GetAllLMSCourse,
    UpdateLMSCourse,
    DeleteLMSCourse
    
}