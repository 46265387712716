
//public
const RequestGetAuthor = async (id:string, dataHandler:(data:any)=>void) => {
    let headers = new Headers();
    const options = {
        method: 'GET',
        headers: headers
    }

    await fetch(`api/Author/${id}`, options)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('getAuthorDetail error');
            //console.log(e);
        });
};
//public
const RequestGetAuthorList = async ( dataHandler:(data:any)=>void) => {
    let headers = new Headers();
    const options = {
        method: 'GET',
        headers: headers
    }

    await fetch(`api/Author/AuthorList`, options)
        .then((response) => response.json())
        .then((data) => {
            dataHandler(data);
        })
        .catch((e) => {
            //console.log('getAuthorDetail error');
            //console.log(e);
        });
};

export const AuthorService = {
    //public
    RequestGetAuthor,
    RequestGetAuthorList
}