const FacebookLogin= (handler) => {
    window.FB.login((response) => {
        handler(response);
    }
        , { scope: 'public_profile,email' });
}

const LoginSuccess= (response) => {
    if (response?.status === 'connected') {
        return true;
    }
    return false;
}

const GetAccessToken =  (response) => {
    return response?.authResponse?.accessToken;
}


const FacebookLogout = (handler) => {
    window.FB.logout(function (response) {
        handler(response)
    });
}
const CheckLoginState= (handler) => {               // Called when a person is finished with the Login Button.
    window.FB.getLoginStatus(function (response) {   // See the onlogin handler
        handler(response);

    });
}


export const FacebookService = {

    FacebookLogin,
    LoginSuccess,
    GetAccessToken,
    FacebookLogout,
    CheckLoginState

}