import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@material-ui/core';
import './CourseScrollContainer.css';


export default function CourseScrollContainer(props) {
    //console.log(props);

    const _navRef = useRef(null);
    const [_showPrev, setShowPrev] = useState(true);
    const [_showNext, setShowNext] = useState(true);

    const [_clientWidth, setClientWidth] = useState(0);
    const [_scrollWidth, setScrollWidth] = useState(0);
    const [_scrollLeft, setScrollLeft] = useState(0);



    const handleNav = (direction) => {

        if (direction === 'left') {
            _navRef.current.scrollLeft -= _navRef.current.clientWidth;
            setScrollLeft(Math.max(_scrollLeft - _navRef.current.clientWidth, 0));

        }
        else {
            _navRef.current.scrollLeft += _navRef.current.clientWidth;
            setScrollLeft(Math.min(_scrollLeft + _navRef.current.clientWidth, _scrollWidth - _navRef.current.clientWidth));

        }

    }
    useEffect(() => {
        if (_navRef) {
            setClientWidth(_navRef.current.clientWidth);
            setScrollWidth(_navRef.current.scrollWidth);
            setScrollLeft(_navRef.current.scrollLeft);
        }

      
        // Debounce
        function debounce(func, timeSetting) {
            var time = timeSetting || 100; // 100 by default if no param
            var timer;
            return function (event) {
                if (timer) clearTimeout(timer);
                timer = setTimeout(func, time, event);
            };
        }

        function handleResize() {
            // Set Client width to state
            if(_navRef?.current)
                setClientWidth(_navRef.current.clientWidth);
        }

        let debounceResizeHandler = debounce(handleResize,250);

        // Add event listener
        // window.addEventListener("resize", debounceResizeHandler);
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        // handleResize();

        // Remove event listener on cleanup
        // return () => window.removeEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", debounceResizeHandler);
    }, [])
    useEffect(

        () => {
            /*
            console.log("clientWidth "+_navRef.current.clientWidth);
            console.log("scrollWidth "+_navRef.current.scrollWidth);
            console.log("offsetWidth "+_navRef.current.offsetWidth);
            console.log("scrollLeft "+_navRef.current.scrollLeft); 
            console.log(_scrollWidth);
            console.log(_scrollLeft);
            console.log(_clientWidth);
            */

            if (_scrollLeft === 0)
                setShowPrev(false);
            else
                setShowPrev(true);

            if (_scrollWidth - _scrollLeft > _clientWidth)
                setShowNext(true);
            else
                setShowNext(false);

        }, [_scrollLeft, _scrollWidth, _clientWidth])



    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap="nowrap"
        >
            {
                _showPrev ?
                    <Grid item >
                        <div onClick={() => handleNav('left')} className={"scrollButton"}>&#8249;</div>
                    </Grid>
                    :
                    <Grid item >
                        <div style={{ opacity: 0 }} className={"scrollButton"}>&#8249;</div>
                    </Grid>

            }

            <div  className="CardListContainer" ref={_navRef}  >
                {
                    props.children
                }
            </div>

            {
                _showNext &&
                <Grid item>
                    <div onClick={() => handleNav('right')} className={" scrollButton"}>&#8250;</div>
                </Grid>
            }

        </Grid >
    )
}
