import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useState } from "react";
import { LMSMaterialLinkType } from "../../../_services/Common/Types";
import { CourseService } from "../../../_services/radacad-academy-lms/CourseService";
import { LessonService } from "../../../_services/radacad-academy-lms/LessonService";
import { ModuleService } from "../../../_services/radacad-academy-lms/ModuleService";
import { useRadacadAuth } from '../../../_services/radacad-auth-react/Core/useRadacadAuth';
import { LoadingAnimation } from "../../_commons/LoadingAnimation";
import { LMSMaterialDownloadButton } from "./LMSMaterialDownloadButton";
import { useMaterialDownloadDivStyles } from "./MaterialDownloadDiv.style";

export default function MaterialDownloadDiv(props: {
    courseId: string | number,
    moduleId: string | number | null,
    lessonId: string | number
}): JSX.Element {
    const { courseId, moduleId, lessonId } = props;
    const { authState: { accessToken } } = useRadacadAuth();;
    const classes = useMaterialDownloadDivStyles();
    const [_courseMaterialLink, setCourseMaterialLink] = useState<LMSMaterialLinkType | undefined>(undefined);
    const [_moduleMaterialLink, setModuleMaterialLink] = useState<LMSMaterialLinkType | undefined>(undefined);
    const [_lessonMaterialLink, setLessonMaterialLink] = useState<LMSMaterialLinkType | undefined>(undefined);


    //fetch course material links
    useEffect(() => {
        function fetchCourseMaterialLink() {
            CourseService.GetMaterialLink(courseId, accessToken).then((value: LMSMaterialLinkType) => {
                setCourseMaterialLink(value)
            })
        }
        fetchCourseMaterialLink();

    }, [accessToken, courseId])
    //fetch module material links
    useEffect(() => {
        function fetchModuleMaterialLink() {
            if (moduleId)
                ModuleService.GetMaterialLink(moduleId, accessToken).then((value: LMSMaterialLinkType) => {
                    setModuleMaterialLink(value)
                })
            else
                setModuleMaterialLink("NOT_FOUND")
        }
        fetchModuleMaterialLink();
    }, [accessToken, moduleId])
    //fetch lesson material links
    useEffect(() => {
        function fetchLessonMaterialLink() {
            LessonService.GetMaterialLink(lessonId, accessToken).then((value: LMSMaterialLinkType) => {
                setLessonMaterialLink(value)
            })
        }
        fetchLessonMaterialLink();
    }, [accessToken, lessonId])

    return !accessToken ? <></>
        : <div className={classes.root}>
            {
                _courseMaterialLink === undefined ? <LoadingAnimation></LoadingAnimation>
                    :
                    <div className={classes.downloadBtnWrapper}>
                        <LMSMaterialDownloadButton
                            lmsMaterialLink={_courseMaterialLink}
                            className={classes.courseMaterialBtn}
                        >
                            Course Material
                            <GetAppIcon fontSize={"small"} />
                        </LMSMaterialDownloadButton>
                    </div>
            }
            {
                _moduleMaterialLink === undefined ? <LoadingAnimation></LoadingAnimation>
                    :
                    <div className={classes.downloadBtnWrapper}>
                        <LMSMaterialDownloadButton
                            lmsMaterialLink={_moduleMaterialLink}
                            className={classes.moduleMaterialBtn}
                        >
                            Module Material
                            <GetAppIcon fontSize={"small"} />
                        </LMSMaterialDownloadButton>
                    </div>
            }
            {
                _lessonMaterialLink === undefined ? <LoadingAnimation></LoadingAnimation>
                    :
                    <div className={classes.downloadBtnWrapper}>
                        <LMSMaterialDownloadButton
                            lmsMaterialLink={_lessonMaterialLink}
                            className={classes.lessonMaterialBtn}
                        >
                            Lesson Material
                            <GetAppIcon fontSize={"small"} />
                        </LMSMaterialDownloadButton>
                    </div>
            }
        </div>
}
