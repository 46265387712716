import React, { useState, useEffect } from 'react'


import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link,Divider } from '@material-ui/core';


import { CourseService } from '../../../_services/radacad-academy-lms/CourseService';
import { LearningPathService } from '../../../_services/radacad-academy-lms/LearningPathService';
import LearningPathEditor from './CourseEditors/LearningPathEditor';
import PrerequisiteCourseEditor from './CourseEditors/PrerequisiteCourseEditor';
import CourseModuleEditor from './CourseEditors/CourseModuleEditor';
import NextCourseEditor from './CourseEditors/NextCourseEditor';


interface ICourse {
    courseId: number,
    courseTitle: string,

}
interface ILearningPath {
    learningPathId: number,
    persona: string
}

interface IEditOption {
    optionNumber: number,
    optionTitle: string
}
export default function CourseManagement() {


    const [_currentCourse, setCurrentCourse] = useState<ICourse | undefined>();
    useEffect(() => {
        if (_currentCourse) {
            setCurrentEditOption(null);
        }
    }, [_currentCourse])


    const [_courseList, setCourseList] = useState<ICourse[] | undefined>();//init as undefined 
    const [_learningPathList, setLearningPathList] = useState<ILearningPath[] | undefined>();// init as undefined
    const [_currentEditOption, setCurrentEditOption] = useState<IEditOption | null>(null);


    //init after first render
    useEffect(() => {
        FetchCourseList();
        FetchLearningPathList();
    }, [])

    //
    const FetchCourseList = () => {
        CourseService.getAllCoursesForSearch(
            (data: ICourse[]) => {
                //console.log(data);
                setCourseList(data);
            },
            (err) => { },

            () => {

            }
        )
    }
    const FetchLearningPathList = () => {

        LearningPathService.GetAllLearningPaths()
            .then(
                async (resp) => {
                    if (resp.status === 200) {

                        let data: ILearningPath[] = await resp.json();
                        //console.log(data);
                        setLearningPathList(data);
                    }
                }
            )

    }

    const HandleCourseSelect = (course: ICourse) => {
        setCurrentCourse(course);
    }


    const RenderCourseList = (searchTitle: string) => {

        return _courseList &&
            <ul className={classes.leftCourseList}>
                {
                    _courseList
                        .filter(
                            (course: ICourse) => course.courseTitle.toLowerCase().includes(searchTitle.toLowerCase())
                        )
                        .map((course: ICourse, index: number) => {
                            return (
                                <li key={index}>
                                    <Link onClick={() => { HandleCourseSelect(course) }}>
                                        {course.courseTitle}
                                    </Link>
                                </li>
                            )
                        })
                }
            </ul >
    }

    const [_searchKeyWord, setSearchKeyWord] = useState<string>('');
    const HandleSearchChange = (keyword: string) => {
        setSearchKeyWord(keyword);
    }



    const classes = useStyles();
    return (
        <div className={classes.root}>

            <Grid container spacing={2}>

                <Grid item xs={12} sm={12} md={'auto'}>
                    Search Course:
                    <input
                        className={classes.leftSearchInput}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { HandleSearchChange(event.currentTarget.value) }}
                    />
                    {RenderCourseList(_searchKeyWord)}
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} sm={12} md={true}>
                    {
                        _currentCourse &&
                        <>
                            <h3>{_currentCourse.courseTitle}</h3>
                            <div className={classes.optionButtonDiv}>
                                {_learningPathList &&
                                    <button onClick={() => setCurrentEditOption(editLearningPathOption)}>
                                        {editLearningPathOption.optionTitle}
                                    </button>}
                                {_courseList &&
                                    <button onClick={() => setCurrentEditOption(editPrerequesiteCourseOption)}>
                                        {editPrerequesiteCourseOption.optionTitle}
                                    </button>}

                                {_courseList &&
                                    <button onClick={() => setCurrentEditOption(editNextCourseOption)}>
                                        {editNextCourseOption.optionTitle}
                                    </button>}

                                {/* {_courseList && */}
                                <button onClick={() => setCurrentEditOption(editCourseModuleOption)}>
                                    {editCourseModuleOption.optionTitle}
                                </button>
                                {/* } */}
                            </div>

                            {
                                _currentEditOption &&
                                <>
                                    <h4>{_currentEditOption.optionTitle}</h4>
                                    {

                                        _currentEditOption.optionNumber === 1 ?
                                            <LearningPathEditor
                                                currentCourse={_currentCourse}
                                                learningPathList={_learningPathList!}
                                            /> :
                                            _currentEditOption.optionNumber === 2 ?
                                                <PrerequisiteCourseEditor
                                                    currentCourse={_currentCourse}
                                                    courseList={_courseList!}
                                                /> :
                                                _currentEditOption.optionNumber === 3 ?
                                                    <NextCourseEditor
                                                        currentCourse={_currentCourse}
                                                        courseList={_courseList!}
                                                    /> :
                                                    _currentEditOption.optionNumber === 4 ?
                                                        <CourseModuleEditor
                                                            currentCourse={_currentCourse}
                                                        /> :
                                                        <></>

                                    }

                                </>


                            }
                        </>
                    }
                </Grid>
            </Grid>

        </div>
    )
}

const useStyles = makeStyles(
    {

        root: {

        },
        leftSearchInput: {
            width: '100%'
        },
        leftCourseList: {
            maxWidth: '400px',
            '& li:hover': {
                cursor: 'pointer'
            }
        },
        optionButtonDiv: {
            '&>button': {
                backgroundColor: '#4CAF50',
                border: 'none',
                color: 'white',
                padding: '15px 32px',
                textAlign: 'center',
                textDecoration: 'none',
                display: 'inline - block',
                fontSize: '16px',
                margin: '4px 2px',
                cursor: 'pointer'
            }

        }

    }
)
const editLearningPathOption = {
    optionNumber: 1,
    optionTitle: "Edit Learning Path"
}
const editPrerequesiteCourseOption = {
    optionNumber: 2,
    optionTitle: "Edit Prerequesite Courses"
}

const editNextCourseOption = {
    optionNumber: 3,
    optionTitle: "Edit Courses after This Course"
}

const editCourseModuleOption = {
    optionNumber: 4,
    optionTitle: "Edit Course Modules"
}
const editOptions={
    editLearningPathOption,
    editPrerequesiteCourseOption,
    editNextCourseOption,
    editCourseModuleOption
}
