import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles({
  root: {
    width:300,
  
  },
  media: {
    height: 250,
  },
  title:{
    height:50
  }
});

export default function LearningPathCard(props) {
  const classes = useStyles();

  const [learningPath, setLearningPath] = useState({
    learningPathId: props.learningPath.learningPathId,
    persona: props.learningPath.persona ? props.learningPath.persona : "",
    imageURL: props.learningPath.imageURL ? props.learningPath.imageURL : "",
  })

  return (
    <Card className={classes.root}>
      <HashLink smooth to={`/LearningPath#${learningPath.learningPathId}`} >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={learningPath.imageURL}
            title={learningPath.persona}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" align="center" className={classes.title}>
              {learningPath.persona}
            </Typography>
          </CardContent>
        </CardActionArea>
      </HashLink>
    </Card>
  );
}
