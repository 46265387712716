const Get = (APIURL: string, token: string | null = null): Promise<Response> => {
    if (process.env.NODE_ENV !== "production")
        console.debug(`${APIURL} ${token}`)

    let headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    const options = {
        method: 'GET',
        headers: headers
    }
    return fetch(APIURL, options);
}

const JsonResponse = <T>(resp: Promise<Response>): Promise<T | null> => {
    return resp.then(
        async resp => {
            let data: T = await resp.json();
            return data;
        }
    ).catch(
        e => null
    )
}

const PostJSON = <T>(APIURL: string, bodyData: T, token: string | null = null): Promise<Response> => {

    if (process.env.NODE_ENV !== "production")
        console.debug(`${APIURL} ${token}`)

    let headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', '*/*');

    const options = bodyData ?
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(bodyData)
        } :
        {
            method: 'POST',
            headers: headers

        }
    return fetch(APIURL, options);

}

const CreateHeaderForAuthorization = (token: string): Record<string, string> => {
    return { 'Authorization': `Bearer ${token}` };
}



export const HttpHelper = {
    Get: Get,
    PostJSON: PostJSON,
    JsonResponse,
    CreateHeaderForAuthorization
}