import React, { useState } from 'react'
import { HubConnection } from "@microsoft/signalr";
import useLiveNotificationSender from '../../../_services/radacad-academy-signalRHub/react-hooks/useLiveNotificationSender'
import HTMLEditor from '../../_commons/Editor/HTMLEditor'
import { withHubConnection } from '../../../_services/radacad-academy-signalRHub/react-hocs/withHubConnection';

export default withHubConnection(withLiveNotificationSender());


function withLiveNotificationSender() {

    return function WrappedComponent(props: { connection: HubConnection }) {
        const {connection} =props;
        const {send} = useLiveNotificationSender(connection);

        return <NotificationManagement
            submitHandler={send}
        />  

    }
}

function NotificationManagement(props: { submitHandler: (message: string) => void }) {
    const { submitHandler } = props;
    const [text, setText] = useState<string>("");
    return (<div>

        <HTMLEditor
            defaultHtmlStr={text}
            htmlStrSetter={setText}
        />
        <button onClick={() => { submitHandler(text) }}>Submit </button>

    </div>
    )
}
