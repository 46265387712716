import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css';
import './custom.css'

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { unregister } from './registerServiceWorker';
import RadacadAuthProvider from './_services/radacad-auth-react/RadacadAuthProvider';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

document.getElementById('body').classList.add("myThemeBody");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <RadacadAuthProvider
      config={{
        allowPasswordLogin: true,
        allowFacebookLogin: true,
        allowLinkedInLogin: true,
        allowGoogleLogin: true
      }}
    >
      <App />
    </RadacadAuthProvider>

  </BrowserRouter>,
  rootElement);

//registerServiceWorker();
unregister();
