import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';

import { IdentityService } from '../../_services/IdentityService';

import AdminPageTabs from './AdminPageTabs';
import CourseManagement from './CourseManagement/CourseManagement';
import EmailTemplateManagement from './EmailManagement/EmailTemplateManagement';
import LMSCourse from './LMSCourse/LMSCourse';
import LMSLesson from './LMSLesson/LMSLesson';
import LMSModule from './LMSModule/LMSModule';
import CertificateTemplateCMS from './CertificateTemplateCMS/CertificateTemplateCMS';
import MembershipPlanCourseCMS from './MembershipPlanCourse/MembershipPlanCourseCMS';
import UserManagement from './UserManagement/UserManagement';
import { useRadacadAuth } from '../../_services/radacad-auth-react/Core/useRadacadAuth';
import NotificationManagement from './NotificationPanel/NotificationManagement';
import MembershipManagement from './MembershipManangement/MembershipManagement';
import EmailCredentialManagement from './EmailManagement/EmailCredentialManagement';
type IAdminPageStateType = "NOT_ADMIN" | "IS_ADMIN" | "IS_LOADING";
export default function AdminPage() {


    const { authState: { authenticated: userAuthenticated, accessToken } } = useRadacadAuth();

    const [_adminPageState, setAdminPageState] = useState<IAdminPageStateType>("IS_LOADING");


    useEffect(() => {

        setAdminPageState("IS_LOADING");

        IdentityService.CheckIsAdmin(accessToken!)
            .then(
                (resp) => {
                    if (resp.status === 200) {
                        setAdminPageState("IS_ADMIN");
                    } else {
                        setAdminPageState("NOT_ADMIN");
                    }
                }
            ).catch(() => {
                setAdminPageState("NOT_ADMIN");
            })
            .finally(
                () => {
                    document!.getElementById('body')!.classList.remove("myThemeBody");
                }
            )


        return () => {
            document!.getElementById('body')!.classList.add("myThemeBody");
        }
    }, [accessToken])




    return userAuthenticated === false ?
        <Redirect to="/SignIn" push /> :
        _adminPageState === "IS_LOADING" ?
            <div>Loading...</div> :
            _adminPageState === "NOT_ADMIN" ?
                <Redirect to="/SignIn" push /> :
                RenderAdminPage();

}

function RenderAdminPage() {
    return (
        < div>
            <AdminPageTabs>
                {{ label: "LMS Course", element: <LMSCourse /> }}
                {{ label: "LMS Course Lesson", element: <LMSLesson /> }}
                {{ label: "LMS Course Module", element: <LMSModule /> }}
                {{ label: "Configure MembershipPlan Course", element: <MembershipPlanCourseCMS /> }}
                {{ label: "Certificate Template", element: <CertificateTemplateCMS /> }}
                {{ label: "Course", element: <CourseManagement /> }}
                {{ label: "User", element: <UserManagement /> }}
                {{ label: "Membership", element: <MembershipManagement /> }}
                {{ label: "Email Credential", element: <EmailCredentialManagement /> }}
                {{ label: "Email Templates", element: <EmailTemplateManagement /> }}
                {{ label: "Website Notification", element: <NotificationManagement /> }}
           
            </AdminPageTabs>
        </div >
    )
}
