import React, { useEffect, useState } from 'react'
import { CourseService } from '../../../_services/radacad-academy-lms/CourseService';
import { useRadacadAuth } from '../../../_services/radacad-auth-react/Core/useRadacadAuth';
import PurchaseCourseCards from './PurchaseCourseCards'

export default function PurchaseCoursePromptDiv(props: { courseId: number, renderDescription?: () => JSX.Element }) {

    const { courseId, renderDescription } = props;
    const { authState: { authenticated: userAuthenticated, accessToken } } = useRadacadAuth();
    const [_needToPrompt, setNeedToPrompt] = useState<boolean>(false);

    useEffect(() => {
        CourseService.OwnCourse(courseId, accessToken).then(
            (resp) => {

                if (resp.status === 200) {

                    setNeedToPrompt(false)
                } else {

                    setNeedToPrompt(true);
                }
            }

        )

    }, [courseId, accessToken])

    return (userAuthenticated === false || _needToPrompt) &&
        <div>
            {
                renderDescription && renderDescription()

            }
            <PurchaseCourseCards courseId={courseId} />
        </div>
}
