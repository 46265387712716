import { HttpHelper } from '../../_commons/Helpers/HttpHelper';
import { ILMSModuleModel } from './Models';

export type IGetCourseModuleResponseData = ILMSModuleModel[];
const GetCourseModules = (courseId:number, token: string): Promise<IGetCourseModuleResponseData | null> => {
    return HttpHelper.Get(`/api/LMS/Module/${courseId}`, token).then(async (resp) => {
        if (resp.status === 200) {
            let data: IGetCourseModuleResponseData = await resp.json();
            return data;
        } else {
            return null;
        }

    })
}


const Update = (action: "ADD" | "EDIT", course: ILMSModuleModel, token: string): Promise<boolean> => {
    if (action === "ADD")
        return HttpHelper.PostJSON('api/LMS/Module/Add', course, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
    else
        return HttpHelper.PostJSON('api/LMS/Module/Edit', course, token).then(async (resp) => {
            if (resp.status === 200)
                return true;
            else
                return false;
        })
}

interface IDeleteLMSModuleRequestData {
    moduleId: number
}
const Delete = (reqData: IDeleteLMSModuleRequestData, token: string): Promise<boolean> => {
    return HttpHelper.PostJSON('api/LMS/Module/Delete', reqData, token).then(async (resp) => {
        if (resp.status === 200)
            return true;
        else
            return false;
    })
}

export const LMSModuleApi = {
    GetCourseModules,
    Update,
    Delete
}