import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
//@ts-ignore
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import Badge from '@material-ui/core/Badge';


import './NavMenu.css';
import AlertDialog from '../_commons/Dialogs/AlertDialog';
import EmbedDialog from '../_commons/Dialogs/EmbedDialog';

import { AcademyContext } from '../../_contexts/UserContext';
import { RoleType } from '../../_commons/Helpers/RoleType';
import { useRadacadAuth } from '../../_services/radacad-auth-react/Core/useRadacadAuth';
import { JwtHelper } from '../../_services/radacad-auth-react/Helpers/JwtHelper';


const logoURL = process.env.PUBLIC_URL + '/img/logo/RADACAD Logo_White Cut 500.png';

export default function NavMenu() {
  const { authState: { authenticated: userAuthenticated,accessToken:jwtToken }, logout } = useRadacadAuth();


  const [collapsed, setColapsed] = useState<boolean>(true);
  const toggleNavbar = () => {
    setColapsed(!collapsed);
  }

  const academyContext = useContext(AcademyContext);


  const logoutHandler = () => {
    logout();
    academyContext.setUnreadCount(0);
  }


  const [logoutDialogOpen, setLogoutDialogOpen] = useState<boolean>(false);
  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  const handleLogoutDialogConfirm = () => {
    logoutHandler();
    handleLogoutDialogClose();
  };


  const [contactUsDialogOpen, setContactUsDialogOpen] = useState<boolean>(false);
  const handleContactUsDialogOpen = () => {
    setContactUsDialogOpen(true);
  };

  const handleContactUsDialogClose = () => {
    //console.log("test")
    setContactUsDialogOpen(false);
  };

  return (
    <header style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
      <Navbar sticky="top" className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 myThemeNav" style={{ padding: '0' }} light>
        <Container fluid={true}>
          <NavbarBrand tag={Link} to="/"><img src={logoURL} style={{ height: '50px', width: '50px', marginLeft: "5vw" }} alt="Radacad Log"></img></NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse text-light" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} to="/">Home</NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar className={"myThemeDropdown"} >
                <DropdownToggle nav caret>
                  Learning Path
                </DropdownToggle>

                <DropdownMenu right style={{ padding: '2px 0', border: '0', borderRadius: "0", fontSize: '15px', textAlign: 'center' }}>

                  <DropdownItem className={"item"} style={{ padding: '0' }}>
                    <NavLink tag={Link} to="/LearningPath" className={" text"}>Overview</NavLink>
                  </DropdownItem>

                  <DropdownItem className={"item"} style={{ padding: '' }}>
                    <NavLink tag={Link} to="/LearningPathDiagram" className={"text"}>Diagrams</NavLink>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink tag={Link} to="/Coaches">Coaches</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/CourseSearch">Search</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="#" onClick={handleContactUsDialogOpen} >Contact Us</NavLink>
              </NavItem>

              <EmbedDialog open={contactUsDialogOpen}
                layoutState={
                  {
                    CancleButtonText: "Close",
                    CloseHandler: handleContactUsDialogClose
                  }
                }
              >
                <iframe src={`${process.env.PUBLIC_URL}/contact-us.html`} title="Contact Us Page"
                  style={{ width: "700px", height: "350px", maxWidth: "100%", maxHeight: "100%", border: "0" }}
                >
                </iframe>
              </EmbedDialog>

              {userAuthenticated ?
                (
                  <>
                    <UncontrolledDropdown nav inNavbar className={"myThemeDropdown"} >

                      <DropdownToggle nav caret>
                        <Badge badgeContent={academyContext.unreadCount} className={'MuiBadge-NewMessage'} color="secondary">
                          Hi, {JwtHelper.GetUserName(jwtToken)}
                          {JwtHelper.IsRole(jwtToken, RoleType.FreeTrail) && <span>(Free Trail)</span>}
                        </Badge>
                      </DropdownToggle>


                      <DropdownMenu right style={{ padding: '2px 0', border: '0', borderRadius: "0", fontSize: '15px', textAlign: 'center' }}>

                        <DropdownItem className={"item"} style={{ padding: '0' }}>
                          <NavLink tag={Link} to="/UserProfile" className={" text"}>Profile</NavLink>
                        </DropdownItem>

                        <DropdownItem className={"item"} style={{ padding: '' }}>
                          <Badge badgeContent={academyContext.unreadCount} className={'MuiBadge-NewMessage'} color="secondary">
                            <NavLink tag={Link} to="/Messages" className={"text"}>
                              Messages
                            </NavLink>
                          </Badge>
                        </DropdownItem>

                        <DropdownItem divider style={{ margin: '1px 0' }} />

                        <DropdownItem className={"item"} style={{ padding: '0', fontSize: '15px' }}>
                          <div style={{ paddingTop: '10px', paddingBottom: '10px' }} onClick={handleLogoutDialogOpen} className={" text"} >Log out</div>
                          <AlertDialog open={logoutDialogOpen} layoutState={
                            {
                              DialogTitle: "Confirmation",
                              DialogContext: "Do you really want to log out?",
                              CancleButtonText: "Cancel",
                              ConfirmButtonText: "Confirm",
                              ConfirmHandler: handleLogoutDialogConfirm,
                              CloseHandler: handleLogoutDialogClose
                            }
                          }>
                          </AlertDialog>
                        </DropdownItem>

                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>

                ) :
                (
                  <NavItem>
                    <NavLink tag={Link} to="/SignIn">Login</NavLink>
                  </NavItem>
                )
              }

            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header>

  );

}
