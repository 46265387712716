import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress'
import Moment from 'moment';


import { LearnerService } from '../../../_services/LearnerService';
import { VimeoService } from '../../../_services/vimeo-videoplayer/VimeoService';


interface IPlayerProps {
    lessonId: string;
    src: string;
    title: string;
    accessToken: string | null;
    autoStartPosition: number;
    completeLessonHandler: (() => void) | null;
    nextLessonNavigationHandler: (() => void) | null;
}
interface IHistoryRecord {
    videoId: number | null,
    startTime: string | null,
    endTime: string | null,
    startPosition: number | null,
    speedRate: number | null,
    subtitle: boolean | null,
    isPlaying: boolean
}
const dateFormatString = "";
const uploadVideoActionData = (token: string | null, lessonId: number, actionDataset: IHistoryRecord[], latestActionData: IHistoryRecord, autoStartSecond: number) => {

    if (latestActionData.isPlaying) {
        latestActionData.endTime = Moment(Date.now()).format(dateFormatString);
        actionDataset.push(Object.assign({}, latestActionData))
    }



    if (token !== null && actionDataset.length > 0)
        LearnerService.PostVideoActionHistory(token, lessonId, actionDataset, autoStartSecond, Moment(Date.now()).format(dateFormatString),
            (resp) => {

            },
            (err) => {

            })

}
export default function LessonVideoPlayer(props: IPlayerProps) {


    const { accessToken, lessonId, autoStartPosition, nextLessonNavigationHandler, completeLessonHandler } = props;

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    let iFrameRef = useRef(null);


    useEffect(() => {
        let player = VimeoService.GetPlayer(iFrameRef.current);

        VimeoService.PlayerReady(player,
            () => {
                // The player is ready
                //console.log("The player is ready");

                VimeoService.SetCurrentTime(player, autoStartPosition,
                    (seconds: any) => {
                        // `seconds` indicates the actual time that the player seeks to

                        //console.log(`The player is set to ${seconds}`);
                        // VimeoService.PlayerPlay(player, () => {
                        //     // The player is playing
                        //     //console.log("The player is playing");

                        // });
                    })

            });
    }, [autoStartPosition])

    const autoStartSecondNextTime = useRef<number>(autoStartPosition);

    useEffect(
        () => {

            if (accessToken) {

                let player = VimeoService.GetPlayer(iFrameRef.current);
                let playingHistoryNewRecord: IHistoryRecord = {
                    videoId: null,
                    startTime: null,
                    endTime: null,
                    startPosition: null,
                    speedRate: null,
                    subtitle: null,
                    isPlaying: false

                };
                let playingHistoryRecordSet: IHistoryRecord[] = [];

                /**
                 * Capture Vimeo Events
                 * 
                 */
                const CatchHistoryAsync = async () => {

                    playingHistoryNewRecord = {
                        videoId: await VimeoService.GetVideoIdAsync(player) ?? 0,
                        startTime: Moment(Date.now()).format(dateFormatString),
                        endTime: Moment(Date.now()).format(dateFormatString),
                        startPosition: await VimeoService.GetCurrentTimeAsync(player) ?? 0,
                        speedRate: await VimeoService.GetPlaybackRateAsync(player) ?? 1,
                        subtitle: await VimeoService.GetTextTracksActiveStatusAsync(player) ?? false,
                        isPlaying: await VimeoService.GetPauseStateAsync(player) === false

                    }


                    const onPlay = (data: any) => {
                        //update state
                        if (playingHistoryNewRecord.isPlaying === false) {
                            playingHistoryNewRecord.startTime = Moment(Date.now()).format(dateFormatString);
                            playingHistoryNewRecord.startPosition = data.seconds;
                        }

                        playingHistoryNewRecord.isPlaying = true;

                    }
                    VimeoService.AddListenerToPlayer(player, 'play', onPlay);


                    const onPause = (data: any) => {
                        //update state
                        playingHistoryNewRecord.endTime = Moment(Date.now()).format(dateFormatString);


                        //insert new record
                        //console.log("New Record-------->");
                        playingHistoryRecordSet.push(Object.assign({}, playingHistoryNewRecord));
                        //console.log(playingHistoryRecordSet);

                        //update after insert
                        playingHistoryNewRecord.isPlaying = false;

                    }
                    VimeoService.AddListenerToPlayer(player, 'pause', onPause);

                    VimeoService.AddListenerToPlayer(player, 'ended', () => {
                        //console.log('ended');
                        autoStartSecondNextTime.current = 0;
                        if (nextLessonNavigationHandler) {
                            setDialogOpen(true);
                        }

                    })

                    VimeoService.AddListenerToPlayer(player, 'timeupdate', (data: any) => {
                        ////console.log(data);
                        autoStartSecondNextTime.current = data.seconds;
                        if (data.percent > 0.95) {
                            //console.log("complete")
                            if (completeLessonHandler)
                                completeLessonHandler();
                        }
                    })

                    const onSeeked = (data: any) => {
                        //console.log('seeked');
                        //console.log(playingHistoryNewRecord);


                        if (playingHistoryNewRecord.isPlaying) {
                            //update before insert 
                            playingHistoryNewRecord.endTime = Moment(Date.now()).format(dateFormatString);

                            //insert new record
                            //console.log("New Record-------->");
                            if (playingHistoryNewRecord.startTime !== playingHistoryNewRecord.endTime)
                                playingHistoryRecordSet.push(Object.assign({}, playingHistoryNewRecord));
                            //console.log(playingHistoryRecordSet);

                            //update state after insert 
                            playingHistoryNewRecord.startTime = Moment(Date.now()).format(dateFormatString);
                        }

                        //reset state
                        playingHistoryNewRecord.startPosition = data.seconds;
                        autoStartSecondNextTime.current = data.seconds;


                    }
                    VimeoService.AddListenerToPlayer(player, 'seeked', onSeeked);


                    const onSpeedChange = async (data: any) => {
                        //console.log('playbackratechange the video');

                        if (playingHistoryNewRecord.isPlaying) {
                            //update before insert 
                            playingHistoryNewRecord.endTime = Moment(Date.now()).format(dateFormatString);

                            //insert new record
                            //console.log("New Record-------->");
                            playingHistoryRecordSet.push(Object.assign({}, playingHistoryNewRecord));
                            //console.log(playingHistoryRecordSet);

                            //update after insert 
                            playingHistoryNewRecord.startTime = Moment(Date.now()).format(dateFormatString);
                            playingHistoryNewRecord.startPosition = await VimeoService.GetCurrentTimeAsync(player);
                        }

                        playingHistoryNewRecord.speedRate = data.playbackRate;
                    }
                    VimeoService.AddListenerToPlayer(player, 'playbackratechange', onSpeedChange);



                    const onCaptionChange = async (data: any) => {
                        //console.log('texttrackchange the video');
                        //console.log(data);

                        if (playingHistoryNewRecord.isPlaying) {
                            //update before insert 
                            playingHistoryNewRecord.endTime = Moment(Date.now()).format(dateFormatString);

                            //insert new record
                            //console.log("New Record-------->");
                            playingHistoryRecordSet.push(Object.assign({}, playingHistoryNewRecord));
                            //console.log(playingHistoryRecordSet);

                            //update after insert 
                            playingHistoryNewRecord.startTime = Moment(Date.now()).format(dateFormatString);
                            playingHistoryNewRecord.startPosition = await VimeoService.GetCurrentTimeAsync(player);
                        }

                        playingHistoryNewRecord.subtitle = data.label === null ? false : true;
                    }
                    VimeoService.AddListenerToPlayer(player, 'texttrackchange', onCaptionChange);


                }

                CatchHistoryAsync();



                const BeforeunloadHanlder = async (event: any) => {
                    event.preventDefault();
                    //console.log("trigger windows close");
                    await uploadVideoActionData(accessToken, parseInt(lessonId), playingHistoryRecordSet, playingHistoryNewRecord, autoStartSecondNextTime.current);
                    //event.returnValue = "close";
                }
                window.addEventListener("beforeunload", BeforeunloadHanlder);

                return () => {
                    /**
                     * no need to remove listener for Player if ing because player has been dismissed after rendering
                     */
                    //console.debug("exit log and push result")
                    VimeoService.RemoveAllListenerToPlayer(player, 'play');
                    VimeoService.RemoveAllListenerToPlayer(player, 'ended');
                    VimeoService.RemoveAllListenerToPlayer(player, 'pause');
                    VimeoService.RemoveAllListenerToPlayer(player, 'seeked');
                    VimeoService.RemoveAllListenerToPlayer(player, 'timeupdate');
                    VimeoService.RemoveAllListenerToPlayer(player, 'playbackratechange');
                    VimeoService.RemoveAllListenerToPlayer(player, 'texttrackchange');

                    window.removeEventListener("beforeunload", BeforeunloadHanlder);
                    uploadVideoActionData(accessToken, parseInt(lessonId), playingHistoryRecordSet, playingHistoryNewRecord, autoStartSecondNextTime.current);



                }

            }
        }, [accessToken, lessonId, autoStartPosition, completeLessonHandler, nextLessonNavigationHandler]
    )


    return (
        <div style={{ position: 'relative', paddingBottom: 'min(56.25%,75vh)' }}>
            <iframe
                src={props.src}
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%'
                }}
                frameBorder="0"
                //@ts-ignore
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                title={props.title}
                ref={iFrameRef}
                //**these three options have to be opened to let autoplay active according to Google Documentation https://goo.gl/xX8pDD  */
                allow="autoplay; fullscreen"
            >
            </iframe>
            <AlertDialog open={dialogOpen} setOpen={setDialogOpen} continueHandler={props.nextLessonNavigationHandler}></AlertDialog>
 
        </div >
    )
}

function AlertDialog(props: any) {

    const { continueHandler } = props;
    const [count, setCount] = useState(500);
    const handleClose = () => {
        props.setOpen(false);
    };

    useEffect(() => {
        if (props.open) {

            //console.log('add interval');

            let interval = setInterval(() => {
                setCount(count => --count);
            }, 10)

            return () => {
                clearInterval(interval);
                //console.log('clear interval')
                setCount(500);


            }
        }

    }, [props.open])
    useEffect(() => {
        if (count <= 0) {
            if (continueHandler)
                continueHandler();
        }
    }, [count, continueHandler]);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ padding: "20px" }}
            >
                <DialogTitle id="alert-dialog-title">{"Lesson Completed"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <small>Auto start next lesson in {Math.ceil(count / 100)}s</small>
                        <LinearProgress variant="determinate" value={(((500 - count) * 100 / 500))} />
                    </DialogContentText>

                    <Button onClick={props.continueHandler ?? null} color="primary" style={{ width: '100%', backgroundColor: '#91CBF7', textTransform: 'none' }} autoFocus>
                        Continue to next lesson
                    </Button>
                    <Button onClick={handleClose} color="primary" style={{ width: '100%', textTransform: 'none' }}>
                        Cancel
                    </Button>
                </DialogContent>




            </Dialog>
        </div>
    );
}