import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';


import { ChatMessageService, ILessonMessageModel } from '../../../_services/radacad-academy-message/ChatMessageService';
import ChatBox, { IMessage, ITempMessage, ITempMessageStateType } from './ChatBox';
import Moment from 'moment';
import { AcademyContext } from '../../../_contexts/UserContext';



interface ILessonMessageTabProps {
    userId: string,
    lessonInfo: {
        courseId: number,
        lessonId: number
    }

}
interface IErrorState {
    error: boolean,
    errorMessage: string
}

export default function LessonMessageTab(props: ILessonMessageTabProps) {

    let _loginContext = useContext(AcademyContext);

    let lessonId: number = typeof (props.lessonInfo.lessonId) === 'number' ? props.lessonInfo.lessonId : parseInt(props.lessonInfo.lessonId);

    const [_isLoading, setIsLoading] = useState<boolean>(true);
    const [_messageList, setMessageList] = useState<(IMessage | ITempMessage)[]>([]);
    const [_errorState, setErrorState] = useState<IErrorState>({ error: false, errorMessage: "" });



    //init the Message List
    useEffect(() => {

        //currently no need to reset state when re-render 
        ChatMessageService.GetLessonMessageList(_loginContext.loginState.accessToken!, lessonId)
            .then(
                async (resp) => {
                    //console.log(resp);
                    if (resp.status === 200) {


                        let data: ILessonMessageModel[] = await resp.json();

                        //console.log(data);
                        let newMessagelist: IMessage[] = [];
                        data.forEach(
                            (m: ILessonMessageModel) =>
                                newMessagelist.push({
                                    text: m.messageContent,
                                    id: String(m.messageId),
                                    datetime: m.datetime,
                                    readDatetime: m.readDatetime,
                                    sender: {
                                        "name": m.senderName,
                                        "uid": m.senderId + ""

                                    }
                                })
                        )
                        //console.log(newMessagelist)
                        newMessagelist.sort((a, b) => { return (new Date(a.datetime)).getTime() - (new Date(b.datetime)).getTime() })
                        setMessageList(newMessagelist);

                        let currentUserIdNumber: number = parseInt(props.userId);
                        let unreadMessageIds = data.filter(
                            (message: ILessonMessageModel) => {
                                return message.readDatetime === null && message.receiverId === currentUserIdNumber
                            }
                        ).map(
                            (message: ILessonMessageModel): number => {
                                return message.messageId;
                            }
                        )

                        if (unreadMessageIds.length > 0) {
                            ChatMessageService.PostNewReadMessage(
                                _loginContext.loginState.accessToken!,
                                { MessageIds: unreadMessageIds },
                                (resp) => {
                                    if (resp.status === 200) {
                                        _loginContext.setUnreadCount((count: number) => { return Math.max(0, count - unreadMessageIds.length) });
                                    }
                                },
                                (err) => {

                                }
                            )
                        }



                    }

                    else if (resp.status === 401) {
                        setErrorState(
                            {
                                error: true,
                                errorMessage: "This feature will be avaiable when you have an active Academy membership or purchased this course"
                            }
                        )
                    }

                }

            ).catch(
                err => {
                    //console.log(err)
                }
            ).finally(
                (): void => {

                    setIsLoading(false);
                }
            )

    }, [])





    const HandleMessageSubmit = (newMessageString: string): void => {

        let tempSendingMessage: ITempMessage = {
            messageText: newMessageString,
            tempMessageState: ITempMessageStateType.sending
        }
        let tempFailedMessage: ITempMessage = {
            messageText: newMessageString,
            tempMessageState: ITempMessageStateType.failed
        }

        setMessageList((messages): (IMessage | ITempMessage)[] => [...messages, tempSendingMessage]);



        ChatMessageService.PostLessonMessage(
            _loginContext.loginState.accessToken!,
            {
                LessonId: lessonId,
                Datetime: Moment(Date.now()).format(""),
                MessageContent: newMessageString
            }

        ).then(
            (resp) => {
                //console.log(resp);
                return resp.json();
            }

        ).then(
            (data: ILessonMessageModel) => {
                //console.log(data);
                setMessageList(messageList =>
                    [...messageList.filter(m => m !== tempSendingMessage),
                    {
                        "text": data.messageContent,
                        "id": String(data.messageId),
                        "datetime": data.datetime,
                        "readDatetime": data.readDatetime,
                        "sender": {
                            "name": data.senderName,
                            "uid": data.senderId + "",
                            "avatar": "https://data.cometchat.com/assets/images/avatars/ironman.png",
                        }
                    }])
            }
        ).catch(
            err => {
                //console.log(err)
                setMessageList(
                    (messages): (IMessage | ITempMessage)[] =>
                        [...messages.filter(m => m !== tempSendingMessage),
                            tempFailedMessage
                        ]
                )
            }
        )
    }


    const classes = useStyles();
    return (
        _isLoading ? <></> :
            _errorState.error ?
                <div
                    className={classes.errorDiv}
                >
                    <h3>{_errorState.errorMessage}</h3>
                </div>
                :
                <ChatBox
                    messages={_messageList}
                    user={{ "uid": props.userId }}
                    onSubmit={HandleMessageSubmit} isLoading={_isLoading}
                ></ChatBox>
    )
}
const useStyles = makeStyles({
    errorDiv: {
        maxWidth: "400px",
        padding: '0 !important',
        boxSizing: 'border-box',
        backgroundColor: '#333232 !important'
    }

})

