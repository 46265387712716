import { HttpHelper } from "../../_commons/Helpers/HttpHelper";

//public 
const GetAllLearningPaths = (): Promise<Response> => {
  let api = 'api/LearningPath';
  return HttpHelper.Get(api);
}
//public 
const getAllLearningPathsWithCourses = async (handler: (data: any) => void) => {

  await fetch('api/LearningPath/Course')
    .then((response) => response.json())
    .then((data) => {
      handler(data);
    })
    .catch((e) => {
    });
}

//public 
const GetLearningPathByCourseId = async (courseId: number, dataHandler: (data: any) => void, errHandler = (err: any) => { }, finalHandler = () => { }) => {
  let api = `api/LearningPath/${courseId}`;


  await HttpHelper.Get(api).then(resp => resp.json()
  ).then(
    data => {
      dataHandler(data)
    }
  ).catch(
    e => {
      errHandler(e);
    }
  ).finally(
    () => {
      finalHandler();
    }
  )
}

//private 
const UpdateLearningPaths = (courseId: number, learningPathIds: number[], token: string): Promise<Response> => {

  let Api = `api/LearningPath/`;
  return HttpHelper.PostJSON(Api, { courseId, learningPathIds }, token);
  
}

export const LearningPathService = {
  //public
  GetAllLearningPaths,
  getAllLearningPathsWithCourses,
  GetLearningPathByCourseId,

  //private
  UpdateLearningPaths
}



