import  { useCallback } from 'react'
import { CertificateTemplateCMSApi } from '../../_services/CMSServices/CertificateTemplateCMS';
import { ICertificateTemplateModel } from '../../_services/CMSServices/Models';
import useResponseData from './useResponseData'


export const useCertificateTemplateData = (token: string) => useResponseData<ICertificateTemplateModel[]>(
    useCallback(
        () => CertificateTemplateCMSApi.GetAll(token)
        ,
        [token]
    ));