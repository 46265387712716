import React, { useEffect, useState } from 'react'
import { AdminService } from '../../../../_services/AdminService';
import { useRadacadAuth } from '../../../../_services/radacad-auth-react/Core/useRadacadAuth';

export default function EmailSubjectEditorForUnreadMessageNotification() {
    const [subjectString, setSubjectString] = useState<string>();
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const { authState: { accessToken } } = useRadacadAuth();
    useEffect(() => {
        accessToken && AdminService.EmailTaskSetting.GetEmailTaskSettingForUnreadMessage(accessToken).then(
            (setting) => {
                if (setting) {
                    setSubjectString(setting.emailSubject);
                }
            }
        )
    }, [])

    const handlleSubmit = (subject: string) => {
        accessToken && AdminService.EmailTaskSetting.UpdateEmailTaskSubjectLineForUnreadMessage(subject, accessToken).then(
            (resp) => {
                if (resp.status === 200) {
                    alert("success")
                } else {
                    alert("failed");
                }
            }
        )
    }

    return (
        <div>
            Subject Line: To let our application know where are positions to present the count of unreand messages and 's' letter for plural nouns in subject line,
            please leave {`{UnreadMessageCount}`} and {`{s}`}at the their positions in this email subject respectively.
            <input
                style={{ width:"100%" }}
                type="text"
                value={subjectString}
                onChange={(e) => setSubjectString(e.target.value)}
                disabled={isEditing === false}
            />
            {
                isEditing ?
                    <button onClick={() => { subjectString && handlleSubmit(subjectString); setIsEditing(false) }}>Submit</button> :
                    <button onClick={() => setIsEditing(true)}>Edit</button>
            }
        </div>
    )
}
